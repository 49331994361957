var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { SearchResponse } from "@/app/modular-content/services/search.service";
import { reactive } from "@/di/decorators/computed.decorator";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { TranslationService } from "@/app/translation/services/translation.service";
import { WebhookTriggerType } from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.constants";
let WebhookService = class WebhookService {
    httpService;
    toastService;
    translationService;
    data = { results: [] };
    constructor(httpService, toastService, translationService) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.translationService = translationService;
    }
    getData() {
        return this.data;
    }
    resetData() {
        this.data = { results: [] };
    }
    async loadPage(dto) {
        this.data = await this.fetchPage(dto);
    }
    async fetchPage(dto) {
        return this.httpService.post("webhooks/search", dto);
    }
    async deleteWebhook(id) {
        await this.httpService.delete(`webhooks/${id}`);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate(`modular-content.services.domain.create.webhook-delete-toast-message`),
        });
        const newData = this.data.results?.filter((it) => it.id !== id) ?? [];
        this.setData(newData);
        if (this.data.count) {
            this.data.count -= 1;
        }
    }
    setData(newData) {
        this.data.results = newData;
    }
    async createWebhook(dto) {
        const entity = await this.httpService.post("webhooks", dto);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate(`modular-content.services.domain.create.webhook-create-toast-message`),
        });
        this.data.results?.unshift(entity);
        if (this.data.count) {
            this.data.count += 1;
        }
        return entity;
    }
    async update(id, dto) {
        const entity = await this.httpService.put(`webhooks/${id}`, dto);
        if (this.data.results) {
            const foundIndex = this.data.results.findIndex((it) => it.id == entity.id);
            const current = this.data.results[foundIndex];
            this.data.results[foundIndex] = { ...current, ...entity };
        }
        return entity;
    }
    async getSendLaneOnDemandAvailability(companyId) {
        const filterQuery = `CompanyId eq '${companyId}'`;
        const searchResponse = await this.fetchPage({ filter: filterQuery });
        const isAnySendLaneOnDemand = searchResponse.results?.some((webhook) => webhook.trigger === WebhookTriggerType.SendLaneOnDemand);
        return Boolean(isAnySendLaneOnDemand);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_d = typeof SearchResponse !== "undefined" && SearchResponse) === "function" ? _d : Object)
], WebhookService.prototype, "data", void 0);
WebhookService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], WebhookService);
export { WebhookService };
