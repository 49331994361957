var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import _ from "lodash";
import { Subject, firstValueFrom } from "rxjs";
import { getChanges } from "@/app/shared/functions/get-changes";
import { service } from "@/di/decorators/service.decorator";
import { ChangeDetectionServiceToken, DomainIdToken, ModuleIdToken, } from "@/app/modular-content/token/modular-content.token";
import { inject } from "@/di/decorators/inject.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { CancelEditModalId } from "@/app/core/core.constants";
import { modal } from "@/app/shared/decorators/inject-modal.decorator";
import { ModalService } from "@/app/modals/services/modal.service";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { BackendValidationErrorsService } from "@/app/validation/services/backend-validation-errors.service";
let TemporaryDataService = class TemporaryDataService {
    cancelModal;
    changeDetectionServices;
    moduleId;
    domainId;
    httpClient;
    routerService;
    backendValidationService;
    isNew;
    dataChanged;
    temporaryMode;
    name;
    subModuleId;
    result;
    initial;
    data;
    dataSubject = new Subject();
    resultSubject = new Subject();
    constructor(cancelModal, changeDetectionServices, moduleId, domainId, httpClient, routerService, backendValidationService) {
        this.cancelModal = cancelModal;
        this.changeDetectionServices = changeDetectionServices;
        this.moduleId = moduleId;
        this.domainId = domainId;
        this.httpClient = httpClient;
        this.routerService = routerService;
        this.backendValidationService = backendValidationService;
    }
    setTemporaryMode(temporaryMode, isNew, name, subModuleId) {
        this.temporaryMode = temporaryMode;
        this.isNew = isNew;
        this.name = name;
        this.subModuleId = subModuleId;
    }
    hasTemporaryMode() {
        return this.temporaryMode;
    }
    setChangeStatus(value) {
        this.dataChanged = value;
    }
    hasChanges() {
        return this.dataChanged;
    }
    isNewEntity() {
        return this.isNew;
    }
    entityName() {
        return this.name;
    }
    getSubModuleId() {
        return this.subModuleId;
    }
    setInitial(initial) {
        this.initial = initial;
    }
    setData(data) {
        this.data = data;
        const hasChanges = !_.isEmpty(getChanges(this.initial ?? {}, [data]));
        this.setChangeStatus(hasChanges);
        this.dataSubject.next(data);
    }
    onDataChange() {
        return this.dataSubject.asObservable();
    }
    getData() {
        return this.data;
    }
    async setResult(result) {
        const { confirmed } = result;
        this.result = result;
        if (!confirmed) {
            await this.checkChanges();
        }
        else {
            this.resultSubject.next(result);
        }
    }
    getResult() {
        return this.result;
    }
    onResultChange() {
        return this.resultSubject.asObservable();
    }
    async checkChanges() {
        const changeDetectionService = this.getCurrentDetectionService();
        if (!changeDetectionService?.hasChanges()) {
            return this.setTemporaryMode(false);
        }
        this.cancelModal.setVisible(true);
        const result = await firstValueFrom(this.cancelModal.onResultChange());
        if (result.saved) {
            this.setTemporaryMode(false);
            this.resultSubject.next({ confirmed: true });
        }
        else if (result.confirmed) {
            this.setTemporaryMode(false);
            this.resultSubject.next({ confirmed: false });
        }
    }
    getCurrentModuleId() {
        return this.routerService.getCurrentRoute()?.meta?.moduleId;
    }
    getCurrentDomainId() {
        return this.routerService.getCurrentRoute()?.meta?.domainId;
    }
    getCurrentDetectionService() {
        return this.changeDetectionServices.find((it) => it?.getModuleId() === this.getCurrentModuleId() && it?.getDomainId() === this.getCurrentDomainId());
    }
    async validate() {
        const subModuleKeys = {
            checkIn: "checkIns",
            gates: "gates",
            parkingSpace: "parkingSpaces",
            weighingBridges: "weighingBridges",
        };
        try {
            await this.httpClient.post(`sites/${subModuleKeys[this.getSubModuleId()]}/validate`, [this.data]);
            this.setResult({ confirmed: true });
            this.setTemporaryMode(false);
        }
        catch (error) {
            this.backendValidationService.scrollToView();
        }
    }
};
TemporaryDataService = __decorate([
    service(),
    __param(0, modal(CancelEditModalId)),
    __param(1, injectAll(ChangeDetectionServiceToken)),
    __param(2, inject(ModuleIdToken)),
    __param(3, inject(DomainIdToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof ModalService !== "undefined" && ModalService) === "function" ? _a : Object, Array, String, String, typeof (_b = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _b : Object, typeof (_c = typeof RouterService !== "undefined" && RouterService) === "function" ? _c : Object, typeof (_d = typeof BackendValidationErrorsService !== "undefined" && BackendValidationErrorsService) === "function" ? _d : Object])
], TemporaryDataService);
export { TemporaryDataService };
