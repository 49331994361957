export var MatchModeType;
(function (MatchModeType) {
    MatchModeType["Equals"] = "equals";
    MatchModeType["Contains"] = "contains";
})(MatchModeType || (MatchModeType = {}));
export var EntityTypeName;
(function (EntityTypeName) {
    EntityTypeName["ProductType"] = "ProductType";
    EntityTypeName["Site"] = "SiteName";
    EntityTypeName["LoadingPoint"] = "LoadingPointName";
    EntityTypeName["UnloadingPoint"] = "UnloadingPointName";
    EntityTypeName["Building"] = "BuildingName";
    EntityTypeName["City"] = "City";
    EntityTypeName["Company"] = "CompanyName";
    EntityTypeName["CompanyType"] = "CompanyType";
    EntityTypeName["Country"] = "Country";
    EntityTypeName["OutboundProduct"] = "OutboundProductName";
    EntityTypeName["SiteTo"] = "SiteToName";
    EntityTypeName["SiteToCity"] = "SiteToCity";
    EntityTypeName["SiteToPostalCode"] = "SiteToPostalCode";
    EntityTypeName["SiteToCountry"] = "SiteToCountry";
    EntityTypeName["InboundProduct"] = "InboundProductName";
    EntityTypeName["CompanyFrom"] = "CompanyFromName";
    EntityTypeName["Product"] = "ProductName";
    EntityTypeName["ProductAuthorizationGroup"] = "ProductAuthorizationGroup";
    EntityTypeName["SiteFrom"] = "SiteFromName";
    EntityTypeName["SiteFromCity"] = "SiteFromCity";
    EntityTypeName["SiteFromPostalCode"] = "SiteFromPostalCode";
    EntityTypeName["SiteFromCountry"] = "SiteFromCountry";
    EntityTypeName["CompanyTo"] = "CompanyToName";
    EntityTypeName["UnloadedProduct"] = "UnloadedProductName";
    EntityTypeName["RequiresVerification"] = "IsRequiredVerification";
    EntityTypeName["Verifier"] = "VerifierName";
    EntityTypeName["StatusVerification"] = "StatusVerification";
    EntityTypeName["AdditionalInformation"] = "IsAdditionalInformation";
    EntityTypeName["Status"] = "Status";
    EntityTypeName["Name"] = "Name";
    EntityTypeName["PrlGroupOrPreProduct"] = "PrlGroupOrPreProduct";
    EntityTypeName["CustomerName"] = "CustomerName";
    EntityTypeName["PrlAllowed"] = "PrlAllowed";
    EntityTypeName["LoadCount"] = "LoadCount";
    EntityTypeName["CleaningRequired"] = "CleaningRequired";
    EntityTypeName["TenderId"] = "TenderId";
    EntityTypeName["TenderName"] = "TenderName";
    EntityTypeName["TenderStatus"] = "TenderStatus";
})(EntityTypeName || (EntityTypeName = {}));
export var EntityGroupName;
(function (EntityGroupName) {
    EntityGroupName["Assignments"] = "Assignments";
    EntityGroupName["TransportGroup"] = "TransportGroup";
    EntityGroupName["CasNumbers"] = "CasNumbers";
    EntityGroupName["RequiredTests"] = "RequiredTests";
    EntityGroupName["CleaningProcedures"] = "CleaningProcedures";
})(EntityGroupName || (EntityGroupName = {}));
