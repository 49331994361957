import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ac494b2"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "header-title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_PreloadingRestrictionPolicyTag = _resolveComponent("PreloadingRestrictionPolicyTag");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_AppPanel = _resolveComponent("AppPanel");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", {
        class: "entity-lists",
        "data-qa-id": _ctx.$options.name
    }, [
        _createVNode(_component_PageHeader, null, {
            title: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_PageTitle, null, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.header), 1)
                        ]),
                        _: 1
                    }),
                    (_ctx.isPreloadingRestrictionDomain)
                        ? (_openBlock(), _createBlock(_component_PreloadingRestrictionPolicyTag, {
                            key: 0,
                            entity: _ctx.entity
                        }, null, 8, ["entity"]))
                        : _createCommentVNode("", true),
                    (_ctx.publicList)
                        ? _withDirectives((_openBlock(), _createBlock(_component_Icon, {
                            key: 1,
                            class: "info-icon",
                            name: "info",
                            variant: "outlined"
                        }, null, 512)), [
                            [
                                _directive_tooltip,
                                _ctx.translate('modular-content.components.sub-module-info.public-tooltip'),
                                void 0,
                                { bottom: true }
                            ]
                        ])
                        : _createCommentVNode("", true)
                ])
            ]),
            buttons: _withCtx(() => [
                (_ctx.metaData.copyAvailable &&
                    !_ctx.isReadOnly &&
                    _ctx.hasWriteAccessForDomain &&
                    _ctx.hasCreateAccessForList &&
                    _ctx.copyAvailableInList)
                    ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                        key: 0,
                        class: "p-button p-button-secondary",
                        onClick: _ctx.openCopyEntityModal
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "file_copy" })
                        ]),
                        _: 1
                    }, 8, ["onClick"])), [
                        [
                            _directive_tooltip,
                            _ctx.getCopyTooltip(),
                            void 0,
                            { bottom: true }
                        ]
                    ])
                    : _createCommentVNode("", true),
                (_ctx.hasNavigationToOriginDomain && _ctx.roleCheck(_ctx.$permissions.belongsToCompany, _ctx.entity?.companyId))
                    ? (_openBlock(), _createBlock(_component_AppButton, {
                        key: 1,
                        class: "p-button p-button-secondary",
                        onClick: _ctx.navigateToOriginDomain
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "input" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.getDestinationTranslateKey())), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]))
                    : (!_ctx.isReadOnly && _ctx.hasCreateAccessForList)
                        ? (_openBlock(), _createBlock(_component_AppButton, {
                            key: 2,
                            "data-qa-id": "list-create-button",
                            onClick: _ctx.openCreateEntityModal
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_Icon, { name: "add" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.getCreateBtnTranslateKey())), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
            ]),
            _: 1
        }),
        _createVNode(_component_PanelTopNavigation, { "menu-items": _ctx.menuItems }, null, 8, ["menu-items"]),
        _createVNode(_component_AppPanel, { class: "pt-0" }, {
            default: _withCtx(() => [
                _createVNode(_component_RouterView)
            ]),
            _: 1
        })
    ], 8, _hoisted_1));
}
