export const VerificationRequestsDomainId = "VerificationRequests";
export const FactSheetsDomainId = "FactSheets";
export const FactSheetModuleId = "factsheet";
export const SiteSeparator = "Site_Separator";
export const UnloadingPointSeparator = "UnloadingPoint_Separator";
export const AssignedProductSeparator = "AssignedProduct_Separator";
export const RequirementProfileFactSheetDomainServiceToken = "RequirementProfileFactSheetDomainService";
export const VerificationDoneSuccessModalId = "VerificationDoneSuccessModal";
export const toastMessages = {
    InReview: "domains.requirement-profiles.requirement-profile-form.status-buttons.in-review.toast-message",
    Confirmed: "domains.requirement-profiles.requirement-profile-form.status-buttons.confirmed.toast-message",
    NotStarted: "domains.requirement-profiles.requirement-profile-form.status-buttons.reset-workflow.toast-message",
    InProgress: "domains.requirement-profiles.requirement-profile-form.status-buttons.in-progress.toast-message",
};
