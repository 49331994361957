import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3490eaba"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title-container" };
const _hoisted_2 = { class: "buttons-container" };
const _hoisted_3 = {
    key: 0,
    class: "request-verification-button"
};
const _hoisted_4 = {
    key: 1,
    class: "verification-done-button"
};
const _hoisted_5 = {
    key: 1,
    class: "verification-done-buttons-container"
};
const _hoisted_6 = {
    key: 1,
    class: "fact-sheet-page-header"
};
const _hoisted_7 = { class: "header-columns-container" };
const _hoisted_8 = { class: "company" };
const _hoisted_9 = { class: "sub-title" };
const _hoisted_10 = { class: "logo-name-container" };
const _hoisted_11 = { class: "entity-name" };
const _hoisted_12 = { class: "product" };
const _hoisted_13 = { class: "sub-title" };
const _hoisted_14 = { class: "entity-name" };
const _hoisted_15 = { class: "entity-erp" };
const _hoisted_16 = { class: "verification" };
const _hoisted_17 = { class: "sub-title" };
const _hoisted_18 = { class: "entity-name" };
const _hoisted_19 = {
    key: 0,
    class: "verification-time"
};
const _hoisted_20 = { class: "sub-title" };
const _hoisted_21 = { class: "entity-name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_tempalte = _resolveComponent("tempalte");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Badge = _resolveComponent("Badge");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PageTitle, null, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("domains.fact-sheets.page-header.title")), 1)
                ]),
                _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isRequirementProfileFactSheetsDomain)
                    ? (_openBlock(), _createBlock(_component_tempalte, { key: 0 }, {
                        default: _withCtx(() => [
                            (_ctx.isVerificationButtonEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createVNode(_component_AppButton, {
                                        onClick: _ctx.requestVerification,
                                        "data-qa-id": "request-verification-button"
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("domains.requirement-profiles.request-verification.label")), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["onClick"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.isReqProfileSaveOrConfirmButtonEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_ButtonGroup, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_AppButton, {
                                                onClick: _ctx.closeFactSheet,
                                                class: "p-button-tertiary",
                                                "data-qa-id": "close-button"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.requirementProfilesPrefix}.close.label`)), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick"]),
                                            (_ctx.isVerifiedStatus)
                                                ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                                                    key: 0,
                                                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.changeStatus(_ctx.VerificationStatus.InReview))),
                                                    class: "p-button-secondary",
                                                    "data-qa-id": "save-data-button"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.requirementProfilesPrefix}.save-data.label`)), 1)
                                                    ]),
                                                    _: 1
                                                })), [
                                                    [
                                                        _directive_tooltip,
                                                        _ctx.translate('fact-sheet.save-data.tooltip'),
                                                        void 0,
                                                        { bottom: true }
                                                    ]
                                                ])
                                                : _createCommentVNode("", true),
                                            (_ctx.isInReviewStatus)
                                                ? (_openBlock(), _createBlock(_component_AppButton, {
                                                    key: 1,
                                                    onClick: _ctx.restartConfirmation,
                                                    class: "p-button-secondary",
                                                    "data-qa-id": "reset-verification-button"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.requirementProfilesPrefix}.restart-verification.label`)), 1)
                                                    ]),
                                                    _: 1
                                                }, 8, ["onClick"]))
                                                : _createCommentVNode("", true),
                                            _createVNode(_component_AppButton, {
                                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.changeStatus(_ctx.VerificationStatus.Confirmed))),
                                                "data-qa-id": "confirm-button"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.requirementProfilesPrefix}.confirm.label`)), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                (_ctx.isVerificationRequestFactSheetsDomain && _ctx.isVerificationDoneButtonEnabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _renderSlot(_ctx.$slots, "editButton", {}, undefined, true),
                        _createVNode(_component_AppButton, {
                            onClick: _ctx.setVerificationStatusVerified,
                            "data-qa-id": "verification-done-button"
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.requirementProfilesPrefix}.verification-done.label`)), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"])
                    ]))
                    : _createCommentVNode("", true)
            ])
        ]),
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("requesting-company")), 1),
                        _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_Thumbnail, {
                                src: _ctx.entity?.companyFromLogoPath,
                                icon: "domain",
                                size: "lg"
                            }, null, 8, ["src"]),
                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.entity?.companyFromName), 1)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("product-name")), 1),
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createTextVNode(_toDisplayString(_ctx.entity?.inboundProductName), 1)
                        ])), [
                            [
                                _directive_tooltip,
                                _ctx.entity?.inboundProductName,
                                void 0,
                                { bottom: true }
                            ]
                        ]),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.entity?.inboundProductReferenceNumber), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("status-verification")), 1),
                        _createElementVNode("div", _hoisted_18, [
                            (_ctx.entity?.statusVerification)
                                ? (_openBlock(), _createBlock(_component_Badge, {
                                    key: 0,
                                    class: "badge",
                                    color: _ctx.verificationStatusStyles[_ctx.entity?.statusVerification]
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getStatus(_ctx.entity?.statusVerification)), 1)
                                    ]),
                                    _: 1
                                }, 8, ["color"]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    (_ctx.hasBeenVerified)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("verified-by")), 1),
                            _createElementVNode("div", _hoisted_21, _toDisplayString(`${_ctx.currentTime} ${_ctx.entity.verifierName}`), 1)
                        ]))
                        : _createCommentVNode("", true)
                ])
            ]))
    ], 64));
}
