import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-49712844"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "inline-field d-inline-flex mb-2" };
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_Icon = _resolveComponent("Icon");
    const _component_InputSwitch = _resolveComponent("InputSwitch");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('header'),
        id: "create-company-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.company,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.company) = $event)),
                errors: _ctx.errors,
                onIsValid: _cache[4] || (_cache[4] = ($event) => (_ctx.isValid = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-name.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.company-name.placeholder'),
                                name: "name",
                                id: "company-name-input-field",
                                required: ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "name" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.reference-number.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.reference-number.placeholder'),
                                name: "referenceNumber",
                                id: "reference-number-input-field",
                                "min-length": "3",
                                "max-length": "30",
                                required: ""
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "referenceNumber" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-type.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_Dropdown, {
                                name: "companyType",
                                options: _ctx.companyTypeOptions,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "CompanyTypes",
                                placeholder: _ctx.translate('form-fields.company-type.placeholder'),
                                required: "",
                                disabled: _ctx.managedByExternal
                            }, null, 8, ["options", "placeholder", "disabled"]),
                            _createVNode(_component_FormError, { name: "companyType" })
                        ]),
                        _: 1
                    }),
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("p", {
                            innerHTML: _ctx.translate('business-partner')
                        }, null, 8, _hoisted_2),
                        _createVNode(_component_Icon, {
                            class: "icon",
                            variant: "outlined",
                            name: "supervisor_account"
                        }),
                        _createVNode(_component_InputSwitch, {
                            modelValue: _ctx.company.managedByExternalCompany,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.company.managedByExternalCompany) = $event))
                        }, null, 8, ["modelValue"])
                    ]),
                    (_ctx.managedByExternal)
                        ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    name: "managedById",
                                    options: _ctx.companiesWithAdminRights,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    additionalLabel: "referenceNumber",
                                    filterFields: ['referenceNumber'],
                                    placeholder: _ctx.translate('form-fields.business-partner-of.placeholder'),
                                    required: !!_ctx.company.managedById
                                }, null, 8, ["options", "placeholder", "required"]),
                                _createVNode(_component_FormError, { name: "managedById" })
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    (!_ctx.managedByExternal)
                        ? (_openBlock(), _createBlock(_component_FormField, { key: 1 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("company-id.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_InputText, {
                                    placeholder: _ctx.translate('company-id.placeholder'),
                                    name: "id",
                                    modelValue: _ctx.company.id,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.company.id) = $event)),
                                    modelModifiers: { trim: true },
                                    onInput: _cache[2] || (_cache[2] = () => (_ctx.company.id = _ctx.company.id?.toUpperCase())),
                                    id: "company-id-input-field",
                                    required: "",
                                    "one-word": "",
                                    alphanumeric: ""
                                }, null, 8, ["placeholder", "modelValue"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "id" })
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Button, {
                                class: "p-button-lg p-button-tertiary",
                                onClick: _ctx.close,
                                id: "cancel-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_Button, {
                                class: "p-button-lg",
                                loading: _ctx.isCreating,
                                disabled: !_ctx.isValid,
                                onClick: _ctx.create,
                                id: "create-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                                ]),
                                _: 1
                            }, 8, ["loading", "disabled", "onClick"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "errors", "initial"])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
