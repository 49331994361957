var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import { inject } from "@/di/decorators/inject.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { InjectorService } from "@/app/shared/services/injector.service";
import { subModuleTokensFactory } from "@/app/modular-content/factories/sub-module-tokens.factory";
import { DomainIdToken, ModuleIdToken, SubModuleIdToken } from "@/app/modular-content/token/modular-content.token";
import { InjectionScope } from "@/di/types/scope";
let SubModuleService = class SubModuleService {
    domainId;
    moduleId;
    subModuleId;
    injector;
    metadata;
    moduleDataSubset = {};
    editComponent;
    displayComponent;
    displayViewComponent;
    constructor(domainId, moduleId, subModuleId, injector) {
        this.domainId = domainId;
        this.moduleId = moduleId;
        this.subModuleId = subModuleId;
        this.injector = injector;
        const tokens = subModuleTokensFactory(domainId, moduleId, subModuleId);
        this.metadata = injector.resolve(tokens.meta);
        this.editComponent = injector.resolve(tokens.editComponent);
        this.displayComponent = injector.resolve(tokens.displayComponent);
        this.displayViewComponent = injector.resolve(tokens.displayViewComponent);
    }
    getDomainId() {
        return this.domainId;
    }
    getModuleId() {
        return this.moduleId;
    }
    getSubModuleId() {
        return this.subModuleId;
    }
    getMetadata() {
        return this.metadata;
    }
    getData() {
        return this.moduleDataSubset[this.metadata.dataKey];
    }
    setData(data) {
        this.moduleDataSubset[this.metadata.dataKey] = data;
    }
    getValue() {
        return this.moduleDataSubset;
    }
    setValue(value) {
        this.moduleDataSubset = value;
    }
    resetValue() {
        this.moduleDataSubset = {};
    }
    getDisplayComponent() {
        return this.displayComponent;
    }
    getDisplayViewComponent() {
        return this.displayViewComponent;
    }
    getEditComponent() {
        return this.editComponent;
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object)
], SubModuleService.prototype, "moduleDataSubset", void 0);
SubModuleService = __decorate([
    service({ scope: InjectionScope.Token }),
    __param(0, inject(DomainIdToken)),
    __param(1, inject(ModuleIdToken)),
    __param(2, inject(SubModuleIdToken)),
    __metadata("design:paramtypes", [String, String, String, typeof (_a = typeof InjectorService !== "undefined" && InjectorService) === "function" ? _a : Object])
], SubModuleService);
export { SubModuleService };
