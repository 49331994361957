import _ from "lodash";
import { resolve } from "@/di/composables/resolve";
import { RouterService } from "@/app/shared/services/router.service";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { RouteMetaDomainId } from "@/app/modular-content/modular-content.constants";
import { SubModuleMetadataToken } from "@/app/modular-content/token/modular-content.token";
import { RequirementProfilesFactSheetsDomainId, VerificationRequestsFactSheetsDomainId, } from "@/app/domains/verification-requests/verification-requests.constants";
export const useDomainTranslationPrefix = (domainId) => {
    const routerService = resolve(RouterService);
    domainId = domainId ? domainId : routerService.getMeta(RouteMetaDomainId);
    const domainMetadata = resolve(domainTokensFactory(domainId).meta);
    const subModuleMetadata = resolve(SubModuleMetadataToken);
    const factSheetDomainIds = [VerificationRequestsFactSheetsDomainId, RequirementProfilesFactSheetsDomainId];
    return (key, strict) => {
        if (strict) {
            const prefix = _.isFunction(domainMetadata.domainTranslationPrefix)
                ? domainMetadata.domainTranslationPrefix(routerService)
                : domainMetadata.domainTranslationPrefix;
            return `${prefix}-${key}`;
        }
        if (domainMetadata.id.includes("Loading") || subModuleMetadata.id.includes("Loading")) {
            return `loading-${key}`;
        }
        else if (domainMetadata.id.includes("Unloading") ||
            subModuleMetadata.id.includes("Unloading") ||
            factSheetDomainIds.includes(domainMetadata.id)) {
            return `unloading-${key}`;
        }
        return `site-${key}`;
    };
};
