import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { useUserStore } from "@/app/users/stores/user.state";
import { VerificationStatus } from "@/app/domains/requirement-profiles/enums/status-verification.enum";
let FeedbackDoneFilterService = class FeedbackDoneFilterService {
    userStore;
    constructor() {
        this.userStore = useUserStore();
    }
    createEqualsFilter() {
        return [
            {
                propertyNames: ["StatusVerification", "StatusVerification"],
                propertyValues: [VerificationStatus.Verified, VerificationStatus.InReview],
            },
            {
                propertyName: "VerifierId",
                propertyValue: this.userStore.user.id ?? "",
            },
        ];
    }
};
FeedbackDoneFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [])
], FeedbackDoneFilterService);
export { FeedbackDoneFilterService };
