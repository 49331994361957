import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-716784e1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "company-settings" };
const _hoisted_2 = { class: "icon-wrapper" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "description" };
const _hoisted_5 = { class: "icon-wrapper" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isLogisticsServiceProvider)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "settings-card",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.onSubModuleSettingsClick && _ctx.onSubModuleSettingsClick(...args)))
            }, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_Icon, {
                        class: "icon",
                        name: "settings_input_component"
                    })
                ]),
                _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("submodule-configuration-title")), 1),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("submodule-configuration-description")), 1)
                ])
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", {
            class: "settings-card",
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.onWebhooksClick && _ctx.onWebhooksClick(...args)))
        }, [
            _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Icon, {
                    class: "icon",
                    name: "precision_manufacturing"
                })
            ]),
            _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("webhooks.title")), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("webhooks.description")), 1)
            ])
        ])
    ]));
}
