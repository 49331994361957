import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-566df92e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "text-wrap name"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "name-id" };
const _hoisted_6 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_7 = { key: 1 };
const _hoisted_8 = {
    key: 0,
    class: "text-wrap id"
};
const _hoisted_9 = { key: 1 };
const _hoisted_10 = {
    key: 0,
    class: "text-wrap id"
};
const _hoisted_11 = { key: 1 };
const _hoisted_12 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_13 = { key: 1 };
const _hoisted_14 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_15 = { key: 1 };
const _hoisted_16 = { class: "name-id" };
const _hoisted_17 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_18 = { key: 1 };
const _hoisted_19 = {
    key: 0,
    class: "text-wrap id"
};
const _hoisted_20 = { key: 1 };
const _hoisted_21 = { class: "d-flex flex-column" };
const _hoisted_22 = { key: 0 };
const _hoisted_23 = { key: 1 };
const _hoisted_24 = { class: "d-flex flex-column" };
const _hoisted_25 = { key: 0 };
const _hoisted_26 = { key: 1 };
const _hoisted_27 = { key: 1 };
const _hoisted_28 = { class: "name-id" };
const _hoisted_29 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_30 = { key: 1 };
const _hoisted_31 = {
    key: 0,
    class: "text-wrap id"
};
const _hoisted_32 = { key: 1 };
const _hoisted_33 = { key: 1 };
const _hoisted_34 = {
    key: 0,
    class: "overflow-hidden px-3 mt-1"
};
const _hoisted_35 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Column = _resolveComponent("Column");
    const _component_Badge = _resolveComponent("Badge");
    const _component_Icon = _resolveComponent("Icon");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "verification-requests-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                onRowClick: _cache[0] || (_cache[0] = ($event) => (_ctx.navigateToEntity($event))),
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[2] || (_cache[2] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                loading: _ctx.isDataLoading,
                onPage: _cache[3] || (_cache[3] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first,
                ref: "verificationRequestsList"
            }, {
                default: _withCtx(() => [
                    (_ctx.isColumnVisible('companyFrom'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "companyFrom",
                            header: _ctx.getColumnHeader('companyFrom'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.companyFromName)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.companyFromName), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "requirementProfileId",
                        header: _ctx.translate('columns.requirement-profile-id'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.id), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('outboundProduct'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "outboundProduct",
                            header: _ctx.getColumnHeader('outboundProduct'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", null, [
                                        (data.outboundProductName)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(data.outboundProductName), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                                    ]),
                                    _createElementVNode("div", null, [
                                        (data.outboundProductId)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(data.outboundProductId), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                                    ]),
                                    _createElementVNode("div", null, [
                                        (data.outboundProductReferenceNumber)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(data.outboundProductReferenceNumber), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                                    ])
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('siteTo'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "siteTo",
                            header: _ctx.getColumnHeader('siteTo'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteToName)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(data.siteToName), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "unloadingPoint",
                            header: _ctx.getColumnHeader('unloadingPoint'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointName)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(data.unloadingPointName), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_15, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('inboundProduct'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "inboundProduct",
                            header: _ctx.getColumnHeader('inboundProduct'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_16, [
                                    _createElementVNode("div", null, [
                                        (data.inboundProductName)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(data.inboundProductName), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
                                    ]),
                                    _createElementVNode("div", null, [
                                        (data.inboundProductId)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(data.inboundProductId), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_20, "-"))
                                    ])
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_21, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime, "YYYY-MM-DD")), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_23, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('lastUpdate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "lastUpdate",
                            header: _ctx.getColumnHeader('lastUpdate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_24, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime, "YYYY-MM-DD")), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_26, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('requiresVerification'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            field: "requiresVerification",
                            header: _ctx.getColumnHeader('requiresVerification'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.isRequiredVerification !== undefined && data.isRequiredVerification !== null)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: data.isRequiredVerification ? 'green' : 'gray'
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.isRequiredVerification ? _ctx.translate("phrases.yes") : _ctx.translate("phrases.no")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_27, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('verifier'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 8,
                            field: "verifier",
                            header: _ctx.getColumnHeader('verifier'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_28, [
                                    _createElementVNode("div", null, [
                                        (data.verifierName)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(data.verifierName), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_30, "-"))
                                    ]),
                                    _createElementVNode("div", null, [
                                        (data.verifierEmail)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(data.verifierEmail), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_32, "-"))
                                    ])
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('statusVerification'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 9,
                            field: "statusVerification",
                            header: _ctx.getColumnHeader('statusVerification'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.statusVerification)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: _ctx.verificationStatusStyles[data?.statusVerification]
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getValue(data.statusVerification, "RequirementProfileStatusVerification")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_33, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('additionalInformation'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 10,
                            field: "additionalInformation",
                            header: _ctx.getColumnHeader('additionalInformation'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (_ctx.$utils.hasValue(data.additionalInformation))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                        _withDirectives(_createVNode(_component_Icon, {
                                            name: "text_snippet_rounded",
                                            class: "image-icon"
                                        }, null, 512), [
                                            [
                                                _directive_tooltip,
                                                data.additionalInformation,
                                                void 0,
                                                { bottom: true }
                                            ]
                                        ])
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_35, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["value", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "first"])
        ]),
        empty: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("empty.header")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.translate("empty.message")), 1)
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
