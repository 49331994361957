import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1929f930"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "driver-briefing-input" };
const _hoisted_2 = { class: "title globe-icon-wrapper" };
const _hoisted_3 = { class: "header col-5" };
const _hoisted_4 = { class: "header col-6" };
const _hoisted_5 = { class: "column col-5" };
const _hoisted_6 = { class: "column col-6 col-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_DriverBriefingEditableListInput = _resolveComponent("DriverBriefingEditableListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_GlobeIcon)
        ]),
        _createVNode(_component_DriverBriefingEditableListInput, {
            modelValue: _ctx.attachmentsLocalized,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.attachmentsLocalized) = $event)),
            add: _ctx.add,
            edit: _ctx.edit,
            remove: _ctx.remove,
            addBtn: _ctx.translate('buttons.add'),
            emptyMsg: _ctx.emptyStateMsg || _ctx.translate('empty')
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("columns.title")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("columns.description")), 1)
            ]),
            row: _withCtx((attachment) => [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.needsPlaceholderClass(attachment.title) ? 'placeholder' : 'bold name')
                    }, _toDisplayString(_ctx.getElementOrFallback(attachment.title, _ctx.translate("enter-title"))), 3)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["description", { placeholder: _ctx.needsPlaceholderClass(attachment.description) }])
                    }, _toDisplayString(_ctx.getElementOrFallback(attachment.description, _ctx.translate("enter-description"))), 3)
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "add", "edit", "remove", "addBtn", "emptyMsg"])
    ]));
}
