import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fb36ae20"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OpeningHoursInput = _resolveComponent("OpeningHoursInput");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        class: "edit-opening-hours-modal",
        showCloseIcon: false
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_OpeningHoursInput, {
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                        _ctx.validateData
                    ],
                    ref: "openingHours"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createVNode(_component_ButtonGroup, {
                class: _normalizeClass({ overflowing: _ctx.isOverflowing })
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.modal.setVisible(false)))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.save,
                        disabled: _ctx.hasInvalidData
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "disabled"])
                ]),
                _: 1
            }, 8, ["class"])
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
