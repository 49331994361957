import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-77d2b66a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-6 column-header" };
const _hoisted_3 = { class: "col-6" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_7 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TemperatureRangesDisplay = _resolveComponent("TemperatureRangesDisplay");
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("transport-temperature")), 1),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.transportTemperature,
                    temperatureUnit: _ctx.model.transportTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("thermo-equipment")), 1),
            (_ctx.model.thermoEquipment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.translateThermoEquipment(_ctx.model.thermoEquipment)), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("refrigeration-unit-temperature-settings")), 1),
            _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.refrigerationUnitTemperatureSettings,
                    temperatureUnit: _ctx.model.refrigerationUnitTemperatureSettingsTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createVNode(_component_LocalizedField, {
            class: "localized-field",
            modelValue: _ctx.model.additionalTemperatureRequirements
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
            ]),
            _: 1
        }, 8, ["modelValue"])
    ], 64));
}
