import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-55ae1d10"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-6 column-header" };
const _hoisted_3 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TemperatureRangesDisplay = _resolveComponent("TemperatureRangesDisplay");
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("unloading-temperature")), 1),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.unloadingTemperature,
                    temperatureUnit: _ctx.model.unloadingTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createVNode(_component_LocalizedField, {
            class: "localized-field",
            modelValue: _ctx.model.additionalTemperatureRequirements
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
            ]),
            _: 1
        }, 8, ["modelValue"])
    ], 64));
}
