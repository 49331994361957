import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate(`modular-content.components.entity-create-modal.${_ctx.domainTranslationPrefix('header', true)}`),
        "modal-id": _ctx.modalId,
        class: "entity-create-modal",
        "data-qa-id": _ctx.$options.name
    }, {
        default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.createFormComponentName), {
                onIsValid: _ctx.formValidate,
                modelValue: _ctx.formData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.formData) = $event)),
                errors: _ctx.formErrors,
                submit: _ctx.create
            }, null, 40, ["onIsValid", "modelValue", "errors", "submit"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.modal.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isLoading,
                        disabled: !_ctx.isValid,
                        "data-qa-id": "modal-create-button",
                        onClick: _ctx.createMethod
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id", "data-qa-id"]));
}
