import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-11f5b6d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = { class: "attachments-title globe-icon-wrapper" };
const _hoisted_4 = {
    key: 0,
    class: "attachments-column col-6"
};
const _hoisted_5 = { class: "d-flex flex-row gap-3" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = {
    key: 0,
    class: "description"
};
const _hoisted_8 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedFieldTag = _resolveComponent("LocalizedFieldTag");
    const _component_Icon = _resolveComponent("Icon");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                _createVNode(_component_GlobeIcon, { size: "md" })
            ])
        ]),
        (_ctx.attachments.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: idx,
                        class: "attachment-element"
                    }, [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getLocalizedAttachment(attachment.values)?.title), 1),
                            (_ctx.isFallbackAttachment(attachment.values))
                                ? (_openBlock(), _createBlock(_component_LocalizedFieldTag, { key: 0 }))
                                : _createCommentVNode("", true)
                        ]),
                        (_ctx.getLocalizedAttachment(attachment.values)?.description)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.getLocalizedAttachment(attachment.values)?.description), 1))
                            : _createCommentVNode("", true),
                        (_ctx.getLocalizedAttachment(attachment.values)?.url)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: _ctx.getLocalizedAttachment(attachment.values)?.url,
                                target: "_blank",
                                class: "url"
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.translate("more-information")) + " ", 1),
                                _createVNode(_component_Icon, { name: "launch" })
                            ], 8, _hoisted_8))
                            : _createCommentVNode("", true)
                    ]));
                }), 128))
            ]))
            : (_openBlock(), _createBlock(_component_EmptyStateMessage, {
                key: 1,
                class: "col-6"
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.emptyStateMsg), 1)
                ]),
                _: 1
            }))
    ]));
}
