import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ccf36ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "d-flex"
};
const _hoisted_2 = { class: "col-3 col-xl-2 d-flex gap-3 align-items-center" };
const _hoisted_3 = { class: "col-4 col-xxl-3" };
const _hoisted_4 = {
    key: 0,
    class: "col-3 col-xl-2 d-flex justify-content-center gap-3 align-items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        model: _ctx.model,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (temperatureRestriction, index) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "input-heating-medium"
                }, [
                    temperatureRestriction
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_AppCheckbox, {
                                    modelValue: temperatureRestriction.isEnabled,
                                    "onUpdate:modelValue": ($event) => ((temperatureRestriction.isEnabled) = $event),
                                    label: _ctx.getDictionaryValue(temperatureRestriction.heatingMedium, _ctx.heatingMediumDictionary, 'HeatingMedium')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                            ]),
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_InputNumber, {
                                    modelValue: temperatureRestriction.maxHeatingMediumTemperature,
                                    "onUpdate:modelValue": ($event) => ((temperatureRestriction.maxHeatingMediumTemperature) = $event),
                                    name: `${_ctx.name}[${index}].maxHeatingMediumTemperature`,
                                    placeholder: _ctx.translate('max-heating-medium-input-temperature'),
                                    mode: "decimal",
                                    maxFractionDigits: 1,
                                    disabled: !temperatureRestriction.isEnabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "placeholder", "disabled"]),
                                _createVNode(_component_FormError, {
                                    name: `${_ctx.name}[${index}].maxHeatingMediumTemperature`
                                }, null, 8, ["name"])
                            ]),
                            (temperatureRestriction.isEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_RadioButton, {
                                        modelValue: temperatureRestriction.maxHeatingMediumTemperatureUnit,
                                        "onUpdate:modelValue": ($event) => ((temperatureRestriction.maxHeatingMediumTemperatureUnit) = $event),
                                        dictionary: _ctx.temperatureTypeDictionary,
                                        dictionaryId: "TemperatureUnit",
                                        isOptionDeselectable: false
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "dictionary"])
                                ]))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ]));
            }), 128))
        ]),
        _: 1
    }, 8, ["model", "errors"]));
}
