import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71c7850b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row company-basic-information" };
const _hoisted_2 = { class: "col-3 column" };
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-value" };
const _hoisted_5 = { class: "column-details" };
const _hoisted_6 = { class: "company-name" };
const _hoisted_7 = {
    key: 0,
    class: "external"
};
const _hoisted_8 = { class: "col-3 column" };
const _hoisted_9 = { class: "column-header" };
const _hoisted_10 = { class: "column-value" };
const _hoisted_11 = {
    key: 0,
    class: "col-3 column"
};
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = { class: "column-value" };
const _hoisted_14 = { class: "col-3 column" };
const _hoisted_15 = { class: "column-header" };
const _hoisted_16 = { class: "column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("columns.company")), 1),
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Thumbnail, {
                    src: _ctx.basicInformation?.logoPath,
                    icon: "domain",
                    size: "lg"
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.basicInformation?.companyName ?? "-"), 1),
                    (_ctx.basicInformation?.managedByExternalCompany)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            _createVNode(_component_Icon, {
                                variant: "outlined",
                                name: "supervisor_account",
                                class: "external-icon"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate("columns.external-company")), 1)
                        ]))
                        : _createCommentVNode("", true)
                ])
            ])
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("form-fields.company-type.label")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.companyType()), 1)
        ]),
        (_ctx.basicInformation?.managedByExternalCompany)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("columns.business-partner-of")), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.basicInformation?.managedByName ?? "-"), 1)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("columns.last-update")), 1),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.lastUpdatedFormatted), 1)
        ])
    ]));
}
