export var HeatingOrCoolingDuringTransport;
(function (HeatingOrCoolingDuringTransport) {
    HeatingOrCoolingDuringTransport["Required"] = "Required";
    HeatingOrCoolingDuringTransport["NotRequired"] = "NotRequired";
    HeatingOrCoolingDuringTransport["NotAllowed"] = "NotAllowed";
})(HeatingOrCoolingDuringTransport || (HeatingOrCoolingDuringTransport = {}));
export var HeatingMedium;
(function (HeatingMedium) {
    HeatingMedium["Electric"] = "Electric";
    HeatingMedium["Glycol"] = "Glycol";
    HeatingMedium["Oil"] = "Oil";
    HeatingMedium["Steam"] = "Steam";
    HeatingMedium["Water"] = "Water";
})(HeatingMedium || (HeatingMedium = {}));
