import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditOpeningHoursWidget = _resolveComponent("EditOpeningHoursWidget");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_EditOpeningHoursWidget, {
            openingHours: _ctx.model,
            "onUpdate:openingHours": _cache[0] || (_cache[0] = ($event) => (_ctx.model = $event)),
            title: ""
        }, null, 8, ["openingHours"])
    ]));
}
