import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8a731f3c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-general-cleaning-info" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedInput = _resolveComponent("LocalizedInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, { class: "checkboxes" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppCheckbox, { name: "noSpecialRequirements" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }),
                    (!_ctx.noSpecialRequirements)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("general-cleaning-requirement")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_RadioButton, {
                                        modelValue: _ctx.model.generalCleaningRequirement,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.generalCleaningRequirement) = $event)),
                                        dictionary: _ctx.dictionaries.generalCleaningRequirement,
                                        dictionaryId: "GeneralCleaningRequirement"
                                    }, null, 8, ["modelValue", "dictionary"])
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("special-cleaning-required")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "specialCleaningRequired",
                                        options: _ctx.dictionaries.cleaningProcedures,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "CleaningProcedures",
                                        display: "chip",
                                        placeholder: _ctx.translate('special-cleaning-required'),
                                        additionalValue: "",
                                        filterFields: ['additionalValue']
                                    }, null, 8, ["options", "placeholder"])
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("alternative-cleaning-procedures")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "alternativeCleaningProcedures",
                                        options: _ctx.dictionaries.cleaningProcedures,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "CleaningProcedures",
                                        display: "chip",
                                        placeholder: _ctx.translate('alternative-cleaning-procedures'),
                                        additionalValue: "",
                                        filterFields: ['additionalValue']
                                    }, null, 8, ["options", "placeholder"])
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("forbidden-cleaning-procedures")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "forbiddenCleaningProcedures",
                                        options: _ctx.dictionaries.cleaningProcedures,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "CleaningProcedures",
                                        display: "chip",
                                        placeholder: _ctx.translate('forbidden-cleaning-procedures'),
                                        additionalValue: "",
                                        filterFields: ['additionalValue']
                                    }, null, 8, ["options", "placeholder"])
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("cleaning-frequency.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_GlobeIcon, { size: "sm" })
                            ]),
                            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_LocalizedInput, {
                                        name: "cleaningFrequency",
                                        placeholder: _ctx.translate('cleaning-frequency.placeholder')
                                    }, null, 8, ["placeholder"]),
                                    _createVNode(_component_FormError, { name: "cleaningFrequency" })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AttachmentsInput, {
                                        title: _ctx.translate('additional-requirements'),
                                        name: "generalCleaningAdditionalRequirements"
                                    }, null, 8, ["title"]),
                                    _createVNode(_component_FormError, {
                                        name: `generalCleaningAdditionalRequirements[${_ctx.$utils.findErrorIndex(_ctx.errors)}].values`
                                    }, null, 8, ["name"])
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["modelValue", "initial", "errors"]))
    ]));
}
