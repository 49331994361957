import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
import CopyCleaningRequirementsModalComponent from "@/app/domains/products/components/copy-cleaning-requirements-modal.component.vue";
export const CleaningRequirementsModuleId = "cleaningRequirements";
export const CleaningRequirementsModule = (entityId, options = {}) => {
    return createModule({
        imports: [
            ModuleModule({
                id: CleaningRequirementsModuleId,
                domainId: entityId,
                readOnly: options.readOnly,
                originDomain: options.originDomain,
                copyAvailable: options.copyAvailable,
                copyTooltip: "domains.products.components.copy-module-modal.cleaning-requirements.tooltip",
                copyModuleModal: CopyCleaningRequirementsModalComponent,
            }),
        ],
    });
};
