import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { SlotBookingModuleId } from "@/app/modules/slot-booking/slot-booking.module";
import DisplaySlotBooking from "@/app/sub-modules/slot-booking/components/display-slot-booking.vue";
import EditSlotBooking from "@/app/sub-modules/slot-booking/components/edit-slot-booking.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const SlotBookingSubModule = (domainId, options = {}) => {
    const id = "slotBooking";
    const moduleId = options.moduleId ?? SlotBookingModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplaySlotBooking, ...componentSuffixes);
    const editComponent = suffixComponentName(EditSlotBooking, ...componentSuffixes);
    return createModule({
        components: [displayComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "confirmation_number",
                components: {
                    display: displayComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.slot-booking",
            },
        ],
    });
};
