import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57ef286c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-site-additional-information-view container" };
const _hoisted_2 = { class: "col-12" };
const _hoisted_3 = { class: "col-6 label" };
const _hoisted_4 = { class: "col-6 value" };
const _hoisted_5 = { class: "col-6 label" };
const _hoisted_6 = { class: "col-6 value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", _hoisted_2, [
            _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.translate("decoupled-loading")), 1),
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getTranslation(_ctx.model.decoupledLoading, "decoupled-loading")), 1)
            ]),
            _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.translate("external-cargo")), 1),
                _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.getTranslation(_ctx.model.externalCargo, "external-cargo")), 1)
            ])
        ])
    ]));
}
