import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c7c9982"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "opening-hours-table" };
const _hoisted_2 = {
    key: 0,
    class: "title"
};
const _hoisted_3 = {
    key: 1,
    class: "bold"
};
const _hoisted_4 = {
    key: 2,
    class: "col-12"
};
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "col-6 day" };
const _hoisted_7 = { class: "col-6 hours" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { key: 2 };
const _hoisted_11 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.title)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.translate("title")), 1))
            : _createCommentVNode("", true),
        (_ctx.areOpeningHoursNotDefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
            : (_openBlock(), _createElementBlock("table", _hoisted_4, [
                (_ctx.dayAndHours)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                        _createElementVNode("th", null, _toDisplayString(_ctx.translate("columns.day")), 1),
                        _createElementVNode("th", null, _toDisplayString(_ctx.translate("columns.hours")), 1)
                    ]))
                    : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekdays, (day) => {
                    return (_openBlock(), _createElementBlock("tr", { key: day }, [
                        _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.translate(`temporal.weekdays.${day}`)), 1),
                        _createElementVNode("td", _hoisted_7, [
                            (!_ctx.isOpen(day))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.translate("closed")), 1))
                                : (_ctx.isOpenAllDay(day))
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.translate("open-all-day")), 1))
                                    : (_ctx.hasNotDefinedHours(day))
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.translate("undefined")), 1))
                                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.getOpeningHours(day), (hours, index) => {
                                            return (_openBlock(), _createElementBlock("span", {
                                                class: "range",
                                                key: index
                                            }, [
                                                _createTextVNode(_toDisplayString(hours), 1),
                                                (index + 1 !== _ctx.getOpeningHours(day).length)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, ","))
                                                    : _createCommentVNode("", true)
                                            ]));
                                        }), 128))
                        ])
                    ]));
                }), 128))
            ]))
    ]));
}
