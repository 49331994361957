import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f2820904"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-vapour-return" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { key: 1 };
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_17 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6 column-description" };
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("connection-to-be-provided-by-carrier")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.connectionProvidedByCarrier), 1)
        ]),
        (_ctx.model.connectionToBeProvidedByCarrier)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("vapour-return-position")), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValue(_ctx.model?.vapourReturnPosition, "VapourReturnPosition")), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("coupling-title")), 1),
                    _createElementVNode("div", _hoisted_11, [
                        (_ctx.model.vapourReturnCoupling !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.model.vapourReturnCoupling ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_13, "-"))
                    ])
                ]),
                (_ctx.model.vapourReturnCoupling)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("coupling-type-label")), 1),
                            (_ctx.model?.vapourReturnCouplingTypes?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.vapourReturnCouplingTypes, (tag, index) => {
                                        return (_openBlock(), _createElementBlock("span", {
                                            key: tag + index,
                                            class: "tag"
                                        }, _toDisplayString(_ctx.getValue(tag, "CouplingType")), 1));
                                    }), 128))
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("coupling-size-label")), 1),
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getValue(_ctx.model.vapourReturnCouplingSize, "CouplingSize")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("coupling-configuration-label")), 1),
                            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.getValue(_ctx.model.vapourReturnConfiguration, "ConnectionConfiguration")), 1)
                        ])
                    ], 64))
                    : _createCommentVNode("", true),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.additionalVapourInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ]))
            : _createCommentVNode("", true)
    ]));
}
