import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8cb058d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "label" };
const _hoisted_3 = { class: "inputs-box col-6" };
const _hoisted_4 = { class: "col-5" };
const _hoisted_5 = { class: "label" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "label" };
const _hoisted_8 = { class: "d-flex" };
const _hoisted_9 = { class: "label col-3 col-xl-2 pe-3" };
const _hoisted_10 = { class: "label col-4 col-xxl-3" };
const _hoisted_11 = { class: "label" };
const _hoisted_12 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_TemperatureRangesInput = _resolveComponent("TemperatureRangesInput");
    const _component_InputHeatingMedium = _resolveComponent("InputHeatingMedium");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedInput = _resolveComponent("LocalizedInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((_ctx.model) = $event)),
            class: "edit-temperature-requirements-bulk-loading-view",
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                (_ctx.model)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("max-contact-temperature")), 1),
                                _createVNode(_component_RadioButton, {
                                    name: "maxContactTemperatureUnit",
                                    dictionary: _ctx.dictionaries.temperatureUnit,
                                    dictionaryId: "TemperatureUnit",
                                    isOptionDeselectable: false
                                }, null, 8, ["dictionary"]),
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("div", _hoisted_4, [
                                        _createVNode(_component_InputNumber, {
                                            class: "input-number",
                                            name: "maxContactTemperature",
                                            placeholder: _ctx.translate('max-temperature'),
                                            mode: "decimal",
                                            maxFractionDigits: 1
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "maxContactTemperature" })
                                    ])
                                ])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_TemperatureRangesInput, {
                                    modelValue: _ctx.model.tankTemperatureAtTheTimeOfLoading,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.tankTemperatureAtTheTimeOfLoading) = $event)),
                                    temperatureUnit: _ctx.model.tankTemperatureAtTheTimeOfLoadingTemperatureUnit,
                                    "onUpdate:temperatureUnit": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.tankTemperatureAtTheTimeOfLoadingTemperatureUnit) = $event)),
                                    name: "tankTemperatureAtTheTimeOfLoading",
                                    label: _ctx.translate('tank-temperature-loading'),
                                    initial: _ctx.initial,
                                    temperatureUnitDictionary: _ctx.dictionaries.temperatureUnit,
                                    errors: _ctx.errors
                                }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_TemperatureRangesInput, {
                                    modelValue: _ctx.model.loadingTemperature,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.loadingTemperature) = $event)),
                                    temperatureUnit: _ctx.model.loadingTemperatureUnit,
                                    "onUpdate:temperatureUnit": _cache[3] || (_cache[3] = ($event) => ((_ctx.model.loadingTemperatureUnit) = $event)),
                                    name: "loadingTemperature",
                                    label: _ctx.translate('loading-temperature'),
                                    initial: _ctx.initial,
                                    temperatureUnitDictionary: _ctx.dictionaries.temperatureUnit,
                                    errors: _ctx.errors
                                }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_TemperatureRangesInput, {
                                    modelValue: _ctx.model.transportTemperature,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model.transportTemperature) = $event)),
                                    temperatureUnit: _ctx.model.transportTemperatureUnit,
                                    "onUpdate:temperatureUnit": _cache[5] || (_cache[5] = ($event) => ((_ctx.model.transportTemperatureUnit) = $event)),
                                    name: "transportTemperature",
                                    label: _ctx.translate('transport-temperature'),
                                    initial: _ctx.initial,
                                    temperatureUnitDictionary: _ctx.dictionaries.temperatureUnit,
                                    errors: _ctx.errors
                                }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("heating-during-transport")), 1),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_RadioButton, {
                                            name: "heatingDuringTransport",
                                            dictionary: _ctx.dictionaries.heatingDuringTransport,
                                            dictionaryId: "HeatingDuringTransport"
                                        }, null, 8, ["dictionary"])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        (_ctx.model.heatingDuringTransport === _ctx.HeatingOrCoolingDuringTransport.Required)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_TemperatureRangesInput, {
                                            modelValue: _ctx.model.heatingSettingsDuringTransport,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.model.heatingSettingsDuringTransport) = $event)),
                                            temperatureUnit: _ctx.model.heatingSettingsDuringTransportTemperatureUnit,
                                            "onUpdate:temperatureUnit": _cache[7] || (_cache[7] = ($event) => ((_ctx.model.heatingSettingsDuringTransportTemperatureUnit) = $event)),
                                            name: "heatingSettingsDuringTransport",
                                            label: _ctx.translate('heating-settings-during-transport'),
                                            initial: _ctx.initial,
                                            temperatureUnitDictionary: _ctx.dictionaries.temperatureUnit,
                                            errors: _ctx.errors
                                        }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"])
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, { class: "mb-2" }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("heating-type")), 1),
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_RadioButton, {
                                                    name: "heatingType",
                                                    dictionary: _ctx.dictionaries.heatingType,
                                                    dictionaryId: "HeatingType"
                                                }, null, 8, ["dictionary"])
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_8, [
                                            _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.translate("allowed-heating-medium")), 1),
                                            _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate("max-heating-medium-temperature")), 1)
                                        ]),
                                        _createVNode(_component_InputHeatingMedium, {
                                            modelValue: _ctx.temperatureRestrictions,
                                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.temperatureRestrictions) = $event)),
                                            name: "heatingMediumTemperatureRestrictions",
                                            heatingMediumDictionary: _ctx.dictionaries.heatingMedium,
                                            temperatureTypeDictionary: _ctx.dictionaries.temperatureUnit,
                                            errors: _ctx.errors
                                        }, null, 8, ["modelValue", "heatingMediumDictionary", "temperatureTypeDictionary", "errors"])
                                    ]),
                                    _: 1
                                })
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.translate("cooling-during-transport")), 1),
                                _createVNode(_component_RadioButton, {
                                    name: "coolingDuringTransport",
                                    dictionary: _ctx.dictionaries.coolingDuringTransport,
                                    dictionaryId: "CoolingDuringTransport"
                                }, null, 8, ["dictionary"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                (_ctx.model.coolingDuringTransport === _ctx.HeatingOrCoolingDuringTransport.Required)
                                    ? (_openBlock(), _createBlock(_component_TemperatureRangesInput, {
                                        key: 0,
                                        modelValue: _ctx.model.coolingSettingsDuringTransport,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.model.coolingSettingsDuringTransport) = $event)),
                                        temperatureUnit: _ctx.model.coolingSettingsDuringTransportTemperatureUnit,
                                        "onUpdate:temperatureUnit": _cache[10] || (_cache[10] = ($event) => ((_ctx.model.coolingSettingsDuringTransportTemperatureUnit) = $event)),
                                        name: "coolingSettingsDuringTransport",
                                        label: _ctx.translate('cooling-settings-during-transport'),
                                        initial: _ctx.initial,
                                        temperatureUnitDictionary: _ctx.dictionaries.temperatureUnit,
                                        errors: _ctx.errors
                                    }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_12, [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_GlobeIcon, { size: "sm" })
                                ]),
                                _createVNode(_component_LocalizedInput, {
                                    name: "additionalTemperatureRequirements",
                                    placeholder: _ctx.translate('additional-temperature-requirements'),
                                    class: "col-xl-7"
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "additionalTemperatureRequirements" })
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
