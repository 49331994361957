import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5ae958e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "driving-lanes-input" };
const _hoisted_2 = { class: "title globe-icon-wrapper" };
const _hoisted_3 = { class: "header col-3" };
const _hoisted_4 = { class: "header col-8" };
const _hoisted_5 = { class: "column col-3" };
const _hoisted_6 = { class: "name bold" };
const _hoisted_7 = { class: "column col-8" };
const _hoisted_8 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_EditableListInput = _resolveComponent("EditableListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.translate("driving-lanes.edit.title")), 1),
            _createVNode(_component_GlobeIcon)
        ]),
        _createVNode(_component_EditableListInput, {
            modelValue: _ctx.lanesLocalized,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.lanesLocalized) = $event)),
            add: _ctx.add,
            edit: _ctx.edit,
            remove: _ctx.remove,
            addBtn: _ctx.translate('driving-lanes.add'),
            emptyMsg: _ctx.emptyStateMsg || _ctx.translate('empty')
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("driving-lanes.edit.column.number")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("driving-lanes.edit.column.purpose")), 1)
            ]),
            row: _withCtx((lane) => [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(lane.laneNumber), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(lane.lanePurpose), 1)
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "add", "edit", "remove", "addBtn", "emptyMsg"])
    ]));
}
