import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { useMaintenanceStore } from "@/app/maintenance/state/maintenance.state";
import { HttpErrorInterceptorToken } from "@/app/http/tokens/http-error-interceptor.token";
let MaintenanceInterceptorService = class MaintenanceInterceptorService {
    maintenanceStore;
    constructor() {
        this.maintenanceStore = useMaintenanceStore();
    }
    onResponseError(config, error) {
        if (error.response && error.response.headers?.["x-maintenance"] === "true" && error.response?.status === 503) {
            this.maintenanceStore.setMaintenanceState(true);
        }
        return error;
    }
};
MaintenanceInterceptorService = __decorate([
    service({ token: HttpErrorInterceptorToken }),
    __metadata("design:paramtypes", [])
], MaintenanceInterceptorService);
export { MaintenanceInterceptorService };
