import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputMask = _resolveComponent("InputMask");
    return (_openBlock(), _createBlock(_component_InputMask, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        class: "temperature-ranges-input-date",
        mask: "99/99",
        placeholder: "DD/MM"
    }, null, 8, ["modelValue"]));
}
