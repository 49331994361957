import { createModule } from "@/di/module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { AssignedProductClustersModule } from "@/app/domains/assigned-product-clusters/assigned-product-clusters.module";
import { AssignedProductMastersModule } from "@/app/domains/assigned-product-masters/assigned-product-masters.module";
import { AssignedProductsModule } from "@/app/domains/assigned-products/assigned-products.module";
import { AvailableEquipmentPackedSubModule } from "@/app/sub-modules/available-equipment-packed/available-equipment-packed.module";
import { AvailableEquipmentSubModule } from "@/app/sub-modules/available-equipment/available-equipment.module";
import { ContactDataSubModule } from "@/app/sub-modules/contact-data/contact-data.module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { DriveInTimeRestrictionsSubModule } from "@/app/sub-modules/drive-in-time-restrictions/drive-in-time-restrictions.module";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { InstallationsModule } from "@/app/modules/installations/installations.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { ReceivingTankSubModule } from "@/app/sub-modules/receiving-tank/receiving-tank.module";
import { RegistrationSubModule } from "@/app/sub-modules/registration/registration.module";
import { SafetyAndSecurityModule } from "@/app/modules/safety-and-security/safety-and-security.module";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
import { SlotBookingModule } from "@/app/modules/slot-booking/slot-booking.module";
import { SlotBookingSubModule } from "@/app/sub-modules/slot-booking/slot-booking.module";
import { UlpBasicInformationSubModule } from "@/app/sub-modules/basic-information/un-loading-point/ulp-basic-information.module";
import { UnLoadingAreaSubModule } from "@/app/sub-modules/un-loading-area/un-loading-area.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { WorkingOnTopSubModule } from "@/app/sub-modules/working-on-top/working-on-top.module";
import { SelectedLPFilterService } from "@/app/domains/assigned-products/services/selected-lp-filter.service";
import { SelectedSiteFilterService } from "@/app/domains/sites/services/selected-site-filter.service";
import CreateLoadingPointFormComponent from "@/app/domains/loading-points/components/create-loading-point-form.component.vue";
import LoadingPointsContextMenuHeaderComponent from "@/app/domains/loading-points/components/loading-points-context-menu-header.component.vue";
import LoadingPointsListComponent from "@/app/domains/loading-points/components/loading-points-list.component.vue";
import { LoadingPointAssignedProductClustersDomainId } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
import { LoadingPointAssignedProductMastersDomainId } from "@/app/domains/assigned-product-masters/assigned-product-masters.constants";
import { LoadingPointAssignedProductsDomainId } from "@/app/domains/assigned-products/assigned-products.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const LoadingPointsModule = createModule({
    components: [LoadingPointsContextMenuHeaderComponent, CreateLoadingPointFormComponent, LoadingPointsListComponent],
    imports: [
        DomainModule({
            parent: SiteDomainId,
            id: LoadingPointDomainId,
            icon: "svg/loading_point",
            detailsIcon: "svg/loading_point",
            lists: [
                {
                    id: "all",
                    default: true,
                    filter: SelectedSiteFilterService,
                },
            ],
            domainTranslationPrefix: "loading",
            forBusinessPartner: true,
            baseUrl: "loadingpoints",
            components: {
                contextMenuHeader: LoadingPointsContextMenuHeaderComponent,
                createForm: CreateLoadingPointFormComponent,
                table: LoadingPointsListComponent,
            },
        }),
        GeneralInformationModule(LoadingPointDomainId),
        UlpBasicInformationSubModule(LoadingPointDomainId),
        PostalAddressSubModule(LoadingPointDomainId),
        AccessRestrictionsSubModule(LoadingPointDomainId),
        RegistrationSubModule(LoadingPointDomainId),
        OpeningHoursSubModule(LoadingPointDomainId),
        DriveInTimeRestrictionsSubModule(LoadingPointDomainId),
        ContactDataSubModule(LoadingPointDomainId),
        ProcessesSubModule(LoadingPointDomainId),
        //
        InstallationsModule(LoadingPointDomainId),
        UnLoadingAreaSubModule(LoadingPointDomainId),
        WorkingOnTopSubModule(LoadingPointDomainId),
        AvailableEquipmentSubModule(LoadingPointDomainId),
        AvailableEquipmentPackedSubModule(LoadingPointDomainId),
        ReceivingTankSubModule(LoadingPointDomainId),
        //
        SlotBookingModule(LoadingPointDomainId),
        SlotBookingSubModule(LoadingPointDomainId),
        //
        SafetyAndSecurityModule(LoadingPointDomainId),
        VehicleAndPPESubModule(LoadingPointDomainId, { moduleId: SafetyAndSecurityModuleId }),
        //
        createModule({
            imports: [AssignedProductsModule(LoadingPointDomainId, LoadingPointAssignedProductsDomainId)],
        }),
        createModule({
            imports: [AssignedProductClustersModule(LoadingPointDomainId, LoadingPointAssignedProductClustersDomainId)],
        }),
        createModule({
            imports: [AssignedProductMastersModule(LoadingPointDomainId, LoadingPointAssignedProductMastersDomainId)],
        }),
    ],
    providers: [SelectedLPFilterService, { token: TranslationPrefixToken, useValue: "domains.loading-point" }],
});
