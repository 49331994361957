import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplaySpecialProductRequirements from "@/app/sub-modules/special-product-requirements/components/display-special-product-requirements.vue";
import DisplaySpecialProductRequirementsView from "@/app/sub-modules/special-product-requirements/components/display-special-product-requirements-view.vue";
import EditSpecialProductRequirements from "@/app/sub-modules/special-product-requirements/components/edit-special-product-requirements.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { ProductsSafetyModuleId } from "@/app/modules/products-safety/products-safety.module";
export const SpecialProductRequirementsSubModule = (domainId, options = {}) => {
    const id = "specialProductRequirements";
    const moduleId = options.moduleId ?? ProductsSafetyModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplaySpecialProductRequirements, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplaySpecialProductRequirementsView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditSpecialProductRequirements, ...componentSuffixes);
    return createModule({
        components: [displayComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "home_repair_service",
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.special-product-requirements",
            },
        ],
    });
};
