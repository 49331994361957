import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditWeighingComponent from "@/app/sub-modules/weighing/components/edit-weighing.component.vue";
import DisplayWeighingComponent from "@/app/sub-modules/weighing/components/display-weighing.component.vue";
import DisplayWeighingViewComponent from "@/app/sub-modules/weighing/components/display-weighing-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const WeighingSubModule = (domainId, options = {}) => {
    const id = "weighing";
    const moduleId = options.moduleId ?? InstructionsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditWeighingComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayWeighingComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayWeighingViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "balance",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.weighing",
            },
        ],
    });
};
