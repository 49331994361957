import VerificationsListComponent from "@/app/domains/verification-requests/components/verification-requests-list.component.vue";
import VerificationsContextMenuHeaderComponent from "@/app/domains/verification-requests/components/verification-requests-context-menu-header.component.vue";
import VerificationDoneSuccessModalComponent from "@/app/domains/verification-requests/fact-sheets/components/verification-done-modal.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { VerificationRequestsDomainId, VerificationRequestedListId, VerificationConfirmedListId, VerificationVerifiedListId, } from "@/app/domains/verification-requests/verification-requests.constants";
import { SelectedUserFilterService } from "./services/selected-user-filter.service";
import { ConfirmedRequestFilterService } from "@/app/domains/verification-requests/services/confirmed-request-filter.service";
import { FeedbackDoneFilterService } from "@/app/domains/verification-requests/services/feedback-done-filter.service";
import { FactSheetsModule } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.module";
import { FactSheetsDomainId, VerificationDoneSuccessModalId, } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
import { ModalModule } from "@/app/modals/modules/modal.module";
export const VerificationRequestsModule = createModule({
    components: [
        VerificationsContextMenuHeaderComponent,
        VerificationsListComponent,
        VerificationDoneSuccessModalComponent,
    ],
    imports: [
        DomainModule({
            id: VerificationRequestsDomainId,
            icon: "summarize",
            detailsIcon: "summarize",
            accessCondition(rolesService) {
                return rolesService?.isVerifier();
            },
            lists: [
                {
                    id: VerificationRequestedListId,
                    default: true,
                    filter: SelectedUserFilterService,
                },
                {
                    id: VerificationVerifiedListId,
                    filter: FeedbackDoneFilterService,
                },
                {
                    id: VerificationConfirmedListId,
                    filter: ConfirmedRequestFilterService,
                },
            ],
            domainTranslationPrefix: "verification-requests",
            forBusinessPartner: true,
            baseUrl: "requirementprofiles/verificationView",
            readOnly: true,
            sideNavTier: 2,
            public: true,
            components: {
                contextMenuHeader: VerificationsContextMenuHeaderComponent,
                table: VerificationsListComponent,
            },
        }),
        ModalModule(VerificationDoneSuccessModalId, VerificationDoneSuccessModalComponent),
        FactSheetsModule(VerificationRequestsDomainId, VerificationRequestsDomainId + FactSheetsDomainId),
    ],
    providers: [
        SelectedUserFilterService,
        ConfirmedRequestFilterService,
        FeedbackDoneFilterService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.verification-requests",
        },
    ],
});
