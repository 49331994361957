import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c07a5d4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "toaster" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Toast = _resolveComponent("Toast");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Toast, {
            position: "top-center",
            "success-icon": "pi pi-check",
            "error-icon": "pi pi-times"
        })
    ]));
}
