import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "edit-vehicle-and-ppe" };
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EditVehicleAndPPESiteView = _resolveComponent("EditVehicleAndPPESiteView");
    const _component_EditVehicleAndPPEUlpView = _resolveComponent("EditVehicleAndPPEUlpView");
    const _component_EditVehicleAndPEEFsView = _resolveComponent("EditVehicleAndPEEFsView");
    const _component_EditVehicleAndPPEApView = _resolveComponent("EditVehicleAndPPEApView");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isSitesDomain)
                    ? (_openBlock(), _createBlock(_component_EditVehicleAndPPESiteView, {
                        key: 0,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.updateModel($event))),
                        initial: _ctx.initial,
                        dictionary: _ctx.ppeRequirementDictionary
                    }, null, 8, ["initial", "dictionary"]))
                    : (_ctx.isUlpDomain)
                        ? (_openBlock(), _createBlock(_component_EditVehicleAndPPEUlpView, {
                            key: 1,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.updateModel($event))),
                            initial: _ctx.initial,
                            dictionary: _ctx.ppeRequirementDictionary
                        }, null, 8, ["initial", "dictionary"]))
                        : (_ctx.isFactSheetsDomain)
                            ? (_openBlock(), _createBlock(_component_EditVehicleAndPEEFsView, {
                                key: 2,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_ctx.updateModel($event))),
                                initial: _ctx.initial,
                                dictionary: _ctx.ppeRequirementDictionary
                            }, null, 8, ["initial", "dictionary"]))
                            : (_openBlock(), _createBlock(_component_EditVehicleAndPPEApView, {
                                key: 3,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.updateModel($event))),
                                initial: _ctx.initial,
                                dictionary: _ctx.ppeRequirementDictionary
                            }, null, 8, ["initial", "dictionary"]))
            ]))
    ]));
}
