import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { BackendErrorResolverToken } from "@/app/validation/tokens/backend-error-resolver.token";
const validationErrorByCode = [
    {
        code: "V1006",
        message: "core.services.api-error-interceptor.max-characters",
    },
    {
        code: "V1005",
        message: "core.services.api-error-interceptor.at-least-characters",
    },
    {
        code: "V1011",
        message: "core.services.api-error-interceptor.decimal-precision",
    },
    {
        code: "V1015",
        message: "core.services.api-error-interceptor.max-number",
    },
    {
        code: "V1017",
        message: "core.services.api-error-interceptor.exact-characters",
    },
    {
        code: "V1018",
        message: "core.services.api-error-interceptor.invalid-value",
    },
    {
        code: "A1082",
        message: "modular-content.components.connect-modal.toast-invalid-code",
    },
    {
        code: "V1023",
        message: "core.services.api-error-interceptor.invalid-format-date",
        condition: "DD/MM",
    },
    {
        code: "V1024",
        message: "core.services.api-error-interceptor.invalid-format-date",
        condition: "DD/MM",
    },
    {
        code: "V1025",
        message: "core.services.api-error-interceptor.english-missing",
    },
    {
        code: "V1026",
    },
];
let ConditionValidationErrorResolver = class ConditionValidationErrorResolver {
    errorCodes = validationErrorByCode.map((it) => it.code);
    resolve(error) {
        if (!error) {
            return {};
        }
        const errorCode = this.errorCodes.find((it) => it === error.message);
        if (error.message !== errorCode || !error.field) {
            return error;
        }
        const message = validationErrorByCode.find((it) => it.code === error.message)?.message ?? "";
        const fallbackCondition = validationErrorByCode.find((it) => it.code === error.message)?.condition;
        const condition = error.condition ?? fallbackCondition ?? "";
        return {
            ...error,
            message,
            args: {
                condition,
            },
        };
    }
    canResolve(error) {
        const errorCode = this.errorCodes.find((it) => it === error?.message);
        return error?.message === errorCode;
    }
};
ConditionValidationErrorResolver = __decorate([
    service({ token: BackendErrorResolverToken })
], ConditionValidationErrorResolver);
export { ConditionValidationErrorResolver };
