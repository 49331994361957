import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
import { DriverBriefingAttachmentModalId } from "./driver-briefing.constants";
import { ModalModule } from "@/app/modals/modules/modal.module";
import DisplayDriverBriefing from "@/app/sub-modules/driver-briefing/components/display-driver-briefing.component.vue";
import DisplayDriverBriefingView from "@/app/sub-modules/driver-briefing/components/display-driver-briefing-view.component.vue";
import EditDriverBriefing from "@/app/sub-modules/driver-briefing/components/edit-driver-briefing.component.vue";
import DriverBriefingModalComponent from "@/app/sub-modules/driver-briefing/driver-briefings-input/driver-briefing-modal.component.vue";
export const DriverBriefingSubModule = (domainId, options = {}) => {
    const id = "driverBriefing";
    const moduleId = options.moduleId ?? InstructionsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayDriverBriefing, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayDriverBriefingView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditDriverBriefing, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            ModalModule(DriverBriefingAttachmentModalId, DriverBriefingModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "menu_book",
                showChangeHistory: options.showChangeHistory,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.driver-briefing",
            },
        ],
    });
};
