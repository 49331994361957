import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e03092c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "input-time-freetext" };
const _hoisted_2 = {
    key: 0,
    class: "error-feedback"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputMask = _resolveComponent("InputMask");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_InputMask, {
            class: _normalizeClass([{ 'error-input-field': _ctx.error }, "no-transition"]),
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            mask: "99:99",
            name: "input-time",
            onBlur: _ctx.onBlur,
            placeholder: _ctx.placeholderRequired ? _ctx.translate('placeholder') : '',
            disabled: _ctx.disabled
        }, null, 8, ["class", "modelValue", "onBlur", "placeholder", "disabled"]),
        (_ctx.error)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
            : _createCommentVNode("", true)
    ]));
}
