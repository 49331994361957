import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00f991c1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "verification-status" };
const _hoisted_2 = { class: "status" };
const _hoisted_3 = { class: "status-change-button-group" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Badge = _resolveComponent("Badge");
    const _component_AppButton = _resolveComponent("AppButton");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.translate("status-verification")), 1),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Badge, {
                color: _ctx.verificationStatusStyles[_ctx.requirementProfile.statusVerification]
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getStatus(_ctx.requirementProfile.statusVerification)), 1)
                ]),
                _: 1
            }, 8, ["color"]),
            _createElementVNode("div", null, _toDisplayString(_ctx.translate("global.columns.last-update")) + " :", 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.updateTime), 1)
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_AppButton, {
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.changeStatus(_ctx.VerificationStatus.Requested))),
                disabled: _ctx.requestVerificationButtonDisabled
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("status-buttons.request-verification.label")), 1)
                ]),
                _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_AppButton, {
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.changeStatus(_ctx.VerificationStatus.NoFeedBack))),
                disabled: _ctx.concludeVerificationButtonDisabled
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("status-buttons.conclude-without-feedback.label")), 1)
                ]),
                _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_AppButton, {
                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.changeStatus(_ctx.VerificationStatus.NotStarted))),
                disabled: _ctx.resetWorkflowButtonDisabled
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("status-buttons.reset-workflow.label")), 1)
                ]),
                _: 1
            }, 8, ["disabled"])
        ])), [
            [_directive_tooltip, {
                    value: _ctx.translate('status-buttons.status-verification-tooltip'),
                    disabled: _ctx.isEmailAvailable,
                }]
        ])
    ]));
}
