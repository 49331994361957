import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-01ed2133"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-physical-check-in" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "section" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-xl-7 mb-4" };
const _hoisted_6 = { class: "section" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-xl-7" };
const _hoisted_9 = { class: "section" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-xl-7" };
const _hoisted_12 = { class: "checkboxes-wrapper" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-xl-7 custom-padding" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AddressInput = _resolveComponent("AddressInput");
    const _component_LocationInput = _resolveComponent("LocationInput");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_EditOpeningHoursWidget = _resolveComponent("EditOpeningHoursWidget");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial
        }, {
            default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.translate("check-in-presence-title")), 1),
                _createElementVNode("section", _hoisted_2, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("first-reception-address")), 1),
                    _createVNode(_component_AddressInput, {
                        initial: _ctx.initial?.postalAddress,
                        modelValue: _ctx.model.postalAddress,
                        "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event) => ((_ctx.model.postalAddress) = $event)),
                            _cache[1] || (_cache[1] = ($event) => (_ctx.model.postalAddress = $event))
                        ]
                    }, null, 8, ["initial", "modelValue"])
                ]),
                _createElementVNode("section", _hoisted_3, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("first-reception-location")), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_LocationInput, {
                                id: "physical-checkin-location",
                                modelValue: _ctx.model.location,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.location) = $event)),
                                initial: _ctx.initial?.location,
                                class: "location"
                            }, null, 8, ["modelValue", "initial"])
                        ])
                    ])
                ]),
                _createElementVNode("section", _hoisted_6, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("language-title")), 1),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("select-language-input")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "languagesRegistrationOffices",
                                        options: _ctx.dictionaryLanguages,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "Languages",
                                        placeholder: _ctx.translate('select-language-input'),
                                        display: "chip"
                                    }, null, 8, ["options", "placeholder"])
                                ]),
                                _: 1
                            })
                        ])
                    ])
                ]),
                _createElementVNode("section", _hoisted_9, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("identification-requirement-title")), 1),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.setInitialCheckboxesValues, (driverLicence) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: driverLicence.id
                                    }, [
                                        _createVNode(_component_FormField, { class: "driver-field" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_AppCheckbox, {
                                                    modelValue: driverLicence.value,
                                                    "onUpdate:modelValue": [($event) => ((driverLicence.value) = $event), ($event) => (_ctx.updateCheckbox($event, driverLicence.id))]
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_InputLabel, { class: "driver-label" }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.dictionaryTranslate("DriverIdentificationRequirement", driverLicence)), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]));
                                }), 128))
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("section", null, [
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_EditOpeningHoursWidget, {
                                "opening-hours": _ctx.model.openingHours,
                                title: true,
                                titleString: "title",
                                "onUpdate:openingHours": _cache[3] || (_cache[3] = ($event) => (_ctx.model.openingHours = $event))
                            }, null, 8, ["opening-hours"])
                        ])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial"])
    ]));
}
