import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditShipmentPreNotificationComponent from "@/app/sub-modules/shipment-pre-notification/components/edit-shipment-pre-notification.vue";
import DisplayShipmentPreNotificationComponent from "@/app/sub-modules/shipment-pre-notification/components/display-shipment-pre-notification.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { SlotBookingModuleId } from "@/app/modules/slot-booking/slot-booking.module";
export const ShipmentPreNotificationSubModule = (domainId, options = {}) => {
    const id = "shipmentPreNotification";
    const moduleId = options.moduleId ?? SlotBookingModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditShipmentPreNotificationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayShipmentPreNotificationComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "notification_add",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.shipment-pre-notification",
            },
        ],
    });
};
