var _a;
import { __decorate, __metadata } from "tslib";
import { isUndefined } from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { ToastService } from "@/app/shared/services/toast.service";
import { ApiErrorCode } from "@/app/core/types/api-error-code.type";
import { HttpErrorInterceptorToken } from "@/app/http/tokens/http-error-interceptor.token";
const errorMessageConfigurations = [
    {
        errorCode: "A1009",
        message: "core.services.api-error-interceptor.invalid-email",
    },
    {
        errorCode: "A1026",
        message: "core.services.api-error-interceptor.lane-company-permission",
    },
    {
        errorCode: "A1030",
        message: "core.services.api-error-interceptor.last-company-admin",
    },
    {
        errorCode: "A1041",
        message: "core.services.api-error-interceptor.no-proper-role",
    },
    {
        errorCode: "A1051",
        message: "core.services.api-error-interceptor.existing-requirement-profile",
    },
    {
        errorCode: "A1053",
        message: "core.services.api-error-interceptor.no-permission-to-add-new-user",
    },
    {
        errorCode: "A1064",
        message: "core.services.api-error-interceptor.public-api-consumer",
    },
    {
        errorCode: "A1069",
        message: "core.services.api-error-interceptor.product-assigned-to-master-product",
    },
    {
        errorCode: "A1072",
        message: "core.services.api-error-interceptor.existing-lane",
    },
    {
        errorCode: "A1076",
        message: "core.services.api-error-interceptor.lane-only-bp-selected",
    },
    {
        errorCode: "A1082",
        message: "modular-content.components.connect-modal.toast-invalid-code",
    },
    {
        errorCode: "A1118",
        message: "domains.assigned-products.services.remove-product-from-product-master.toast-message.product-not-assigned-error",
    },
    {
        errorCode: "V1003",
        message: "core.services.api-error-interceptor.empty-field",
    },
    {
        errorCode: "V1005",
        message: "core.services.api-error-interceptor.at-least-characters",
    },
    {
        errorCode: "V1006",
        message: "core.services.api-error-interceptor.max-characters",
    },
    {
        errorCode: "V1010",
        message: "core.services.api-error-interceptor.conflict",
    },
    {
        errorCode: "V1012",
        message: "sub-modules.gates.errors.duplication.driving-lane",
        predicate: (error) => error.paths.some((path) => ["gates", "drivingLanes", "laneNumber"].every((segment) => path.includes(segment))),
    },
    {
        errorCode: "V1012",
        message: "sub-modules.gates.errors.duplication.gate-name",
        predicate: (error) => error.paths.some((path) => ["gates", "name"].every((segment) => path.includes(segment))),
    },
    {
        errorCode: "V1012",
        message: "sub-modules.check-in.errors.duplication.name",
        predicate: (error) => error.paths.some((path) => ["checkIns", "name"].every((segment) => path.includes(segment))),
    },
    {
        errorCode: "V1012",
        message: "sub-modules.weighing-bridges.errors.duplication.name",
        predicate: (error) => error.paths.some((path) => ["weighingBridges", "name"].every((segment) => path.includes(segment))),
    },
    {
        errorCode: "V1015",
        message: "core.services.api-error-interceptor.max-number",
    },
    {
        errorCode: "V1017",
        message: "core.services.api-error-interceptor.exact-characters",
    },
    {
        errorCode: "V1025",
        message: "core.services.api-error-interceptor.missing-main-lang",
    },
];
let ApiErrorInterceptorService = class ApiErrorInterceptorService {
    toastService;
    suppressedErrorCodes = [ApiErrorCode.DeleteRestriction, ApiErrorCode.Unauthorized];
    constructor(toastService) {
        this.toastService = toastService;
    }
    onResponseError(config, error) {
        if (error.isAxiosError) {
            const errors = error.response?.data.errors;
            if (errors) {
                this.showErrorToasts(errors);
            }
            else {
                this.showGenericErrorToast();
            }
        }
        return error;
    }
    showErrorToasts(errors) {
        for (const error of errors) {
            if (this.isErrorSuppressed(error)) {
                continue;
            }
            const toasts = this.getErrorToasts(error);
            for (const toast of toasts) {
                this.toastService.showErrorToast(toast);
            }
        }
    }
    showGenericErrorToast() {
        const message = this.getGenericErrorMessage();
        const toast = this.createErrorToast(message);
        this.toastService.showErrorToast(toast);
    }
    isErrorSuppressed(error) {
        return this.suppressedErrorCodes.includes(error.errorCode);
    }
    getErrorToasts(error) {
        const messages = this.getErrorMessages(error);
        return messages.map((message) => this.createErrorToast(message, error.condition));
    }
    getErrorMessages(error) {
        const configuredMessages = this.getConfiguredErrorMessages(error);
        const fallbackMessages = this.getFallbackErrorMessages(error);
        return configuredMessages.length > 0 ? configuredMessages : fallbackMessages;
    }
    getConfiguredErrorMessages(error) {
        return errorMessageConfigurations
            .filter((configuration) => configuration.errorCode === error.errorCode)
            .reduce((messages, configuration) => {
            if (isUndefined(configuration.predicate) || configuration.predicate(error)) {
                messages.push(configuration.message);
            }
            return messages;
        }, []);
    }
    getFallbackErrorMessages(error) {
        return [this.getBackendErrorMessage(error) || this.getGenericErrorMessage()];
    }
    getBackendErrorMessage(error) {
        return error.errorDescription;
    }
    getGenericErrorMessage() {
        return "core.services.api-error-interceptor.toast-message";
    }
    createErrorToast(message, condition) {
        return { message, condition };
    }
};
ApiErrorInterceptorService = __decorate([
    service({ token: HttpErrorInterceptorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof ToastService !== "undefined" && ToastService) === "function" ? _a : Object])
], ApiErrorInterceptorService);
export { ApiErrorInterceptorService };
