import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-843c672e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row align-items-center g-3" };
const _hoisted_2 = { class: "col-auto" };
const _hoisted_3 = { class: "input" };
const _hoisted_4 = { class: "col-auto" };
const _hoisted_5 = { class: "unit" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    return (_openBlock(), _createBlock(_component_FormField, null, {
        default: _withCtx(() => [
            _createVNode(_component_InputLabel, null, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_InputNumber, {
                            name: _ctx.name,
                            placeholder: _ctx.translate('global.temporal.minutes.placeholder'),
                            mode: "integer",
                            min: 0,
                            maxLength: 3
                        }, null, 8, ["name", "placeholder"])
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("global.temporal.minutes.label")), 1)
                ])
            ]),
            _createVNode(_component_FormError, { name: _ctx.name }, null, 8, ["name"])
        ]),
        _: 1
    }));
}
