import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditSafetyTestComponent from "@/app/sub-modules/safety-test/components/edit-safety-test.component.vue";
import DisplaySafetyTestComponent from "@/app/sub-modules/safety-test/components/display-safety-test.component.vue";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { OpeningHoursModalId } from "@/app/shared/components/opening-hours-widget/opening-hours.constants";
import EditOpeningHoursModalComponent from "@/app/shared/components/opening-hours-widget/edit-opening-hours-modal.component.vue";
export const SafetyTestSubModule = (domainId, options = {}) => {
    const id = "safetyTest";
    const moduleId = options.moduleId ?? SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditSafetyTestComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplaySafetyTestComponent, ...componentSuffixes);
    const modalComponent = suffixComponentName(EditOpeningHoursModalComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, modalComponent],
        imports: [
            ModalModule(OpeningHoursModalId, EditOpeningHoursModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "content_paste",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.safety-test",
            },
        ],
    });
};
