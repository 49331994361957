import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c760c20c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "download-specifications-modal"
};
const _hoisted_2 = { class: "d-flex justify-content-between mb-4" };
const _hoisted_3 = { class: "options" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = {
    key: 0,
    class: "mt-0 mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('modal-header'),
        showCloseIcon: false
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.modalService.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.downloadMethod,
                        loading: _ctx.isDownloading
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.download")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading"])
                ]),
                _: 1
            })
        ]),
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_InputLabel, { class: "mb-0" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("select-specifications")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_ButtonGroup, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_AppButton, {
                                    class: "p-button-tertiary",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.setAllCheckBoxValues(true)))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("buttons.select-all")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_AppButton, {
                                    class: "p-button-secondary",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.setAllCheckBoxValues(false)))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("buttons.clear-all")), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkBoxes, (sectionCheckBoxes, sectionName) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: sectionName }, [
                                _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.translate(_ctx.paramCase(sectionName))), 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sectionCheckBoxes, (checkBox) => {
                                    return (_openBlock(), _createBlock(_component_FormField, {
                                        key: checkBox.id,
                                        class: "checkbox-item"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_AppCheckbox, {
                                                modelValue: checkBox.value,
                                                "onUpdate:modelValue": [($event) => ((checkBox.value) = $event), ($event) => (_ctx.updateCheckBox($event, checkBox.id))],
                                                label: _ctx.dictionaryTranslate(_ctx.pascalCase(sectionName), checkBox),
                                                disabled: checkBox.disabled
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled"])
                                        ]),
                                        _: 2
                                    }, 1024));
                                }), 128)),
                                (Object.keys(_ctx.checkBoxes).indexOf(sectionName) !== Object.keys(_ctx.checkBoxes).length - 1)
                                    ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 128))
                    ])
                ]))
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
