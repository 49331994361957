export const VerificationRequestsDomainId = "VerificationRequests";
export const VerificationRequestedListId = "requested";
export const VerificationConfirmedListId = "confirmed";
export const VerificationVerifiedListId = "verified";
export const VerificationRequestsRouteName = "verification-requests-list";
export const RequirementProfilesFactSheetsDomainId = "RequirementProfilesFactSheets";
export const VerificationRequestsFactSheetsDomainId = "VerificationRequestsFactSheets";
export const VerificationRequestsEditRoute = "verification-requests-fact-sheets-edit/factsheet";
export const VerificationRequestsDisplayRoute = "verification-requests-fact-sheets-details";
export const RequirementProfileFactSheetRoute = "requirement-profiles-fact-sheets-details/factsheet";
