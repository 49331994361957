import { ModalModule } from "@/app/modals/modules/modal.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import AccessRestrictionInputComponent from "@/app/shared/components/access-restriction/access-restriction-input.component.vue";
import AccessRestrictionComponent from "@/app/shared/components/access-restriction/access-restriction.component.vue";
import AttachmentModalComponent from "@/app/shared/components/attachment-widget/attachment-modal.component.vue";
import { AttachmentModalId } from "@/app/shared/components/attachment-widget/attachment.constants";
import DisplayAccessRestrictionsComponent from "@/app/sub-modules/access-restrictions/components/display-access-restrictions.component.vue";
import EditAccessRestrictionsComponent from "@/app/sub-modules/access-restrictions/components/edit-access-restrictions.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { AccessRestrictionsSubModuleId } from "@/app/sub-modules/access-restrictions/access-restrictions.constants";
export const AccessRestrictionsSubModule = (domainId, options = {}) => {
    const id = options.id ?? AccessRestrictionsSubModuleId;
    const moduleId = options.moduleId ?? GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditAccessRestrictionsComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayAccessRestrictionsComponent, ...componentSuffixes);
    return createModule({
        components: [
            editComponent,
            displayComponent,
            AccessRestrictionComponent,
            AccessRestrictionInputComponent,
            AttachmentModalComponent,
        ],
        imports: [
            ModalModule(AttachmentModalId, AttachmentModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "local_shipping",
                publicInformation: options.publicInformation,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.access-restrictions",
            },
        ],
    });
};
