import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57c49c1e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = {
    key: 0,
    class: "configure-sub-modules"
};
const _hoisted_3 = {
    key: 0,
    class: "enabled-sub-modules"
};
const _hoisted_4 = {
    key: 2,
    class: "no-sub-modules"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_CollapsiblePanel = _resolveComponent("CollapsiblePanel");
    return (_openBlock(), _createElementBlock("div", {
        class: "sub-modules-edit",
        "data-qa-id": _ctx.$options.name
    }, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                class: "loader",
                center: ""
            }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.hasTemporaryMode && _ctx.someOptional && !_ctx.isForbidden(_ctx.errorResponse))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (!_ctx.inheritsSubModules && !_ctx.isFactSheetsDomain)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.translate("enabled-count", { enabled: _ctx.enabledSubModules, total: _ctx.subModuleServices.length })), 1))
                            : _createCommentVNode("", true),
                        (!_ctx.inheritsSubModules && !_ctx.isFactSheetsDomain)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 1,
                                class: "p-button-secondary",
                                "data-qa-id": "configure-sub-modules-button",
                                onClick: _ctx.configureSubModules
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "settings" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.configure")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.isForbidden(_ctx.errorResponse))
                    ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 1 }))
                    : (_ctx.areSubModulesDisabled())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("no-submodule.header")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("no-submodule.description")), 1),
                            _createVNode(_component_AppButton, {
                                "data-qa-id": "configure-sub-modules-button",
                                onClick: _ctx.configureSubModules
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.add-submodule")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.subModuleServices, (subModuleService) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                                key: subModuleService.getMetadata().id
                            }, [
                                (_ctx.isEnabled(subModuleService.getMetadata().id))
                                    ? _withDirectives((_openBlock(), _createBlock(_component_CollapsiblePanel, {
                                        key: 0,
                                        title: _ctx.subModuleTitle(subModuleService),
                                        noHeader: _ctx.hasTemporaryMode || _ctx.isSeparatorSubModule(subModuleService.getMetadata().id),
                                        publicInfo: subModuleService.getMetadata().publicInformation && !_ctx.company?.managedByExternalCompany,
                                        subModuleHeaderText: _ctx.subModuleTitle(subModuleService),
                                        subModuleId: subModuleService.getMetadata().id,
                                        subModuleDescription: _ctx.subModuleDescription(subModuleService.getMetadata()),
                                        collapsible: !_ctx.notCollapsibleDomains,
                                        "data-qa-id": subModuleService.getMetadata().id
                                    }, {
                                        default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component(subModuleService)), { subModuleService: subModuleService }, null, 8, ["subModuleService"]))
                                        ]),
                                        _: 2
                                    }, 1032, ["title", "noHeader", "publicInfo", "subModuleHeaderText", "subModuleId", "subModuleDescription", "collapsible", "data-qa-id"])), [
                                        [_vShow, !_ctx.isConfirmActive(subModuleService)]
                                    ])
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 128))
            ], 64))
    ], 8, _hoisted_1));
}
