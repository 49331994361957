import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayFieldNumber = _resolveComponent("DisplayFieldNumber");
    const _component_DisplaySection = _resolveComponent("DisplaySection");
    return (_openBlock(), _createBlock(_component_DisplaySection, null, {
        default: _withCtx(() => [
            _createVNode(_component_DisplayFieldNumber, {
                name: "earliestDriveInBeforeOpening",
                label: _ctx.translate('earliest-drive-in-before-opening'),
                value: _ctx.model.earliestDriveInBeforeOpening,
                unit: _ctx.translate('global.temporal.minutes.label')
            }, null, 8, ["label", "value", "unit"]),
            _createVNode(_component_DisplayFieldNumber, {
                name: "latestDriveInBeforeClosing",
                label: _ctx.translate('latest-drive-in-before-closing'),
                value: _ctx.model.latestDriveInBeforeClosing,
                unit: _ctx.translate('global.temporal.minutes.label')
            }, null, 8, ["label", "value", "unit"]),
            _createVNode(_component_DisplayFieldNumber, {
                name: "earliestDriveInBeforePlannedSlot",
                label: _ctx.translate('earliest-drive-in-before-planned-slot'),
                value: _ctx.model.earliestDriveInBeforePlannedSlot,
                unit: _ctx.translate('global.temporal.minutes.label')
            }, null, 8, ["label", "value", "unit"]),
            _createVNode(_component_DisplayFieldNumber, {
                name: "latestDriveInBeforePlannedSlot",
                label: _ctx.translate('latest-drive-in-before-planned-slot'),
                value: _ctx.model.latestDriveInBeforePlannedSlot,
                unit: _ctx.translate('global.temporal.minutes.label')
            }, null, 8, ["label", "value", "unit"])
        ]),
        _: 1
    }));
}
