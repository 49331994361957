import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5d70b970"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
const _hoisted_2 = { class: "selection-row" };
const _hoisted_3 = ["onClick"];
const _hoisted_4 = { class: "text" };
const _hoisted_5 = { class: "name" };
const _hoisted_6 = {
    key: 0,
    class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.disabled)
    }, [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dictionary, (it, index) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: it.id + index,
                    class: _normalizeClass(["selection-tile", { selected: _ctx.selected[it.id] }]),
                    onClick: ($event) => (_ctx.toggle(it.id))
                }, [
                    _createVNode(_component_Icon, {
                        class: "icon",
                        name: "circle"
                    }),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.dictionaryTranslate(_ctx.dictionaryId, it)), 1),
                        (it.additionalValue)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.dictionaryTranslate(_ctx.dictionaryId, it, "additionalValue")), 1))
                            : _createCommentVNode("", true)
                    ])
                ], 10, _hoisted_3));
            }), 128))
        ])
    ], 2));
}
