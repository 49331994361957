import _ from "lodash";
import { onMounted, reactive } from "vue";
import { resolve, tryResolve } from "@/di/composables/resolve";
import { TranslationService } from "@/app/translation/services/translation.service";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const useTranslate = (prefix) => {
    const translationService = resolve(TranslationService);
    const translatePrefix = tryResolve(TranslationPrefixToken);
    const data = reactive({
        componentName: "",
    });
    onMounted(() => {
        const instance = useInstance();
        data.componentName = _.get(instance.type, "originalName") ?? instance.type.name ?? "";
    });
    return (key, params) => {
        const localPrefixedKeys = [
            _.compact([prefix, key]).join("."),
            _.compact([prefix, "components", data.componentName, key]).join("."),
            _.compact([prefix, data.componentName, key]).join("."),
        ];
        const injectedPrefixedKeys = [
            _.compact([translatePrefix, "components", data.componentName, key]).join("."),
            _.compact([translatePrefix, data.componentName, key]).join("."),
            _.compact([translatePrefix, key]).join("."),
        ];
        const combinedKeys = [
            ...(prefix ? localPrefixedKeys : []),
            ...(translatePrefix ? injectedPrefixedKeys : []),
            _.compact(["components", data.componentName, key]).join("."),
            _.compact([data.componentName, key]).join("."),
            key,
            ["global", key].join("."),
        ];
        for (const combinedKey of combinedKeys) {
            const translation = translationService.translate(combinedKey, params);
            if (translation !== combinedKey) {
                return translation;
            }
        }
        return key;
    };
};
