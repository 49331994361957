import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const ProductsSafetyModuleId = "productsSafety";
export const ProductsSafetyModule = (entityId, options = {}) => {
    return createModule({
        imports: [
            ModuleModule({
                id: ProductsSafetyModuleId,
                domainId: entityId,
                readOnly: options.readOnly,
                originDomain: options.originDomain,
            }),
        ],
    });
};
