import { createModule } from "@/di/module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { ChangeHistoryService } from "@/app/modular-content/services/change-history.service";
import { CopyModuleService } from "@/app/modular-content/services/copy-module.service";
import { CopyModuleUrlFactoryService } from "@/app/modular-content/services/copy-module-url.factory.service";
import { GenericChangeDetectionService } from "@/app/modular-content/services/generic-change-detection.service";
import { InactiveUserInterceptorService } from "@/app/users/services/inactive-user-interceptor.service";
import { LeaveEditGuardService } from "@/app/modular-content/services/leave-edit-guard.service";
import { ModuleConfigurationService } from "@/app/modular-content/services/module-configuration.service";
import { ModuleService } from "@/app/modular-content/services/module.service";
import { ModuleUrlFactoryService } from "@/app/modular-content/services/module-url-factory.service";
import { SubModuleConfigurationUrlFactoryService } from "@/app/modular-content/services/sub-module-configuration-url-factory.service";
import { UpdateModuleService } from "@/app/modular-content/services/update-module.service";
import ConfigureSubModulesModalComponent from "@/app/modular-content/components/configure-sub-modules-modal.component.vue";
import ContentModuleOverviewComponent from "@/app/modular-content/components/module-overview.component.vue";
import EntityDetailsComponent from "@/app/modular-content/components/entity-details.component.vue";
import EntityEditComponent from "@/app/modular-content/components/entity-edit.component.vue";
import ModuleCopyModalComponent from "@/app/modular-content/components/module-copy-modal.component.vue";
import SubModulesEditComponent from "@/app/modular-content/components/sub-modules-edit.component.vue";
import { combinedIdFactory } from "@/app/shared/factories/combined-id.factory";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import { moduleTokensFactory } from "@/app/modular-content/factories/module-tokens.factory";
import { subModuleTokensFactory } from "@/app/modular-content/factories/sub-module-tokens.factory";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { AllSubModulesMetaDataToken, CopyModuleModalIdToken, DomainIdToken, ModuleCopyModalComponentToken, ModuleIdToken, ModuleMetadataToken, SubModuleConfigurationModalIdToken, SubModuleConfigurationServiceToken, SubModuleServicesToken, } from "@/app/modular-content/token/modular-content.token";
import { RouteMetaDomainId, RouteMetaModuleId } from "@/app/modular-content/modular-content.constants";
const ContentRoutesModule = (options) => {
    const routeDomainId = createRouteId(options.domainId);
    const routeModuleId = createRouteId(options.id);
    return RoutesModule({
        parent: `${routeDomainId}-single`,
        routes: [
            {
                path: "",
                name: `${routeDomainId}-details`,
                meta: {
                    [RouteMetaDomainId]: options.domainId,
                },
                component: suffixComponentName(EntityDetailsComponent, options.domainId),
                props: (route) => ({ query: route.query }),
                children: [
                    {
                        path: `${routeModuleId}`,
                        name: `${routeDomainId}-details/${routeModuleId}`,
                        meta: {
                            [RouteMetaDomainId]: options.domainId,
                            [RouteMetaModuleId]: options.id,
                        },
                        component: suffixComponentName(ContentModuleOverviewComponent, options.domainId, options.id),
                    },
                ],
                redirect: {
                    name: `${routeDomainId}-details/${routeModuleId}`,
                },
            },
            {
                path: "edit/",
                name: `${routeDomainId}-edit`,
                meta: {
                    [RouteMetaDomainId]: options.domainId,
                },
                component: suffixComponentName(EntityEditComponent, options.domainId),
                children: [
                    {
                        path: `${routeModuleId}`,
                        name: `${routeDomainId}-edit/${routeModuleId}`,
                        meta: {
                            [RouteMetaDomainId]: options.domainId,
                            [RouteMetaModuleId]: options.id,
                        },
                        component: suffixComponentName(SubModulesEditComponent, options.domainId, options.id),
                    },
                ],
            },
        ],
    });
};
const ModuleMetadataModule = (options) => {
    const specific = moduleTokensFactory(options.domainId, options.id);
    const generic = moduleTokensFactory(options.domainId);
    const meta = {
        id: options.id,
        domainId: options.domainId,
        readOnly: options.readOnly,
        originDomain: options.originDomain,
        navigationIcon: options.navigationIcon,
        copyAvailable: options.copyAvailable,
        copyTooltip: options.copyTooltip,
    };
    return createModule({
        providers: [
            { token: specific.meta, useValue: meta },
            { token: generic.meta, useToken: specific.meta },
            { token: ModuleMetadataToken, useToken: specific.meta },
        ],
    });
};
const ModuleServiceModule = (options) => {
    const specific = moduleTokensFactory(options.domainId, options.id);
    const generic = moduleTokensFactory(options.domainId);
    return createModule({
        providers: [
            { token: specific.service, useClass: ModuleService },
            { token: generic.service, useToken: specific.service },
            { token: ModuleService, useToken: specific.service },
            ModuleUrlFactoryService,
        ],
    });
};
const ModuleConfigurationModule = (options) => {
    const modalId = combinedIdFactory(options.domainId, options.id, "ModuleConfigurationModal");
    const specific = moduleTokensFactory(options.domainId, options.id);
    const generic = moduleTokensFactory(options.domainId);
    const modalComponent = suffixComponentName(ConfigureSubModulesModalComponent, options.domainId, options.id);
    return createModule({
        components: [modalComponent],
        imports: [ModalModule(modalId, modalComponent)],
        providers: [
            { token: specific.configurationService, useClass: ModuleConfigurationService },
            { token: generic.configurationService, useToken: specific.configurationService },
            { token: ModuleConfigurationService, useToken: specific.configurationService },
            { token: SubModuleConfigurationServiceToken, useToken: specific.configurationService },
            { token: SubModuleConfigurationModalIdToken, useValue: modalId },
            SubModuleConfigurationUrlFactoryService,
        ],
    });
};
const CopyModule = (options) => {
    if (!options.copyAvailable && !options.copyModuleModal) {
        return createModule({});
    }
    const modalId = combinedIdFactory(options.domainId, options.id, "CopyModuleModal");
    const specific = moduleTokensFactory(options.domainId, options.id);
    const generic = moduleTokensFactory(options.domainId);
    const modalComponent = suffixComponentName(ModuleCopyModalComponent, options.domainId, options.id);
    return createModule({
        components: [modalComponent],
        imports: [ModalModule(modalId, modalComponent)],
        providers: [
            { token: specific.copyModuleService, useClass: CopyModuleService },
            { token: generic.copyModuleService, useToken: specific.copyModuleService },
            { token: CopyModuleService, useToken: specific.copyModuleService },
            { token: specific.copyModuleComponent, useValue: options.copyModuleModal },
            { token: generic.copyModuleComponent, useToken: specific.copyModuleComponent },
            { token: ModuleCopyModalComponentToken, useToken: specific.copyModuleComponent },
            { token: CopyModuleModalIdToken, useValue: modalId },
            CopyModuleUrlFactoryService,
        ],
    });
};
export const ModuleModule = (options) => {
    return createModule({
        components: [
            suffixComponentName(ContentModuleOverviewComponent, options.domainId, options.id),
            suffixComponentName(SubModulesEditComponent, options.domainId, options.id),
        ],
        imports: [
            ContentRoutesModule(options),
            ModuleMetadataModule(options),
            ModuleServiceModule(options),
            ModuleConfigurationModule(options),
            CopyModule(options),
        ],
        providers: [
            { token: DomainIdToken, useValue: options.domainId },
            { token: ModuleIdToken, useValue: options.id },
            {
                token: SubModuleServicesToken,
                useFactory: (resolve, resolveAll) => {
                    const tokens = subModuleTokensFactory(options.domainId, options.id);
                    return resolveAll(tokens.service);
                },
            },
            {
                token: AllSubModulesMetaDataToken,
                useFactory: (resolve, resolveAll) => {
                    const tokens = subModuleTokensFactory(options.domainId, options.id);
                    return resolveAll(tokens.meta);
                },
            },
            GenericChangeDetectionService,
            LeaveEditGuardService,
            UpdateModuleService,
            InactiveUserInterceptorService,
            ChangeHistoryService,
        ],
    });
};
