import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09f268a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-contact-data" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.telephone.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                name: "telephone",
                                placeholder: _ctx.translate('form-fields.telephone.placeholder')
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "telephone" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("email")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                name: "email",
                                placeholder: _ctx.translate('email'),
                                email: ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "email" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "initial", "errors"]))
    ]));
}
