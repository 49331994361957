import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2763adba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "verifier-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_SearchDropdown = _resolveComponent("SearchDropdown");
    const _component_DropdownOptionsReload = _resolveComponent("DropdownOptionsReload");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AutoCompleteDropdown = _resolveComponent("AutoCompleteDropdown");
    const _component_VerificationStatus = _resolveComponent("VerificationStatus");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        initial: _ctx.initial,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('header')
    }, {
        default: _withCtx(() => [
            (_ctx.initialLoad.isLoading.value)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                    errors: _ctx.errors,
                    onIsValid: _cache[2] || (_cache[2] = ($event) => (_ctx.isValid = $event)),
                    initial: _ctx.initial,
                    class: "create-requirement-profile-modal"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("company-from")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    disabled: "",
                                    value: `${_ctx.currentCompany.name} / ${_ctx.currentCompany.referenceNumber}`
                                }, null, 8, ["value"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "company-product" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("company-product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    class: "product-dropdown outbound",
                                    disabled: _ctx.isDataLoading,
                                    name: "outboundProductId",
                                    options: _ctx.outboundProducts,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    additionalLabel: "referenceNumber",
                                    filterFields: ['referenceNumber'],
                                    placeholder: _ctx.translate('company-product.placeholder'),
                                    id: "company-product-dropdown",
                                    required: ""
                                }, null, 8, ["disabled", "options", "placeholder"]),
                                _createVNode(_component_FormError, { name: "outboundProductId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: !!_ctx.currentBPC,
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("company-to.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]),
                                (!!_ctx.currentBPC)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        value: `${_ctx.currentBPC.name} / ${_ctx.currentBPC.referenceNumber}`
                                    }, null, 8, ["value"]))
                                    : (_openBlock(), _createBlock(_component_SearchDropdown, {
                                        key: 1,
                                        name: "companyToId",
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('company-to.placeholder'),
                                        id: "company-to-dropdown",
                                        disabled: _ctx.isDataLoading,
                                        searchService: _ctx.companiesSearchService,
                                        searchSelect: ['id', 'name', 'referenceNumber'],
                                        searchFields: ['name', 'referenceNumber'],
                                        searchMode: "all",
                                        searchInclude: _ctx.companySearchInclude,
                                        searchFilterServices: _ctx.searchFilterService,
                                        excludeOptionValues: [_ctx.currentCompany.id],
                                        required: ""
                                    }, null, 8, ["placeholder", "disabled", "searchService", "searchInclude", "searchFilterServices", "excludeOptionValues"])),
                                _createVNode(_component_FormError, { name: "companyToId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("site-to.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.destinationSitesLoad.isError.value)
                                    ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                        key: 0,
                                        onClick: _ctx.destinationSitesLoad.loadMethod
                                    }, null, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 1,
                                        disabled: !_ctx.selectedCompanyTo || _ctx.isDataLoading || _ctx.isUnloadingPointBpcDomain,
                                        loading: _ctx.destinationSitesLoad.isLoading.value,
                                        name: "siteToId",
                                        options: _ctx.destinationSites,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('site-to.placeholder'),
                                        id: "site-to-dropdown",
                                        required: ""
                                    }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                _createVNode(_component_FormError, { name: "siteToId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("unloading-point.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.destinationUnloadingPointsLoad.isError.value)
                                    ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                        key: 0,
                                        onClick: _ctx.destinationUnloadingPointsLoad.loadMethod
                                    }, null, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 1,
                                        disabled: !_ctx.selectedSiteTo || _ctx.isDataLoading || _ctx.isUnloadingPointBpcDomain,
                                        loading: _ctx.destinationUnloadingPointsLoad.isLoading.value,
                                        name: "unloadingPointId",
                                        options: _ctx.destinationUnloadingPoints,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('unloading-point.placeholder'),
                                        id: "unloading-point-dropdown",
                                        required: ""
                                    }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                _createVNode(_component_FormError, { name: "unloadingPointId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("inbound-product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    class: "product-dropdown inbound",
                                    disabled: "",
                                    loading: _ctx.inboundProductsLoad.isLoading.value,
                                    name: "inboundProductId",
                                    additionalLabel: "referenceNumber",
                                    filterFields: ['referenceNumber'],
                                    placeholder: _ctx.BPCInboundProductName || _ctx.translate('inbound-product.placeholder'),
                                    id: "unloading-point-dropdown"
                                }, null, 8, ["loading", "placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requires-customer-verification")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.isRequiredVerification,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.isRequiredVerification) = $event)),
                                    truthyLabel: "phrases.required",
                                    falsyLabel: "phrases.not-required",
                                    disabled: !_ctx.selectedCompanyTo || _ctx.isDataLoading,
                                    isOptionDeselectable: false
                                }, null, 8, ["modelValue", "disabled"])
                            ]),
                            _: 1
                        }),
                        (_ctx.requiresVerification)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, { required: "" }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("verifier-email.label")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _withDirectives(_createVNode(_component_AutoCompleteDropdown, {
                                            required: "",
                                            name: "verifierEmail",
                                            suggestions: _ctx.verifiersSuggestions,
                                            placeholder: _ctx.translate('verifier-email.placeholder'),
                                            email: ""
                                        }, null, 8, ["suggestions", "placeholder"]), [
                                            [_directive_default_validation]
                                        ]),
                                        _createVNode(_component_FormError, { name: "verifierEmail" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_VerificationStatus, {
                                    requirementProfile: _ctx.model
                                }, null, 8, ["requirementProfile"])
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("internal-comments.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_InputText, {
                                    name: "additionalInformation",
                                    placeholder: _ctx.translate('internal-comments.placeholder')
                                }, null, 8, ["placeholder"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "additionalInformation" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["modelValue", "errors", "initial"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.modal.close())),
                        id: "cancel-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isCreating,
                        onClick: _ctx.createMethod,
                        disabled: !_ctx.isValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.save")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["initial", "modal-id", "header"]));
}
