import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplayUlpBasicInformationComponent from "@/app/sub-modules/basic-information/un-loading-point/components/display-ulp-basic-information.component.vue";
import EditUlpBasicInformationComponent from "@/app/sub-modules/basic-information/un-loading-point/components/edit-ulp-basic-information.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { BasicInformationSubModuleId } from "@/app/sub-modules/basic-information/basic-information.types";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const UlpBasicInformationSubModule = (domainId, options = {}) => {
    const id = options.id ?? BasicInformationSubModuleId;
    const moduleId = options.moduleId ?? GeneralInformationModuleId;
    const suffix = (component) => suffixComponentName(component, domainId, moduleId, id);
    const editComponent = suffix(EditUlpBasicInformationComponent);
    const displayComponent = suffix(DisplayUlpBasicInformationComponent);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "info",
                editTitle: "sub-modules.basic-information.un-loading-point.name",
                hideDisplayTitle: true,
                configurationTranslationPrefix: "sub-modules.basic-information.un-loading-point",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.basic-information.un-loading-point" }],
    });
};
