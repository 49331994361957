import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7627c8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = {
    class: "label",
    "data-qa-id": "label"
};
const _hoisted_4 = { class: "col-6" };
const _hoisted_5 = {
    key: 0,
    class: "value",
    "data-qa-id": "value"
};
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: "row",
        "data-qa-id": _ctx.name
    }, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
            (Number.isFinite(_ctx.value))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
                    (_ctx.unit)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.unit), 1))
                        : _createCommentVNode("", true)
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
        ])
    ], 8, _hoisted_1));
}
