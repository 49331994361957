import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-35819099"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "input-section"
};
const _hoisted_2 = {
    key: 1,
    class: "input-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        class: "edit-site-additional-information"
    }, {
        default: _withCtx(() => [
            (_ctx.model)
                ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("decoupled-loading")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                modelValue: _ctx.model.decoupledLoading,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.decoupledLoading) = $event)),
                                truthyLabel: "phrases.possible",
                                falsyLabel: "phrases.not-possible"
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]))
                : _createCommentVNode("", true),
            (_ctx.model)
                ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("external-cargo")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                modelValue: _ctx.model.externalCargo,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.externalCargo) = $event)),
                                truthyLabel: "phrases.allowed",
                                falsyLabel: "phrases.not-allowed"
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["modelValue", "initial"]));
}
