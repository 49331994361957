import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CompanyBasicInformationWidget = _resolveComponent("CompanyBasicInformationWidget");
    const _component_SiteInformationDisplay = _resolveComponent("SiteInformationDisplay");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model && _ctx.isThereMainInformationData)
            ? (_openBlock(), _createBlock(_component_CompanyBasicInformationWidget, {
                key: 0,
                class: "mt-3",
                "basic-information": _ctx.model.mainInformation
            }, null, 8, ["basic-information"]))
            : _createCommentVNode("", true),
        (_ctx.isThereMainInformationData)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
            : _createCommentVNode("", true),
        (_ctx.model)
            ? (_openBlock(), _createBlock(_component_SiteInformationDisplay, {
                key: 2,
                model: _ctx.model
            }, null, 8, ["model"]))
            : _createCommentVNode("", true)
    ]));
}
