import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a050659a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = { class: "title" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            onContext: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('context', $event))),
            class: "edit-dg-classification-view"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.translate("un-number")), 1),
                        _createVNode(_component_InputText, {
                            name: "unNumber",
                            "exact-length": 4,
                            type: "number",
                            placeholder: _ctx.translate('un-number'),
                            onInput: _ctx.parseEmptyToNull
                        }, null, 8, ["placeholder", "onInput"]),
                        _createVNode(_component_FormError, { name: "unNumber" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("proper-shipping-name")), 1),
                        _withDirectives(_createVNode(_component_InputText, {
                            name: "properShippingName",
                            placeholder: _ctx.translate('proper-shipping-name')
                        }, null, 8, ["placeholder"]), [
                            [_directive_default_validation]
                        ]),
                        _createVNode(_component_FormError, { name: "properShippingName" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.translate("hazard-inducer")), 1),
                        _withDirectives(_createVNode(_component_InputText, {
                            name: "hazardInducer",
                            placeholder: _ctx.translate('hazard-inducer')
                        }, null, 8, ["placeholder"]), [
                            [_directive_default_validation]
                        ]),
                        _createVNode(_component_FormError, { name: "hazardInducer" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.translate("class")), 1),
                        _createVNode(_component_Dropdown, {
                            name: "classOrDivision",
                            options: _ctx.dictionaries.classOrDivision,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "ClassOrDivision",
                            placeholder: _ctx.translate('class')
                        }, null, 8, ["options", "placeholder"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("subsidiary-hazard")), 1),
                        _createVNode(_component_AppMultiSelect, {
                            name: "subsidiaryHazards",
                            options: _ctx.dictionaries.subsidiaryHazard,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "SubsidiaryHazard",
                            display: "chip",
                            placeholder: _ctx.translate('subsidiary-hazard')
                        }, null, 8, ["options", "placeholder"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.translate("packing-group")), 1),
                        _createVNode(_component_Dropdown, {
                            name: "packingGroup",
                            options: _ctx.dictionaries.packingGroup,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "PackingGroup",
                            placeholder: _ctx.translate('packing-group')
                        }, null, 8, ["options", "placeholder"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("environmental-hazards")), 1),
                        _createVNode(_component_RadioButton, {
                            modelValue: _ctx.model.environmentalHazards,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.environmentalHazards) = $event))
                        }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
