export const RequirementProfileDomainId = "RequirementProfiles";
export const StatusChangeConfirmationModalId = "StatusChangeConfirmationModal";
export const RequirementProfileBookmarksListId = "bookmarks";
export const CompanyListId = "company";
export const RequirementProfilesUrl = "requirementprofiles";
export const RequirementProfileBookmarksUrl = "requirementprofiles/bookmarks";
export const EditRequirementProfileModalId = "EditEntityRequirementProfiles";
export const RequirementProfilesCompanyRouteName = "requirement-profiles/company";
export const RequirementProfilesListRouteName = "requirement-profiles-list";
export const verificationStatusStyles = {
    NotRequired: "gray",
    NotStarted: "gray",
    Requested: "yellow",
    Info: "red",
    Confirmed: "green",
    ManuallyDone: "green",
    InProgress: "blue",
    Verified: "red",
    InReview: "red",
};
export const toastMessages = {
    Requested: "domains.requirement-profiles.requirement-profile-form.request-verification.toast-message",
    ConcludedWithoutVerification: "domains.requirement-profiles.requirement-profile-form.conclude-without-feedback.toast-message",
    NotStarted: "domains.requirement-profiles.requirement-profile-form.reset-workflow.toast-message",
};
export const statusMessages = {
    NotStarted: {
        header: "domains.requirement-profiles.requirement-profile-form.reset-workflow.confirmation-header",
        message: "domains.requirement-profiles.requirement-profile-form.reset-workflow.confirmation-message",
        button: "domains.requirement-profiles.requirement-profile-form.reset-workflow.confirmation-header",
    },
    ConcludedWithoutVerification: {
        header: "domains.requirement-profiles.requirement-profile-form.conclude-without-feedback.confirmation-header",
        message: "domains.requirement-profiles.requirement-profile-form.conclude-without-feedback.confirmation-message",
        button: "domains.requirement-profiles.requirement-profile-form.conclude-without-feedback.label",
    },
};
