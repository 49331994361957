import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71fbf55f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "breadcrumbs" };
const _hoisted_2 = {
    key: 0,
    class: "d-flex align-items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_RouterLink = _resolveComponent("RouterLink");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isVisible && !_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (crumb, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (index > 0)
                            ? (_openBlock(), _createBlock(_component_Icon, {
                                key: 0,
                                name: "navigate_next"
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_RouterLink, {
                            class: "link",
                            to: { path: crumb.path }
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getLabel(crumb.label)), 1)
                            ]),
                            _: 2
                        }, 1032, ["to"])
                    ], 64));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ]));
}
