import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayOpeningHoursView = _resolveComponent("DisplayOpeningHoursView");
    return (_ctx.model)
        ? (_openBlock(), _createBlock(_component_DisplayOpeningHoursView, {
            key: 0,
            model: _ctx.model
        }, null, 8, ["model"]))
        : _createCommentVNode("", true);
}
