import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import DisplayTemperatureRequirementsPacked from "@/app/sub-modules/temperature-requirements-packed/components/display-temperature-requirements-packed.component.vue";
import DisplayTemperatureRequirementsPackedView from "@/app/sub-modules/temperature-requirements-packed/components/display-temperature-requirements-packed-view.component.vue";
import EditTemperatureRequirementsPacked from "@/app/sub-modules/temperature-requirements-packed/components/edit-temperature-requirements-packed.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TemperatureRequirementsPackedSubModuleId } from "@/app/sub-modules/temperature-requirements-packed/temperature-requirements-packed.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const TemperatureRequirementsPackedSubModule = (domainId, options = {}) => {
    const id = options.id ?? TemperatureRequirementsPackedSubModuleId;
    const moduleId = options.moduleId ?? TechnicalRequirementsModuleId;
    const suffix = (component) => suffixComponentName(component, domainId, moduleId, id);
    const editComponent = suffix(EditTemperatureRequirementsPacked);
    const displayComponent = suffix(DisplayTemperatureRequirementsPacked);
    const displayViewComponent = suffix(DisplayTemperatureRequirementsPackedView);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "thermostat",
                showChangeHistory: options.showChangeHistory ?? true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.temperature-requirements-packed" }],
    });
};
