import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-75c2363e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            class: "edit-packaging"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.translate("packaging-type")), 1),
                        _createVNode(_component_AppMultiSelect, {
                            name: "packagingType",
                            options: _ctx.packagingTypeDictionary,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "PackagingType",
                            display: "chip",
                            placeholder: _ctx.translate('packaging-type-placeholder')
                        }, null, 8, ["options", "placeholder"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial"]));
}
