import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36f2f918"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = { class: "sub-modules" };
const _hoisted_3 = { class: "title-container" };
const _hoisted_4 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        "show-header": false,
        class: "configure-sub-modules-modal",
        "data-qa-id": "configure-sub-modules-modal"
    }, {
        default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.translate(`${_ctx.domainTranslationPrefix("header", true)}`)), 1),
            _createElementVNode("p", {
                innerHTML: _ctx.translate('description', { moduleName: _ctx.moduleName })
            }, null, 8, _hoisted_1),
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    class: "initial-loader",
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    initial: _ctx.initial,
                    modelValue: _ctx.formData,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.formData) = $event))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subModuleMetadataById, (metadata, id) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: id }, [
                                    (id in _ctx.formData)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(["sub-module", { enabled: _ctx.formData[id] || !_ctx.isOptional(id) }])
                                        }, [
                                            _createVNode(_component_Icon, {
                                                name: metadata.icon,
                                                variant: metadata.iconVariant
                                            }, null, 8, ["name", "variant"]),
                                            _createElementVNode("div", _hoisted_3, [
                                                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subModuleName(metadata)), 1)
                                            ]),
                                            _createVNode(_component_AppCheckbox, {
                                                disabled: !_ctx.isOptional(id),
                                                name: id
                                            }, null, 8, ["disabled", "name"])
                                        ], 2))
                                        : _createCommentVNode("", true)
                                ], 64));
                            }), 128))
                        ])
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isSaving,
                        onClick: _ctx.save,
                        "data-qa-id": "save-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.save")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
