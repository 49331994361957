import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b596cfc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-general-cleaning-info" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = { class: "col-6 column-description" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "column-header col-6" };
const _hoisted_9 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_10 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "column-header col-6" };
const _hoisted_13 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_14 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "column-header col-6" };
const _hoisted_17 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_18 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("general-cleaning-requirement")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getValue(_ctx.model?.generalCleaningRequirement, "GeneralCleaningRequirement")), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("special-cleaning-required")), 1),
                    (_ctx.model?.specialCleaningRequired?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.specialCleaningRequired, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CleaningProcedures")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                ]),
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("alternative-cleaning-procedures")), 1),
                    (_ctx.model?.alternativeCleaningProcedures?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.alternativeCleaningProcedures, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CleaningProcedures")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("forbidden-cleaning-procedures")), 1),
                    (_ctx.model?.forbiddenCleaningProcedures?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.forbiddenCleaningProcedures, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CleaningProcedures")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cleaningFrequency
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("cleaning-frequency.label")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_DisplayAttachments, {
                    attachments: _ctx.model?.generalCleaningAdditionalRequirements,
                    title: _ctx.translate('additional-requirements')
                }, null, 8, ["attachments", "title"])
            ]))
    ]));
}
