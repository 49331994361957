import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aaf75b3e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
const _hoisted_2 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Notification = _resolveComponent("Notification");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            class: "edit-prl-restrictions-settings"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.translate("allow-as-pre-product-label")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.allowAsPreProduct,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.allowAsPreProduct) = $event))
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("master-product-label")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.isMasterProduct,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.isMasterProduct) = $event))
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                (_ctx.masterForPrlRestrictionsId)
                    ? (_openBlock(), _createBlock(_component_FormField, {
                        key: 0,
                        class: "col-xl-7"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Notification, null, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "error" })
                                ]),
                                header: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("assigned-master-product-warning-title")), 1)
                                ]),
                                description: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("assigned-master-product-warning-notification")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                (!_ctx.isMasterProduct)
                    ? (_openBlock(), _createBlock(_component_FormField, {
                        key: 1,
                        class: "col-xl-7"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("assign-master-product-label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_Dropdown, {
                                name: "masterForPrlRestrictionsId",
                                options: _ctx.masterBulkProducts,
                                optionValue: "id",
                                optionLabel: "name",
                                additionalLabel: "referenceNumber",
                                filterFields: ['referenceNumber'],
                                placeholder: _ctx.translate('assign-master-product-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: _ctx.isMasterProduct }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("assign-prl-policy-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["required"]),
                        (_ctx.masterForPrlRestrictionsId)
                            ? (_openBlock(), _createBlock(_component_Dropdown, {
                                key: 0,
                                modelValue: _ctx.masterPrlRestrictionsPolicy,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.masterPrlRestrictionsPolicy) = $event)),
                                disabled: "",
                                options: _ctx.prlRestrictionsPolicyOptions,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "PrlRestrictionsPolicy",
                                placeholder: _ctx.translate('assign-prl-policy-placeholder')
                            }, null, 8, ["modelValue", "options", "placeholder"]))
                            : (_openBlock(), _createBlock(_component_Dropdown, {
                                key: 1,
                                name: "prlRestrictionsPolicy",
                                options: _ctx.prlRestrictionsPolicyOptions,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "PrlRestrictionsPolicy",
                                placeholder: _ctx.translate('assign-prl-policy-placeholder'),
                                required: _ctx.isMasterProduct
                            }, null, 8, ["options", "placeholder", "required"])),
                        _createVNode(_component_FormError, { name: "prlRestrictionsPolicy" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
