import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-737dd292"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "loader"
};
const _hoisted_2 = {
    key: 2,
    class: "basic-container"
};
const _hoisted_3 = {
    key: 3,
    class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Title = _resolveComponent("Title");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Maintenance = _resolveComponent("Maintenance");
    const _component_TopNavigation = _resolveComponent("TopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_SideNavigation = _resolveComponent("SideNavigation");
    const _component_CookieBanner = _resolveComponent("CookieBanner");
    const _component_Breadcrumbs = _resolveComponent("Breadcrumbs");
    const _component_ErrorToast = _resolveComponent("ErrorToast");
    const _component_ModalContainer = _resolveComponent("ModalContainer");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_Title),
        (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_LoadingSpinner, { center: "" })
            ]))
            : (_ctx.isMaintenance)
                ? (_openBlock(), _createBlock(_component_Maintenance, { key: 1 }))
                : (!_ctx.hasPermission)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_TopNavigation),
                        _createElementVNode("main", null, [
                            _createVNode(_component_RouterView)
                        ])
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_SideNavigation),
                        _createVNode(_component_TopNavigation),
                        _createVNode(_component_CookieBanner),
                        _createElementVNode("main", null, [
                            _createVNode(_component_Breadcrumbs),
                            _createVNode(_component_RouterView)
                        ]),
                        _createVNode(_component_ErrorToast)
                    ])),
        _createVNode(_component_ModalContainer)
    ], 64));
}
