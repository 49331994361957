import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65f837ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 2,
    class: "summary"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_Panel = _resolveComponent("Panel");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_ctx.isForbiddenError)
            ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 1 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_PageHeader, null, {
                    title: _withCtx(() => [
                        _createVNode(_component_PageTitle, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("title", true), { product: _ctx.model?.productName, site: _ctx.model?.siteName })), 1)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                _createVNode(_component_Panel, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_PanelTopNavigation, { "menu-items": _ctx.menuItems }, null, 8, ["menu-items"]),
                        _createVNode(_component_RouterView)
                    ]),
                    _: 1
                })
            ]));
}
