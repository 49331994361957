import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_TemperatureRangesInput = _resolveComponent("TemperatureRangesInput");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedInput = _resolveComponent("LocalizedInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                (_ctx.model)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_FormField, { class: "mb-4" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_TemperatureRangesInput, {
                                    modelValue: _ctx.model.unloadingTemperature,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.unloadingTemperature) = $event)),
                                    temperatureUnit: _ctx.model.unloadingTemperatureUnit,
                                    "onUpdate:temperatureUnit": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.unloadingTemperatureUnit) = $event)),
                                    name: "unloadingTemperature",
                                    label: _ctx.translate('unloading-temperature'),
                                    initial: _ctx.initial,
                                    temperatureUnitDictionary: _ctx.temperatureUnitDictionary,
                                    errors: _ctx.errors
                                }, null, 8, ["modelValue", "temperatureUnit", "label", "initial", "temperatureUnitDictionary", "errors"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_GlobeIcon, { size: "sm" })
                                ]),
                                _createVNode(_component_LocalizedInput, {
                                    class: "col-xl-7",
                                    name: "additionalTemperatureRequirements",
                                    placeholder: _ctx.translate('additional-temperature-requirements')
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "additionalTemperatureRequirements" })
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
