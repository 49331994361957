import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-48a9b211"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "policy-tag"
};
const _hoisted_2 = {
    key: 0,
    class: "policy-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (!_ctx.isPolicyDictionaryLoading)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Icon, {
                class: "policy-icon",
                name: "new_releases",
                variant: "outlined"
            }),
            (_ctx.policyName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.policyName), 1))
                : _createCommentVNode("", true)
        ])), [
            [
                _directive_tooltip,
                _ctx.policyDescription,
                void 0,
                { bottom: true }
            ]
        ])
        : _createCommentVNode("", true);
}
