import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d753f3c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-site-navigation" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_AttachmentsInput, {
                            title: _ctx.translate('title'),
                            name: "siteNavigation",
                            "empty-state-msg": _ctx.translate('empty')
                        }, null, 8, ["title", "empty-state-msg"]),
                        _createVNode(_component_FormError, { name: "values" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"])
    ]));
}
