var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { ProcessingItemsService } from "@/app/shared/services/processing-items.service";
import { VerificationStatus } from "@/app/shared/enums/fact-sheet.enum";
let RequestVerificationService = class RequestVerificationService {
    httpService;
    toastService;
    processingItemsService;
    constructor(httpService, toastService, processingItemsService) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.processingItemsService = processingItemsService;
    }
    async requestVerification(requirementProfileIds) {
        await this.httpService.post("requirementprofiles/sendRequestVerification", {
            requirementProfileIds,
        });
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.requirement-profiles.request-verification.toast-message",
        });
        this.processingItemsService.add({ id: requirementProfileIds[0], statusVerification: VerificationStatus.Requested });
    }
    async sendFeedback(requirementProfileId) {
        await this.httpService.post(`factsheets/${requirementProfileId}/verify`, {});
        this.processingItemsService.add({ id: requirementProfileId, statusVerification: VerificationStatus.Confirmed });
    }
};
RequestVerificationService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof ProcessingItemsService !== "undefined" && ProcessingItemsService) === "function" ? _c : Object])
], RequestVerificationService);
export { RequestVerificationService };
