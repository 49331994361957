import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-03c001ad"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "content-wrapper"
};
const _hoisted_2 = { class: "content-column" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = {
    key: 0,
    class: "content-column"
};
const _hoisted_6 = { class: "pre-product-subtitle" };
const _hoisted_7 = {
    key: 1,
    class: "content-column align-items-center d-flex"
};
const _hoisted_8 = { class: "empty-preproduct" };
const _hoisted_9 = { class: "empty-text" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_SearchPreProductsDropdown = _resolveComponent("SearchPreProductsDropdown");
    const _component_SearchDropdown = _resolveComponent("SearchDropdown");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_Icon = _resolveComponent("Icon");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_EditPreProduct = _resolveComponent("EditPreProduct");
    const _component_DisplayPreProduct = _resolveComponent("DisplayPreProduct");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.currentModalId,
        header: _ctx.translate(_ctx.isEditMode ? 'edit-header' : 'header'),
        class: "preloading-restriction-modal"
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_ButtonGroup, { class: "buttons-group" }, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.currentModalService.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    (_ctx.isEditMode)
                        ? (_openBlock(), _createBlock(_component_AppButton, {
                            key: 0,
                            loading: _ctx.isUpdating,
                            onClick: _ctx.update,
                            disabled: !_ctx.isValid || _ctx.isEdit || _ctx.isCreate
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.update")), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick", "disabled"]))
                        : (_openBlock(), _createBlock(_component_AppButton, {
                            key: 1,
                            loading: _ctx.isCreating,
                            onClick: _ctx.create,
                            disabled: !_ctx.isValid || _ctx.isEdit || _ctx.isCreate
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick", "disabled"]))
                ]),
                _: 1
            })
        ]),
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: "",
                    class: "m-5"
                }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_AppForm, {
                            initial: _ctx.initial,
                            modelValue: _ctx.model,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                            errors: _ctx.errors,
                            onIsValid: _cache[3] || (_cache[3] = ($event) => (_ctx.isValid = $event))
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, {
                                            required: !_ctx.model.preProductId
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("prl-group")), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["required"]),
                                        _createVNode(_component_Dropdown, {
                                            name: "prlGroup",
                                            options: _ctx.prlGroupsOptions,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            placeholder: _ctx.translate('prl-group'),
                                            disabled: !!_ctx.model.preProductId,
                                            required: !_ctx.model.preProductId,
                                            showClear: ""
                                        }, null, 8, ["options", "placeholder", "disabled", "required"]),
                                        _createVNode(_component_FormError, { name: "prlGroup" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, {
                                            required: !_ctx.model.prlGroup
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("pre-product-id")), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["required"]),
                                        _createVNode(_component_SearchPreProductsDropdown, {
                                            name: "preProductId",
                                            options: _ctx.allPreProductOptions,
                                            optionValue: "id",
                                            optionLabel: "displayName",
                                            placeholder: _ctx.translate('pre-product-id-placeholder'),
                                            disabled: !!_ctx.model.prlGroup || _ctx.isEdit || _ctx.isCreate,
                                            required: !_ctx.model.prlGroup,
                                            loading: _ctx.isFetching,
                                            onSearchValue: _cache[0] || (_cache[0] = ($event) => (_ctx.loadPreProduct($event))),
                                            onSearchValueReset: _ctx.emptyPreProductOptions,
                                            onLoadMore: _cache[1] || (_cache[1] = ($event) => (_ctx.loadPreProduct($event, _ctx.skip, _ctx.top))),
                                            onCreate: _ctx.createNewPreProduct,
                                            optionGroupLabel: "label",
                                            optionGroupChildren: "items",
                                            disabledOptionIds: ['empty'],
                                            showClear: ""
                                        }, null, 8, ["options", "placeholder", "disabled", "required", "loading", "onSearchValueReset", "onCreate"]),
                                        _createVNode(_component_FormError, { name: "preProductId" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("customer")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_SearchDropdown, {
                                            name: "customerId",
                                            initial: _ctx.initialCompany,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            placeholder: _ctx.translate('customer'),
                                            searchService: _ctx.companiesSearchService,
                                            searchSelect: ['id', 'name', 'referenceNumber'],
                                            searchFields: ['name', 'referenceNumber'],
                                            searchMode: "all",
                                            searchInclude: "OnlyMineOrOnlyBusinessPartners",
                                            searchFilterServices: _ctx.searchFilterServices
                                        }, null, 8, ["initial", "placeholder", "searchService", "searchFilterServices"]),
                                        _createVNode(_component_FormError, { name: "customerId" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, { required: "" }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("prl-allowed")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_RadioButton, {
                                            name: "prlAllowed",
                                            required: "",
                                            isOptionDeselectable: false
                                        }),
                                        _createVNode(_component_FormError, { name: "prlAllowed" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, { disabled: _ctx.isPrlAllowed }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("load-count")), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["disabled"]),
                                        _createVNode(_component_Dropdown, {
                                            name: "loadCount",
                                            options: _ctx.loadCountOptions,
                                            optionValue: "value",
                                            optionLabel: "name",
                                            placeholder: _ctx.translate('load-count'),
                                            disabled: _ctx.isPrlAllowed,
                                            required: ""
                                        }, null, 8, ["options", "placeholder", "disabled"]),
                                        _createVNode(_component_FormError, { name: "loadCount" })
                                    ]),
                                    _: 1
                                }),
                                (_ctx.isPrlAllowed)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, { required: "" }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("cleaning-required")), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(_component_RadioButton, {
                                                    name: "cleaningRequired",
                                                    required: "",
                                                    isOptionDeselectable: false
                                                }),
                                                _createVNode(_component_FormError, { name: "cleaningRequired" })
                                            ]),
                                            _: 1
                                        }),
                                        (_ctx.isCleaningRequired)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                _createVNode(_component_FormField, null, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_InputLabel, null, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.translate("cleaning-procedures")), 1)
                                                            ]),
                                                            _: 1
                                                        }),
                                                        _createVNode(_component_AppMultiSelect, {
                                                            name: "cleaningProcedures",
                                                            options: _ctx.dictionaries.cleaningProcedures,
                                                            optionValue: "id",
                                                            optionLabel: "name",
                                                            dictionaryId: "CleaningProcedures",
                                                            placeholder: _ctx.translate('cleaning-procedures'),
                                                            display: "chip",
                                                            additionalValue: "",
                                                            filterFields: ['additionalValue']
                                                        }, null, 8, ["options", "placeholder"]),
                                                        _createVNode(_component_FormError, { name: "cleaningProcedures" })
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(_component_FormField, null, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_InputLabel, null, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.translate("required-tests")), 1)
                                                            ]),
                                                            _: 1
                                                        }),
                                                        _createVNode(_component_AppMultiSelect, {
                                                            name: "requiredTests",
                                                            options: _ctx.dictionaries.requiredTests,
                                                            optionValue: "id",
                                                            optionLabel: "name",
                                                            dictionaryId: "RequiredTests",
                                                            placeholder: _ctx.translate('required-tests'),
                                                            display: "chip",
                                                            additionalValue: "",
                                                            filterFields: ['additionalValue']
                                                        }, null, 8, ["options", "placeholder"]),
                                                        _createVNode(_component_FormError, { name: "requiredTests" })
                                                    ]),
                                                    _: 1
                                                })
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }, 8, ["initial", "modelValue", "errors"])
                    ]),
                    (_ctx.model.preProductId)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_PageHeader, { "with-subtitle": "" }, {
                                title: _withCtx(() => [
                                    (_ctx.isEdit || _ctx.isCreate)
                                        ? (_openBlock(), _createBlock(_component_PageTitle, {
                                            key: 0,
                                            class: "m-0"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.isEdit ? "edit-pre-product-title" : "create-new-pre-product-title")), 1)
                                            ]),
                                            _: 1
                                        }))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createVNode(_component_PageTitle, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.selectedPreProduct?.name), 1)
                                                ]),
                                                _: 1
                                            }),
                                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate("sds-product-name")), 1)
                                        ], 64))
                                ]),
                                buttons: _withCtx(() => [
                                    (_ctx.isEdit || _ctx.isCreate)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createVNode(_component_AppButton, {
                                                onClick: _ctx.cancelPreProductChanges,
                                                class: "p-button p-button-tertiary"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick"]),
                                            _createVNode(_component_AppButton, {
                                                onClick: _ctx.savePreProductChanges,
                                                disabled: !_ctx.isPreProductFormValid,
                                                class: "p-button p-button-secondary"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate(_ctx.isCreate ? "create-new-pre-product-button" : "buttons.save-changes")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick", "disabled"])
                                        ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createVNode(_component_AppButton, {
                                                onClick: _ctx.editPreProduct,
                                                class: "p-button p-button-secondary"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_Icon, { name: "edit" }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.edit")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick"]),
                                            _createVNode(_component_AppButton, {
                                                onClick: _ctx.openSDSLink,
                                                disabled: !_ctx.selectedPreProduct.sdsLink
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("go-to-sds-button")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["onClick", "disabled"])
                                        ], 64))
                                ]),
                                _: 1
                            }),
                            (_ctx.isEdit || _ctx.isCreate)
                                ? (_openBlock(), _createBlock(_component_EditPreProduct, {
                                    key: 0,
                                    modelValue: _ctx.selectedPreProduct,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.selectedPreProduct) = $event)),
                                    onIsValid: _cache[5] || (_cache[5] = ($event) => (_ctx.isPreProductFormValid = $event))
                                }, null, 8, ["modelValue"]))
                                : (_openBlock(), _createBlock(_component_DisplayPreProduct, {
                                    key: 1,
                                    model: _ctx.selectedPreProduct
                                }, null, 8, ["model"]))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_Icon, {
                                    class: "eye-icon",
                                    variant: "outlined",
                                    name: "visibility"
                                }),
                                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.translate("select-pre-product-info")), 1)
                            ])
                        ]))
                ]))
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
