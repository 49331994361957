import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1864127c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-site-information-view" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = {
    key: 0,
    class: "row"
};
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubModuleTitle = _resolveComponent("SubModuleTitle");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModuleTitle, {
            icon: "info",
            headerText: _ctx.translate('name'),
            subModuleDescription: _ctx.translate('description')
        }, null, 8, ["headerText", "subModuleDescription"]),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate(`form-fields.name.site-label`)), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.model.name ?? "-"), 1)
        ]),
        (_ctx.companyAccess && !_ctx.isFactSheetsDomain)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("form-fields.reference-number.label")), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.model.referenceNumber ?? "-"), 1)
            ]))
            : _createCommentVNode("", true)
    ]));
}
