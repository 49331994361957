import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayUlpBasicInformationView = _resolveComponent("DisplayUlpBasicInformationView");
    return (_ctx.model)
        ? (_openBlock(), _createBlock(_component_DisplayUlpBasicInformationView, {
            key: 0,
            model: _ctx.model,
            hideCompanyInformation: _ctx.isFactSheetsDomain
        }, null, 8, ["model", "hideCompanyInformation"]))
        : _createCommentVNode("", true);
}
