import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40af33f3"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "parking-spaces-input" };
const _hoisted_2 = { class: "header col-2" };
const _hoisted_3 = { class: "header col-2" };
const _hoisted_4 = { class: "header col-2" };
const _hoisted_5 = { class: "header col-2" };
const _hoisted_6 = { class: "header col-3" };
const _hoisted_7 = { class: "column col-2" };
const _hoisted_8 = { class: "name bold" };
const _hoisted_9 = { class: "column col-2" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = { class: "column col-2" };
const _hoisted_13 = { class: "column col-2" };
const _hoisted_14 = { class: "column col-3" };
const _hoisted_15 = {
    key: 0,
    class: "tags"
};
const _hoisted_16 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditableTemporaryListInput = _resolveComponent("EditableTemporaryListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EditableTemporaryListInput, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                _cache[1] || (_cache[1] = ($event) => (_ctx.model = $event))
            ],
            addBtn: _ctx.translate('add-parking-slot'),
            emptyMsg: _ctx.translate('empty'),
            name: _ctx.translate('name'),
            id: _ctx.id
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("parking-name")), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("geo-information")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("purpose-of-use")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("access-restrictions")), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("safety-measures-and-facilities")), 1)
            ]),
            row: _withCtx((it) => [
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(it?.name ?? "-"), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    (it?.location.latitude && it.location.longitude)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(it?.location?.latitude) + ", " + _toDisplayString(it?.location?.longitude), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.purposeOfUseInfo(it)), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.hasAccessRestriction(it) ? _ctx.translate("phrases.yes") : _ctx.translate("phrases.no")), 1),
                _createElementVNode("div", _hoisted_14, [
                    (_ctx.safetyMeasuresAndFacilities(it)?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(it?.safetyMeasures, (id) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: id,
                                    class: "tag"
                                }, _toDisplayString(_ctx.translationValue(id, _ctx.dictionaries.safetyMeasure, "SafetyMeasure")), 1));
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(it?.sanitaryFacilities, (id) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: id,
                                    class: "tag"
                                }, _toDisplayString(_ctx.translationValue(id, _ctx.dictionaries.sanitaryFacility, "SanitaryFacility")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "addBtn", "emptyMsg", "name", "id"])
    ]));
}
