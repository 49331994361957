import { InstallationsModuleId } from "@/app/modules/installations/installations.module";
import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import DisplayUnLoadingAreaComponent from "@/app/sub-modules/un-loading-area/components/display-un-loading-area.component.vue";
import EditUnLoadingAreaComponent from "@/app/sub-modules/un-loading-area/components/edit-un-loading-area.component.vue";
import { paramCase } from "change-case";
import { FactSheetsDomainId } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
export const UnLoadingAreaSubModule = (domainId, options = {}) => {
    const id = "unloadingLoadingArea";
    const moduleId = options.moduleId ?? InstallationsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const domainTranslationKey = domainId !== FactSheetsDomainId ? paramCase(domainId) : paramCase(options.id);
    const displayComponent = suffixComponentName(DisplayUnLoadingAreaComponent, ...componentSuffixes);
    const editComponent = suffixComponentName(EditUnLoadingAreaComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                displayTitle: `sub-modules.unloading-loading-area.${domainTranslationKey}.name`,
                editTitle: `sub-modules.unloading-loading-area.${domainTranslationKey}.name`,
                configurationTranslationPrefix: `sub-modules.unloading-loading-area.${domainTranslationKey}`,
                icon: "local_shipping",
                components: {
                    display: displayComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.unloading-loading-area" }],
    });
};
