import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e4a2ee8c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = {
    key: 2,
    class: "d-flex align-items-center gap-3"
};
const _hoisted_3 = { class: "modules-edit" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TitleButton = _resolveComponent("TitleButton");
    const _component_FactSheetPageHeader = _resolveComponent("FactSheetPageHeader");
    const _component_ConnectButton = _resolveComponent("ConnectButton");
    const _component_ConnectStatus = _resolveComponent("ConnectStatus");
    const _component_DataLocalization = _resolveComponent("DataLocalization");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_SaveEntityButton = _resolveComponent("SaveEntityButton");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    return (_openBlock(), _createElementBlock("div", {
        class: "entity-edit",
        "data-qa-id": _ctx.$options.name
    }, [
        _createVNode(_component_PageHeader, null, {
            title: _withCtx(() => [
                (_ctx.temporaryMode)
                    ? (_openBlock(), _createBlock(_component_TitleButton, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.handleCancel()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.temporaryModeTitle), 1)
                        ]),
                        _: 1
                    }))
                    : (_ctx.isFactSheetsDomain)
                        ? (_openBlock(), _createBlock(_component_FactSheetPageHeader, {
                            key: 1,
                            entity: _ctx.entity
                        }, null, 8, ["entity"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_TitleButton, {
                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.navigateToDetails()))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate(`entity-edit.buttons.${_ctx.domainTranslationPrefix("title", true)}`)), 1)
                                ]),
                                _: 1
                            }),
                            (_ctx.isConnectingEnabled)
                                ? (_openBlock(), _createBlock(_component_ConnectButton, {
                                    key: 0,
                                    onDisconnected: _cache[2] || (_cache[2] = ($event) => (_ctx.loadCurrentDomain()))
                                }))
                                : _createCommentVNode("", true),
                            (_ctx.isConnectingEnabled)
                                ? (_openBlock(), _createBlock(_component_ConnectStatus, {
                                    key: 1,
                                    connectedEntity: _ctx.connectedEntity
                                }, null, 8, ["connectedEntity"]))
                                : _createCommentVNode("", true)
                        ]))
            ]),
            buttons: _withCtx(() => [
                _createVNode(_component_DataLocalization, { visible: "" }),
                _createVNode(_component_AppButton, {
                    class: "p-button-tertiary",
                    onClick: _ctx.handleCancel,
                    disabled: _ctx.isSaving
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_SaveEntityButton, {
                    label: _ctx.translate('buttons.save'),
                    disabled: _ctx.isSaveDisabled,
                    "data-qa-id": "save-button",
                    onLoading: _cache[3] || (_cache[3] = ($event) => (_ctx.isSaving = $event)),
                    onClickCapture: _withModifiers(_ctx.handleSave, ["stop"]),
                    onSave: _ctx.navigateToDetails,
                    ref: "saveEntityButton"
                }, null, 8, ["label", "disabled", "onClickCapture", "onSave"])
            ]),
            _: 1
        }),
        (!_ctx.temporaryMode)
            ? (_openBlock(), _createBlock(_component_PanelTopNavigation, {
                key: 0,
                "is-loading": _ctx.isLoading,
                "menu-items": _ctx.menuItems,
                skeletons: _ctx.modules.length
            }, null, 8, ["is-loading", "menu-items", "skeletons"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_RouterView)
        ])
    ], 8, _hoisted_1));
}
