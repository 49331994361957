import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditVacuumTestConfirmationComponent from "@/app/sub-modules/vacuum-test-confirmation/components/edit-vacuum-test-confirmation.component.vue";
import DisplayVacuumTestConfirmationComponent from "@/app/sub-modules/vacuum-test-confirmation/components/display-vacuum-test-confirmation.component.vue";
import DisplayVacuumTestConfirmationViewComponent from "@/app/sub-modules/vacuum-test-confirmation/components/display-vacuum-test-confirmation-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const VacuumTestConfirmationSubModule = (domainId, options = {}) => {
    const id = "vacuumTestConfirmation";
    const moduleId = options.moduleId ?? CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditVacuumTestConfirmationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayVacuumTestConfirmationComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayVacuumTestConfirmationViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "sanitizer",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.vacuum-test-confirmation",
            },
        ],
    });
};
