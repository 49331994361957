export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["NotRequired"] = "NotRequired";
    VerificationStatus["NotStarted"] = "NotStarted";
    VerificationStatus["Requested"] = "Requested";
    VerificationStatus["Confirmed"] = "Confirmed";
    VerificationStatus["Info"] = "Info";
    VerificationStatus["InProgress"] = "InProgress";
    VerificationStatus["Verified"] = "Verified";
    VerificationStatus["InReview"] = "InReview";
    VerificationStatus["NoFeedBack"] = "ConcludedWithoutVerification";
})(VerificationStatus || (VerificationStatus = {}));
