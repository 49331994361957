import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "gap-1 d-inline-flex mb-2" };
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('header'),
        id: "create-business-partner-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.company,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.company) = $event)),
                errors: _ctx.errors,
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("p", {
                            innerHTML: _ctx.translate('sub-header')
                        }, null, 8, _hoisted_2),
                        _createVNode(_component_Icon, {
                            class: "icon",
                            variant: "outlined",
                            name: "supervisor_account"
                        })
                    ]),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-name.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.company-name.placeholder'),
                                name: "name",
                                id: "company-name-input-field",
                                required: ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "name" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.reference-number.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.reference-number.placeholder'),
                                name: "referenceNumber",
                                id: "reference-number-input-field",
                                "min-length": "3",
                                "max-length": "30",
                                required: ""
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "referenceNumber" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-type.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_Dropdown, {
                                name: "companyType",
                                options: _ctx.companyTypeOptions,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "CompanyTypes",
                                placeholder: _ctx.translate('form-fields.company-type.placeholder'),
                                required: "",
                                disabled: ""
                            }, null, 8, ["options", "placeholder"]),
                            _createVNode(_component_FormError, { name: "companyType" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_Dropdown, {
                                name: "managedById",
                                options: _ctx.companiesWithAdminRights,
                                optionValue: "id",
                                optionLabel: "name",
                                additionalLabel: "referenceNumber",
                                filterFields: ['referenceNumber'],
                                placeholder: _ctx.translate('form-fields.business-partner-of.placeholder'),
                                required: ""
                            }, null, 8, ["options", "placeholder"]),
                            _createVNode(_component_FormError, { name: "managedById" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Button, {
                                class: "p-button-lg p-button-tertiary",
                                onClick: _ctx.close,
                                id: "cancel-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_Button, {
                                class: "p-button-lg",
                                loading: _ctx.isCreating,
                                disabled: !_ctx.isValid,
                                onClick: _ctx.create,
                                id: "create-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                                ]),
                                _: 1
                            }, 8, ["loading", "disabled", "onClick"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "errors", "initial"])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
