export const defaultCargoSecuring = {
    noSpecialRequirements: true,
    minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR: [
        {
            value: null,
            languageCode: "en",
        },
        {
            value: null,
            languageCode: "de",
        },
    ],
    minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR: [
        {
            value: null,
            languageCode: "en",
        },
        {
            value: null,
            languageCode: "de",
        },
    ],
    cargoSecuringMaterial: {
        certifiedCargoSecuringSystem: {
            isRequired: null,
            providedBy: null,
            systemSpecification: [],
            requiredOnlyInCaseOf: [],
            additionalInformation: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
        },
        slatBoards: {
            providedBy: null,
            tarpaulinTrailers: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
            curtainSideTrailers: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
            tarpaulinSuperstructures: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
            boxTruck: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
        },
        antiSlipMats: {
            providedBy: null,
            carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo: null,
            minimumNumberPerPallet: null,
            minimumNumberPerTruck: null,
            minimumLengthSize: null,
            minimumWidthSize: null,
            alternativeLengthSize: null,
            alternativeWidthSize: null,
            minimumThickness: null,
            frictionCoefcient: null,
        },
        lashing: {
            providedBy: null,
            dinNorm: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
            carrierToEnsureSufficientNumberOfLashingsToSecureCargo: null,
            minimumNumberPerPallet: null,
            minimumNumberPerTruck: null,
            minimumLength: null,
            minimumLashingCapacity: null,
            minimumTensionForceSTF: null,
        },
        disposalOfCargoSecuringMaterial: {
            instructionsToDisposeTheSecuringMaterialAfterUnloading: null,
            additionalInformation: [
                {
                    value: null,
                    languageCode: "en",
                },
                {
                    value: null,
                    languageCode: "de",
                },
            ],
        },
    },
};
