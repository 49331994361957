import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-797bbbe6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-pump" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_8 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("pump-to-be-provided-by-carrier")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.pumpToBeProvidedByCarrier), 1)
        ]),
        (_ctx.model?.pumpToBeProvidedByCarrier)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("name")), 1),
                    (_ctx.model?.pumpTypes?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.pumpTypes, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "PumpType")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("power-supply")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getValue(_ctx.model?.powerSupply, "PumpPowerSupply")), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("additional-pump-requirements")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.additionalPumpInfo()), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.additionalPumpInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ], 64))
            : _createCommentVNode("", true)
    ]));
}
