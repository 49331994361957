export const ManageCompanyRouteName = "manage-company";
export const ManageCompanyAccountRouteName = "manage-company/account";
export const ManageCompanyAccountEditRouteName = "manage-company-edit/account";
export const DefaultCompany = {
    id: "",
    name: "",
    referenceNumber: "",
    email: "",
    telephone: "",
    faxNumber: "",
    companyType: "",
    managedByExternalCompany: false,
    managedById: "",
    managedByName: "",
    updatedAtUtc: "",
    address: {
        streetAddress: "",
        addressLocality: "",
        postalCode: "",
        addressCountry: "",
    },
};
