import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
/**
 * WORKAROUND: This interceptor makes Fact Sheet's data structure adhere to Modular Content constraints.
 */
let FactSheetModuleDataInterceptor = class FactSheetModuleDataInterceptor {
    interceptRequest(request, next) {
        if (this.hasFactSheetUrl(request) && request.method === "put") {
            request.data = this.patchRequestData(request);
        }
        return next(request);
    }
    async interceptResponse(config, response) {
        if (this.hasFactSheetUrl(config) && config.method === "get" && this.hasPristineResponseData(response)) {
            response.data = this.patchResponseData(response);
        }
        return response;
    }
    onResponseError(request, error) {
        if (this.hasFactSheetUrl(request) && request.method === "put" && error.response) {
            error.response.data = this.patchErrorResponseData(error.response);
        }
        return error;
    }
    hasFactSheetUrl(config) {
        return config.url?.endsWith("/factsheet");
    }
    patchRequestData(request) {
        const data = request.data;
        return {
            site: {
                generalInformation: {
                    processes: data?.Site_processes,
                    siteInformation: data?.siteInformation,
                    address: data?.Site_address,
                    accessRestrictions: data?.Site_accessRestrictions,
                    openingHours: data?.Site_openingHours,
                    additionalInformation: data?.Site_additionalInformation,
                    siteNavigation: data?.siteNavigation,
                },
                checkIn: {
                    checkIns: data.checkIns,
                },
                facilities: {
                    nearbyInfrastructures: data?.nearbyInfrastructures,
                    parkingSpaces: data?.parkingSpaces,
                    weighingBridges: data?.weighingBridges,
                },
                gates: {
                    gates: data?.gates,
                },
                safetyAndSecurity: {
                    alarmAndEmergencyProcedures: data?.alarmAndEmergencyProcedures,
                    generalSafetyRules: data?.generalSafetyRules,
                    safetyTest: data?.safetyTest,
                    siteIncidentManagement: data?.siteIncidentManagement,
                    trafficRules: data?.trafficRules,
                },
            },
            //
            unloadingPoint: {
                generalInformation: {
                    basicInformation: data?.UnloadingPoint_basicInformation,
                    processes: data?.UnloadingPoint_processes,
                    address: data?.UnloadingPoint_address,
                    accessRestrictions: data?.UnloadingPoint_accessRestrictions,
                    openingHours: data?.UnloadingPoint_openingHours,
                    driveInTimeRestrictions: data?.driveInTimeRestrictions,
                    contactData: data?.contactData,
                    registration: data?.registration,
                },
                slotBooking: {
                    shipmentPreNotification: data?.shipmentPreNotification,
                    slotBooking: data?.slotBooking,
                },
                installations: {
                    unloadingLoadingArea: data?.unloadingLoadingArea,
                    availableEquipment: data?.availableEquipment,
                    availableEquipmentPacked: data?.availableEquipmentPacked,
                    receivingTank: data?.receivingTank,
                    workingOnTop: data?.workingOnTop,
                },
            },
            //
            product: {
                technicalRequirements: {
                    temperatureRequirementsBulkUnloading: data?.temperatureRequirementsBulkUnloading,
                    temperatureRequirementsPacked: data?.temperatureRequirementsPacked,
                    vehicle: data?.vehicle,
                    tank: data?.tank,
                    connections: data?.connections,
                    hose: data?.hose,
                    pump: data?.pump,
                    compressor: data?.compressor,
                    vapourReturn: data?.vapourReturn,
                    equipmentPacked: data?.equipmentPacked,
                    cargoSecuring: data?.cargoSecuring,
                },
                instructions: {
                    generalInstructions: data?.generalInstructions,
                    weighing: data?.weighing,
                    filling: data?.filling,
                    sealing: data?.sealing,
                    sampling: data?.sampling,
                    processes: data?.AssignedProduct_processes,
                },
                safetyAndSecurity: {
                    vehicleAndPersonalProtectiveEquipment: data?.vehicleAndPersonalProtectiveEquipment,
                },
                documents: {
                    documents: data?.documents,
                },
                packaging: {
                    packaging: data?.packaging,
                    pallets: data?.pallets,
                },
            },
        };
    }
    hasPristineResponseData(response) {
        return Boolean(response.data.site);
    }
    patchResponseData(response) {
        const { site, unloadingPoint, product } = response.data;
        return {
            siteInformation: site?.generalInformation?.siteInformation,
            Site_address: site?.generalInformation?.address,
            siteNavigation: site?.generalInformation?.siteNavigation,
            Site_processes: site?.generalInformation?.processes,
            Site_openingHours: site?.generalInformation?.openingHours,
            Site_additionalInformation: site?.generalInformation?.additionalInformation,
            Site_accessRestrictions: site?.generalInformation?.accessRestrictions,
            nearbyInfrastructures: site?.facilities?.nearbyInfrastructures,
            parkingSpaces: site?.facilities?.parkingSpaces,
            weighingBridges: site?.facilities?.weighingBridges,
            checkIns: site?.checkIn?.checkIns,
            gates: site?.gates?.gates,
            alarmAndEmergencyProcedures: site?.safetyAndSecurity?.alarmAndEmergencyProcedures,
            generalSafetyRules: site?.safetyAndSecurity?.generalSafetyRules,
            safetyTest: site?.safetyAndSecurity?.safetyTest,
            siteIncidentManagement: site?.safetyAndSecurity?.siteIncidentManagement,
            trafficRules: site?.safetyAndSecurity?.trafficRules,
            //
            UnloadingPoint_basicInformation: unloadingPoint?.generalInformation?.basicInformation,
            UnloadingPoint_address: unloadingPoint?.generalInformation?.address,
            UnloadingPoint_accessRestrictions: unloadingPoint?.generalInformation?.accessRestrictions,
            registration: unloadingPoint?.generalInformation?.registration,
            UnloadingPoint_openingHours: unloadingPoint?.generalInformation?.openingHours,
            driveInTimeRestrictions: unloadingPoint?.generalInformation?.driveInTimeRestrictions,
            contactData: unloadingPoint?.generalInformation?.contactData,
            UnloadingPoint_processes: unloadingPoint?.generalInformation?.processes,
            unloadingLoadingArea: unloadingPoint?.installations?.unloadingLoadingArea,
            availableEquipment: unloadingPoint?.installations?.availableEquipment,
            availableEquipmentPacked: unloadingPoint?.installations?.availableEquipmentPacked,
            receivingTank: unloadingPoint?.installations?.receivingTank,
            workingOnTop: unloadingPoint?.installations?.workingOnTop,
            shipmentPreNotification: unloadingPoint?.slotBooking?.shipmentPreNotification,
            slotBooking: unloadingPoint?.slotBooking?.slotBooking,
            //
            vehicleAndPersonalProtectiveEquipment: product?.safetyAndSecurity.vehicleAndPersonalProtectiveEquipment,
            temperatureRequirementsBulkUnloading: product?.technicalRequirements?.temperatureRequirementsBulkUnloading,
            temperatureRequirementsPacked: product?.technicalRequirements?.temperatureRequirementsPacked,
            vehicle: product?.technicalRequirements?.vehicle,
            tank: product?.technicalRequirements?.tank,
            connections: product?.technicalRequirements?.connections,
            hose: product?.technicalRequirements?.hose,
            pump: product?.technicalRequirements?.pump,
            compressor: product?.technicalRequirements?.compressor,
            vapourReturn: product?.technicalRequirements?.vapourReturn,
            equipmentPacked: product?.technicalRequirements?.equipmentPacked,
            cargoSecuring: product?.technicalRequirements?.cargoSecuring,
            packaging: product?.packaging?.packaging,
            pallets: product?.packaging?.pallets,
            documents: product?.documents?.documents,
            generalInstructions: product?.instructions?.generalInstructions,
            weighing: product?.instructions?.weighing,
            filling: product?.instructions?.filling,
            sealing: product?.instructions?.sealing,
            sampling: product?.instructions?.sampling,
            AssignedProduct_processes: product?.instructions?.processes,
        };
    }
    patchErrorResponseData(response) {
        const { errors } = response.data;
        if (errors) {
            response.data.errors = errors.map(this.patchErrorPaths);
        }
        return response.data;
    }
    patchErrorPaths(error) {
        const pristineToPatchedPaths = {
            "site.generalInformation.siteInformation": "siteInformation",
            "site.generalInformation.address": "Site_address",
            "site.generalInformation.siteNavigation": "siteNavigation",
            "site.generalInformation.processes": "Site_processes",
            "site.generalInformation.openingHours": "Site_openingHours",
            "site.generalInformation.additionalInformation": "Site_additionalInformation",
            "site.generalInformation.accessRestrictions": "Site_accessRestrictions",
            "site.facilities.nearbyInfrastructures": "nearbyInfrastructures",
            "site.facilities.parkingSpaces": "parkingSpaces",
            "site.facilities.weighingBridges": "weighingBridges",
            "site.checkIn.checkIns": "checkIns",
            "site.gates.gates": "gates",
            "site.safetyAndSecurity.alarmAndEmergencyProcedures": "alarmAndEmergencyProcedures",
            "site.safetyAndSecurity.generalSafetyRules": "generalSafetyRules",
            "site.safetyAndSecurity.safetyTest": "safetyTest",
            "site.safetyAndSecurity.siteIncidentManagement": "siteIncidentManagement",
            "site.safetyAndSecurity.trafficRules": "trafficRules",
            //
            "unloadingPoint.generalInformation.basicInformation": "UnloadingPoint_basicInformation",
            "unloadingPoint.generalInformation.address": "UnloadingPoint_address",
            "unloadingPoint.generalInformation.accessRestrictions": "UnloadingPoint_accessRestrictions",
            "unloadingPoint.generalInformation.registration": "registration",
            "unloadingPoint.generalInformation.openingHours": "UnloadingPoint_openingHours",
            "unloadingPoint.generalInformation.driveInTimeRestrictions": "driveInTimeRestrictions",
            "unloadingPoint.generalInformation.contactData": "contactData",
            "unloadingPoint.generalInformation.processes": "UnloadingPoint_processes",
            "unloadingPoint.installations.unloadingLoadingArea": "unloadingLoadingArea",
            "unloadingPoint.installations.availableEquipment": "availableEquipment",
            "unloadingPoint.installations.availableEquipmentPacked": "availableEquipmentPacked",
            "unloadingPoint.installations.receivingTank": "receivingTank",
            "unloadingPoint.installations.workingOnTop": "workingOnTop",
            "unloadingPoint.slotBooking.shipmentPreNotification": "shipmentPreNotification",
            "unloadingPoint.slotBooking.slotBooking": "slotBooking",
            //
            "product.safetyAndSecurity.vehicleAndPersonalProtectiveEquipment": "vehicleAndPersonalProtectiveEquipment",
            "product.technicalRequirements.temperatureRequirements": "temperatureRequirements",
            "product.technicalRequirements.vehicle": "vehicle",
            "product.technicalRequirements.tank": "tank",
            "product.technicalRequirements.connections": "connections",
            "product.technicalRequirements.hose": "hose",
            "product.technicalRequirements.pump": "pump",
            "product.technicalRequirements.compressor": "compressor",
            "product.technicalRequirements.vapourReturn": "vapourReturn",
            "product.technicalRequirements.equipmentPacked": "equipmentPacked",
            "product.technicalRequirements.cargoSecuring": "cargoSecuring",
            "product.packaging.packaging": "packaging",
            "product.packaging.pallets": "pallets",
            "product.documents.documents": "documents",
            "product.instructions.generalInstructions": "generalInstructions",
            "product.instructions.weighing": "weighing",
            "product.instructions.filling": "filling",
            "product.instructions.sealing": "sealing",
            "product.instructions.sampling": "sampling",
            "product.instructions.processes": "AssignedProduct_processes",
        };
        error.paths = error.paths.map((path) => {
            for (const [pristinePath, patchedPath] of Object.entries(pristineToPatchedPaths)) {
                if (path.includes(pristinePath)) {
                    return path.replace(pristinePath, patchedPath);
                }
            }
            return path;
        });
        return error;
    }
};
FactSheetModuleDataInterceptor = __decorate([
    service()
], FactSheetModuleDataInterceptor);
export { FactSheetModuleDataInterceptor };
