import { createModule } from "@/di/module";
import { CheckInModuleId } from "@/app/modules/check-in/check-in.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import DisplayCheckInComponent from "@/app/sub-modules/check-in/components/display-check-in.component.vue";
import EditCheckInComponent from "@/app/sub-modules/check-in/components/edit-check-in.component.vue";
export const CheckInSubModule = (domainId, options = {}) => {
    const id = "checkIn";
    const moduleId = options.moduleId ?? CheckInModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditCheckInComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayCheckInComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "meeting_room",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.check-in" }],
    });
};
