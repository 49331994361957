import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f0e019e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-safety-test" };
const _hoisted_2 = {
    key: 1,
    class: "row section"
};
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6" };
const _hoisted_5 = { key: 2 };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { class: "section" };
const _hoisted_10 = { class: "row section" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-description" };
const _hoisted_13 = {
    key: 0,
    class: "row section"
};
const _hoisted_14 = { class: "col-6 column-header" };
const _hoisted_15 = { class: "col-6" };
const _hoisted_16 = ["href"];
const _hoisted_17 = {
    key: 1,
    class: "column-description"
};
const _hoisted_18 = { key: 1 };
const _hoisted_19 = { class: "row section" };
const _hoisted_20 = { class: "col-6 column-header" };
const _hoisted_21 = { class: "col-6 column-description" };
const _hoisted_22 = { class: "col-3" };
const _hoisted_23 = { class: "row section" };
const _hoisted_24 = { class: "col-6 column-header" };
const _hoisted_25 = { class: "col-6" };
const _hoisted_26 = { class: "row section" };
const _hoisted_27 = { class: "col-6 column-header" };
const _hoisted_28 = { class: "col-6 column-description" };
const _hoisted_29 = { key: 2 };
const _hoisted_30 = {
    key: 0,
    class: "row section"
};
const _hoisted_31 = { class: "col-6 offset-6 column-description" };
const _hoisted_32 = {
    key: 1,
    class: "row section"
};
const _hoisted_33 = { class: "col-6 offset-6" };
const _hoisted_34 = ["href"];
const _hoisted_35 = {
    key: 2,
    class: "row section"
};
const _hoisted_36 = { class: "col-6 offset-6" };
const _hoisted_37 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model?.required)
            ? (_openBlock(), _createBlock(_component_LocalizedField, {
                key: 0,
                modelValue: _ctx.model?.requiredText,
                freeText: ""
            }, {
                header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("safety-test-required")), 1)
                ]),
                description: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.requirementStatus()), 1)
                ]),
                _: 1
            }, 8, ["modelValue"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("safety-test-required")), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_EmptyStateMessage, null, {
                        default: _withCtx(() => [
                            _createTextVNode("-")
                        ]),
                        _: 1
                    })
                ])
            ])),
        (_ctx.requirementAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("test-language")), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.languagesInfo()), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_DisplayAttachments, {
                        attachments: _ctx.model.instructions,
                        title: _ctx.translate('test-instructions')
                    }, null, 8, ["attachments", "title"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("safety-test-procedure")), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.safetyTestProcedureInfo), 1)
                ]),
                (_ctx.model?.procedure.isOnline)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("link-to-online-test")), 1),
                        _createElementVNode("div", _hoisted_15, [
                            (_ctx.model.procedure.onlineLink)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: _ctx.model.procedure.onlineLink,
                                    target: "_blank",
                                    class: "column-link-value"
                                }, _toDisplayString(_ctx.model.procedure.onlineLink), 9, _hoisted_16))
                                : (_openBlock(), _createElementBlock("span", _hoisted_17, "-"))
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.model?.procedure.isInPresence)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("safety-test-adress")), 1),
                            _createElementVNode("div", _hoisted_21, [
                                _createVNode(_component_DisplayAddress, {
                                    short: "",
                                    address: _ctx.model.procedure.inPresencePostalAddress
                                }, null, 8, ["address"])
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                                _createVNode(_component_DisplayLocation, {
                                    class: "map-button",
                                    location: _ctx.model.procedure.inPresenceLocation
                                }, null, 8, ["location"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.translate("safety-test-opening-hours")), 1),
                            _createElementVNode("div", _hoisted_25, [
                                (_ctx.noOpeningHoursDefined)
                                    ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("phrases.empty-opening-hours")), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                                        key: 1,
                                        "opening-hours": _ctx.model.procedure.inPresenceOpeningHours
                                    }, null, 8, ["opening-hours"]))
                            ])
                        ])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.translate("slot-booking-for-safety-test")), 1),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.slotInfo), 1)
                ]),
                (_ctx.model?.slotBooking.isRequired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        (_ctx.model.slotBooking.telephone)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.model.slotBooking.telephone), 1)
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model.slotBooking.email)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                _createElementVNode("div", _hoisted_33, [
                                    _createElementVNode("a", {
                                        href: `mailto:${_ctx.model.slotBooking.email}`,
                                        target: "_blank",
                                        class: "column-link-value"
                                    }, _toDisplayString(_ctx.model.slotBooking.email), 9, _hoisted_34)
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model.slotBooking.link)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                    _createElementVNode("a", {
                                        href: _ctx.model.slotBooking.link,
                                        target: "_blank",
                                        class: "column-link-value"
                                    }, [
                                        _createTextVNode(_toDisplayString(_ctx.translate("booking-tool")) + " ", 1),
                                        _createVNode(_component_Icon, { name: "launch" })
                                    ], 8, _hoisted_37)
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true)
            ]))
            : _createCommentVNode("", true)
    ]));
}
