import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1cd81319"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "temperature-ranges-display" };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = { class: "temperature-info" };
const _hoisted_4 = {
    key: 0,
    class: "date-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
            : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (temperatureRange, index) => {
            return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "column-description"
            }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.temperatureRangeInfo(temperatureRange)), 1),
                (_ctx.isDateRangeValid(index))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.parseDate(temperatureRange.datesRange)), 1))
                    : _createCommentVNode("", true)
            ]));
        }), 128))
    ]));
}
