import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditVehicleAndPPE from "@/app/sub-modules/vehicle-and-ppe/components/edit-vehicle-and-ppe.component.vue";
import DisplayVehicleAndPPE from "@/app/sub-modules/vehicle-and-ppe/components/display-vehicle-and-ppe.component.vue";
import DisplayVehicleAndPPEView from "@/app/sub-modules/vehicle-and-ppe/components/display-vehicle-and-ppe-view.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
export const VehicleAndPPESubModule = (domainId, options = {}) => {
    const id = options.id ?? "vehicleAndPersonalProtectiveEquipment";
    const moduleId = options.moduleId ?? SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditVehicleAndPPE, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayVehicleAndPPE, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayVehicleAndPPEView, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "masks",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.vehicle-and-personal-protective-equipment" }],
    });
};
