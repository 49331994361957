import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { ProductsSafetyModuleId } from "@/app/modules/products-safety/products-safety.module";
import DisplayDgClassification from "@/app/sub-modules/dg-classification/components/display-dg-classification.vue";
import DisplayDgClassificationView from "@/app/sub-modules/dg-classification/components/display-dg-classification-view.vue";
import EditDgClassification from "@/app/sub-modules/dg-classification/components/edit-dg-classification.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const DgClassificationSubModule = (domainId, options = {}) => {
    const id = "dgClassification";
    const moduleId = options.moduleId ?? ProductsSafetyModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayDgClassification, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayDgClassificationView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditDgClassification, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "account_tree",
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.dg-classification",
            },
        ],
    });
};
