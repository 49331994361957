import { FactSheetModuleId } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const FactSheetModule = (entityId, options = {}) => {
    return createModule({
        imports: [
            ModuleModule({
                id: FactSheetModuleId,
                domainId: entityId,
                readOnly: options.readOnly,
                originDomain: options.originDomain,
            }),
        ],
    });
};
