import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2642e186"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputText = _resolveComponent("InputText");
    const _component_InputUrl = _resolveComponent("InputUrl");
    const _component_Textarea = _resolveComponent("Textarea");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('manage-notifications-title'),
        showCloseIcon: false,
        class: "create-notification-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_AppForm, {
                        modelValue: _ctx.notification,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.notification) = $event)),
                        initial: _ctx.initial,
                        errors: _ctx.errors,
                        onIsValid: _cache[2] || (_cache[2] = ($event) => (_ctx.isValid = $event))
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, { required: "" }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("notification-receivers-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "receivers",
                                        options: _ctx.roles,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "NotificationRoles",
                                        placeholder: _ctx.translate('notification-receivers-placeholder'),
                                        withHeader: true,
                                        required: ""
                                    }, null, 8, ["options", "placeholder"]),
                                    _createVNode(_component_FormError, { name: "receivers" })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("header-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_InputText, {
                                        name: "header",
                                        placeholder: _ctx.translate('header-placeholder'),
                                        "min-length": "3",
                                        "max-length": "30"
                                    }, null, 8, ["placeholder"]),
                                    _createVNode(_component_FormError, { name: "header" })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("link-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_InputUrl, {
                                        name: "link",
                                        placeholder: _ctx.translate('link-placeholder'),
                                        "max-length": "256",
                                        "url-with-optional-schema": ""
                                    }, null, 8, ["placeholder"]),
                                    _createVNode(_component_FormError, { name: "link" })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("text-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_Textarea, {
                                        name: "text",
                                        placeholder: _ctx.translate('text-placeholder'),
                                        autoResize: true,
                                        rows: "2",
                                        cols: "30",
                                        "min-length": "3",
                                        "max-length": "1000"
                                    }, null, 8, ["placeholder"]),
                                    _createVNode(_component_FormError, { name: "text" })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, {
                                        disabled: !_ctx.notification.link
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("x-button-label")), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["disabled"]),
                                    _createVNode(_component_RadioButton, {
                                        modelValue: _ctx.notification.showXButton,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.notification.showXButton) = $event)),
                                        disabled: !_ctx.notification.link,
                                        isOptionDeselectable: false
                                    }, null, 8, ["modelValue", "disabled"])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }, 8, ["modelValue", "initial", "errors"])
                ])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.modal.close()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isCreating,
                        disabled: !_ctx.isValid,
                        onClick: _ctx.createMethod
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.send")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
