import { defineComponent } from "vue";
import { resolve, resolveAll } from "@/di/composables/resolve";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { RouterService } from "@/app/shared/services/router.service";
import { useTranslate } from "@/app/translation/composables/use-translate.composable";
import { RouteMetaDomainId } from "@/app/modular-content/modular-content.constants";
import { translationDomainPrefix } from "@/app/modular-content/modules/functions/translation-domain-prefix.function";
export default defineComponent({
    name: "title",
    setup() {
        return {
            translate: useTranslate(),
            routerService: resolve(RouterService),
            domainServices: resolveAll(DomainService),
        };
    },
    data() {
        return {
            globalTitle: document.title,
        };
    },
    computed: {
        title() {
            return this.localTitle ? `${this.localTitle} | ${this.globalTitle}` : this.globalTitle;
        },
        localTitle() {
            return this.entityName || this.pluralDomainName || this.domainName;
        },
        entity() {
            return this.domainService?.getCurrent();
        },
        entityName() {
            return this.entity?.name;
        },
        domainName() {
            return this.domainTranslate("name");
        },
        pluralDomainName() {
            return this.domainTranslate("plural-name");
        },
        domainService() {
            return this.domainServices.find((domainService) => domainService.getDomainId() === this.domainId);
        },
        domainId() {
            return this.routerService.getMeta(RouteMetaDomainId);
        },
    },
    watch: {
        title() {
            document.title = this.title;
        },
    },
    methods: {
        domainTranslate(key) {
            const prefixedKey = this.getDomainPrefixedTranslationKey(key);
            const translation = this.translate(prefixedKey);
            return translation === prefixedKey ? null : translation;
        },
        getDomainPrefixedTranslationKey(key) {
            if (this.domainId) {
                const prefix = translationDomainPrefix({ id: this.domainId });
                return `${prefix}.${key}`;
            }
            return key;
        },
    },
});
