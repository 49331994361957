import { createModule } from "@/di/module";
import { ConnectModalModule } from "@/app/modular-content/modules/domain-modules/connect-modal.module";
import { CopyEntityModalModule } from "@/app/modular-content/modules/domain-modules/copy-entity-modal.module";
import { CreateModalModules } from "@/app/modular-content/modules/domain-modules/create-modal.module";
import { DomainComponentsModule } from "@/app/modular-content/modules/domain-modules/domain-components.module";
import { DomainConfigurationModule } from "@/app/modular-content/modules/domain-modules/domain-configuration.module";
import { DomainConnectServiceModule } from "@/app/modular-content/modules/domain-modules/domain-connect-service.module";
import { DomainContextMenuModule } from "@/app/modular-content/modules/domain-modules/domain-context-menu.module";
import { DomainListsModule } from "@/app/modular-content/modules/domain-modules/domain-lists.module";
import { DomainMenuModule } from "@/app/modular-content/modules/domain-modules/domain-menu.module";
import { DomainMetadataModule } from "@/app/modular-content/modules/domain-modules/domain-metadata.module";
import { DomainRestApiModule } from "@/app/modular-content/modules/domain-modules/domain-rest-api.module";
import { DomainRoutesModule } from "@/app/modular-content/modules/domain-modules/domain-routes.module";
import { DomainSearchServiceModule } from "@/app/modular-content/modules/domain-modules/domain-search-service.module";
import { DomainServiceModule } from "@/app/modular-content/modules/domain-modules/domain-service.module";
import { EditModalModule } from "@/app/modular-content/modules/domain-modules/edit-modal.module";
import { ParentDomainServiceModule } from "@/app/modular-content/modules/domain-modules/parent-domain-service.module";
import EntityConnectModalComponent from "@/app/modular-content/components/entity-connect-modal.component.vue";
import EntityCopyModalComponent from "@/app/modular-content/components/entity-copy-modal.component.vue";
import EntityCreateModalComponent from "@/app/modular-content/components/entity-create-modal.component.vue";
import EntityDetailsComponent from "@/app/modular-content/components/entity-details.component.vue";
import EntityEditComponent from "@/app/modular-content/components/entity-edit.component.vue";
import EntityEditModalComponent from "@/app/modular-content/components/entity-edit-modal.component.vue";
import EntityPageComponent from "@/app/modular-content/components/entity-page.component.vue";
import { moduleTokensFactory } from "@/app/modular-content/factories/module-tokens.factory";
import { subModuleTokensFactory } from "@/app/modular-content/factories/sub-module-tokens.factory";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { DomainIdToken, ModuleServicesToken, ParentDomainIdToken, SubModuleConfigurationServicesToken, SubModuleServicesToken, } from "@/app/modular-content/token/modular-content.token";
export const DomainModule = (options) => {
    return createModule({
        components: [
            suffixComponentName(EntityCreateModalComponent, options.id),
            suffixComponentName(EntityCopyModalComponent, options.id),
            suffixComponentName(EntityConnectModalComponent, options.id),
            suffixComponentName(EntityEditModalComponent, options.id),
            suffixComponentName(EntityDetailsComponent, options.id),
            suffixComponentName(EntityEditComponent, options.id),
            suffixComponentName(EntityPageComponent, options.id),
        ],
        imports: [
            DomainMetadataModule(options),
            DomainMenuModule(options),
            DomainContextMenuModule(options),
            DomainListsModule(options),
            DomainRoutesModule(options),
            DomainRestApiModule(options),
            DomainComponentsModule(options),
            DomainSearchServiceModule(options),
            DomainConnectServiceModule(options),
            DomainServiceModule(options),
            ParentDomainServiceModule(options),
            ConnectModalModule(options),
            CopyEntityModalModule(options),
            ...CreateModalModules(options),
            EditModalModule(options),
            DomainConfigurationModule(options),
        ],
        providers: [
            { token: DomainIdToken, useValue: options.id },
            { token: ParentDomainIdToken, useValue: options.parent },
            {
                token: ModuleServicesToken,
                useFactory: (resolve, resolveAll) => {
                    const tokens = moduleTokensFactory(options.id);
                    return resolveAll(tokens.service);
                },
            },
            {
                token: SubModuleServicesToken,
                useFactory: (resolve, resolveAll) => {
                    const tokens = subModuleTokensFactory(options.id);
                    return resolveAll(tokens.service);
                },
            },
            {
                token: SubModuleConfigurationServicesToken,
                useFactory: (resolve, resolveAll) => {
                    const tokens = moduleTokensFactory(options.id);
                    return resolveAll(tokens.configurationService);
                },
            },
        ],
    });
};
