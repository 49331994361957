import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DriveInTimeRestrictionsInputMinutes = _resolveComponent("DriveInTimeRestrictionsInputMinutes");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            (_ctx.model)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_DriveInTimeRestrictionsInputMinutes, {
                        name: "earliestDriveInBeforeOpening",
                        label: _ctx.translate('earliest-drive-in-before-opening')
                    }, null, 8, ["label"]),
                    _createVNode(_component_DriveInTimeRestrictionsInputMinutes, {
                        name: "latestDriveInBeforeClosing",
                        label: _ctx.translate('latest-drive-in-before-closing')
                    }, null, 8, ["label"]),
                    _createVNode(_component_DriveInTimeRestrictionsInputMinutes, {
                        name: "earliestDriveInBeforePlannedSlot",
                        label: _ctx.translate('earliest-drive-in-before-planned-slot')
                    }, null, 8, ["label"]),
                    _createVNode(_component_DriveInTimeRestrictionsInputMinutes, {
                        name: "latestDriveInBeforePlannedSlot",
                        label: _ctx.translate('latest-drive-in-before-planned-slot')
                    }, null, 8, ["label"])
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
