import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-62f95854"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "panel-top-navigation" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Skeleton = _resolveComponent("Skeleton");
    const _component_TabPanel = _resolveComponent("TabPanel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["skeletons-wrapper", { 'border-bottom': _ctx.menuItems.length > 1 }])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skeletons, (it) => {
                    return (_openBlock(), _createBlock(_component_Skeleton, {
                        key: it,
                        width: "125px",
                        height: "24px"
                    }));
                }), 128))
            ], 2))
            : (_openBlock(), _createBlock(_component_TabPanel, {
                key: 1,
                menuItems: _ctx.menuItems
            }, null, 8, ["menuItems"]))
    ]));
}
