import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "col-lg-7" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label")}`)), 1)
                            ]),
                            _: 1
                        }),
                        _withDirectives(_createVNode(_component_InputText, {
                            name: "name",
                            placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder')}`),
                            disabled: _ctx.isFactSheetsDomain,
                            required: ""
                        }, null, 8, ["placeholder", "disabled"]), [
                            [_directive_default_validation]
                        ]),
                        _createVNode(_component_FormError, { name: "name" })
                    ]),
                    _: 1
                }),
                (!_ctx.isFactSheetsDomain)
                    ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.name.building-label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                name: "buildingName",
                                placeholder: _ctx.translate('form-fields.name.building-placeholder'),
                                "min-length": "3",
                                "max-length": "50"
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "buildingName" })
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                (!_ctx.isFactSheetsDomain)
                    ? (_openBlock(), _createBlock(_component_FormField, { key: 1 }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix("label")}`)), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                name: "referenceNumber",
                                placeholder: _ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix('placeholder')}`),
                                "min-length": "1",
                                "max-length": "30",
                                required: ""
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "referenceNumber" })
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"])
    ]));
}
