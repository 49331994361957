var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
let ManagedByFilterService = class ManagedByFilterService {
    companyService;
    constructor(companyService) {
        this.companyService = companyService;
    }
    createEqualsFilter() {
        return [
            {
                propertyName: "ManagedById",
                propertyValue: this.companyService.getSelectedCompany()?.id,
            },
        ];
    }
};
ManagedByFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object])
], ManagedByFilterService);
export { ManagedByFilterService };
