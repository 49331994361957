import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditWorkingOnTopComponent from "@/app/sub-modules/working-on-top/components/edit-working-on-top.component.vue";
import DisplayWorkingOnTopComponent from "@/app/sub-modules/working-on-top/components/display-working-on-top.component.vue";
import DisplayWorkingOnTopView from "@/app/sub-modules/working-on-top/components/display-working-on-top-view.component.vue";
import { InstallationsModuleId } from "@/app/modules/installations/installations.module";
export const WorkingOnTopSubModule = (domainId, options = {}) => {
    const id = "workingOnTop";
    const moduleId = options.moduleId ?? InstallationsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditWorkingOnTopComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayWorkingOnTopComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, DisplayWorkingOnTopView],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "vertical_align_top",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.working-on-top",
            },
        ],
    });
};
