import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2e28511c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = {
    key: 1,
    class: "no-sub-modules"
};
const _hoisted_3 = ["data-qa-id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_SubModuleTitle = _resolveComponent("SubModuleTitle");
    return (_openBlock(), _createElementBlock("div", {
        class: "module-overview",
        "data-qa-id": _ctx.moduleService.getModuleId()
    }, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                class: "loader",
                center: ""
            }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.isForbidden(_ctx.errorResponse))
                    ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 0 }))
                    : (_ctx.areSubModulesDisabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("no-data.header")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("no-data.description")), 1)
                        ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.subModulesMetadata, (subModuleMetadata) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                                key: subModuleMetadata.id
                            }, [
                                (_ctx.enabled[subModuleMetadata.id] && _ctx.hasData(subModuleMetadata.id))
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "sub-module",
                                        "data-qa-id": subModuleMetadata.id
                                    }, [
                                        (!subModuleMetadata.hideDisplayTitle)
                                            ? (_openBlock(), _createBlock(_component_SubModuleTitle, {
                                                key: 0,
                                                icon: subModuleMetadata.icon,
                                                iconVariant: subModuleMetadata.iconVariant,
                                                publicInfo: subModuleMetadata.publicInformation && !_ctx.company?.managedByExternalCompany,
                                                headerText: _ctx.subModuleTitle(subModuleMetadata),
                                                subModuleId: subModuleMetadata.id,
                                                subModuleKey: subModuleMetadata.id,
                                                subModuleDescription: _ctx.subModuleDescription(subModuleMetadata),
                                                showChangeHistory: subModuleMetadata.showChangeHistory
                                            }, null, 8, ["icon", "iconVariant", "publicInfo", "headerText", "subModuleId", "subModuleKey", "subModuleDescription", "showChangeHistory"]))
                                            : _createCommentVNode("", true),
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subModuleDisplayComponents[subModuleMetadata.id]), { subModuleMetadata: subModuleMetadata }, null, 8, ["subModuleMetadata"]))
                                    ], 8, _hoisted_3))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 128))
            ], 64))
    ], 8, _hoisted_1));
}
