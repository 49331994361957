import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditCleaningOperatorsComponent from "@/app/sub-modules/cleaning-operators/components/edit-cleaning-operators.component.vue";
import DisplayCleaningOperatorsComponent from "@/app/sub-modules/cleaning-operators/components/display-cleaning-operators.component.vue";
import DisplayCleaningOperatorsViewComponent from "@/app/sub-modules/cleaning-operators/components/display-cleaning-operators-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const CleaningOperatorsSubModule = (domainId, options = {}) => {
    const id = "cleaningOperators";
    const moduleId = options.moduleId ?? CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditCleaningOperatorsComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayCleaningOperatorsComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayCleaningOperatorsViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "cleaning_services",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.cleaning-operators",
            },
        ],
    });
};
