import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DriverBriefingInput = _resolveComponent("DriverBriefingInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_DriverBriefingInput, {
                        title: _ctx.translate('title'),
                        name: "driverBriefingInformation",
                        "empty-state-msg": _ctx.translate('empty')
                    }, null, 8, ["title", "empty-state-msg"]),
                    _createVNode(_component_FormError, { name: "values" })
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
