import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9f69ba24"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-cargo-securing" };
const _hoisted_2 = {
    key: 0,
    class: "column-value mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6" };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-value" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_12 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-6" };
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-value" };
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6" };
const _hoisted_20 = { class: "row" };
const _hoisted_21 = { class: "col-6 column-header" };
const _hoisted_22 = { class: "col-6 column-value" };
const _hoisted_23 = { class: "row" };
const _hoisted_24 = { class: "col-6 column-header" };
const _hoisted_25 = { class: "col-6 column-value" };
const _hoisted_26 = { class: "row" };
const _hoisted_27 = { class: "col-6 column-header" };
const _hoisted_28 = { class: "col-6 column-value" };
const _hoisted_29 = { class: "row" };
const _hoisted_30 = { class: "col-6 column-header" };
const _hoisted_31 = { class: "col-6 column-value" };
const _hoisted_32 = { class: "row" };
const _hoisted_33 = { class: "col-6 column-header" };
const _hoisted_34 = { class: "col-6 column-value" };
const _hoisted_35 = { class: "row" };
const _hoisted_36 = { class: "col-6 column-header" };
const _hoisted_37 = { class: "col-6 column-value" };
const _hoisted_38 = { class: "row" };
const _hoisted_39 = { class: "col-6 column-header" };
const _hoisted_40 = { class: "col-6 column-value" };
const _hoisted_41 = { class: "row" };
const _hoisted_42 = { class: "col-6 column-header" };
const _hoisted_43 = { class: "col-6 column-value" };
const _hoisted_44 = { class: "row" };
const _hoisted_45 = { class: "col-6" };
const _hoisted_46 = { class: "row" };
const _hoisted_47 = { class: "col-6 column-header" };
const _hoisted_48 = { class: "col-6 column-value" };
const _hoisted_49 = { class: "row" };
const _hoisted_50 = { class: "col-6 column-header" };
const _hoisted_51 = { class: "col-6 column-value" };
const _hoisted_52 = { class: "row" };
const _hoisted_53 = { class: "col-6 column-header" };
const _hoisted_54 = { class: "col-6 column-value" };
const _hoisted_55 = { class: "row" };
const _hoisted_56 = { class: "col-6 column-header" };
const _hoisted_57 = { class: "col-6 column-value" };
const _hoisted_58 = { class: "row" };
const _hoisted_59 = { class: "col-6 column-header" };
const _hoisted_60 = { class: "col-6 column-value" };
const _hoisted_61 = { class: "row" };
const _hoisted_62 = { class: "col-6 column-header" };
const _hoisted_63 = { class: "col-6 column-value" };
const _hoisted_64 = { class: "row" };
const _hoisted_65 = { class: "col-6 column-header" };
const _hoisted_66 = { class: "col-6 column-value" };
const _hoisted_67 = { class: "row" };
const _hoisted_68 = { class: "col-6" };
const _hoisted_69 = { class: "row" };
const _hoisted_70 = { class: "col-6 column-header" };
const _hoisted_71 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("min-requirements-below-3-5-t")), 1),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("min-requirements-below-3-5-t")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("min-requirements-above-3-5-t")), 1),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("min-requirements-above-3-5-t")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("cargo-securing-material")), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.requiredOnlyInCaseOf,
                    freeText: ""
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1)
                    ]),
                    description: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.isRequired, "CertifiedCargoSecuringSystemRequired")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("system-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("system-specification")), 1),
                    (_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.systemSpecification?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.systemSpecification, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getDictionaryValue(tag, "SystemSpecification")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.additionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("slat-boards")), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("slat-boards-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.slatBoards?.tarpaulinTrailers
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-trailers")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.slatBoards?.curtainSideTrailers
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("slat-boards-curtain-side-trailers")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.slatBoards?.tarpaulinSuperstructures
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-superstructures")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.slatBoards?.boxTruck
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("box-truck")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("anti-slip-mats")), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.translate("anti-slip-mats-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.translate("carrier-ensures-number-of-anti-slip-mats")), 1),
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                ]),
                (_ctx.isCompanyGuideline(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.translate("min-number-per-pallet")), 1),
                            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumNumberPerPallet)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.translate("min-number-per-truck")), 1),
                            _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumNumberPerTruck)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.translate("min-size")), 1),
                            _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.getDimensions(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumLengthSize, _ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumWidthSize)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.translate("alt-size")), 1),
                            _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.getDimensions(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.alternativeLengthSize, _ctx.model?.cargoSecuringMaterial?.antiSlipMats?.alternativeWidthSize)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.translate("min-thickness")), 1),
                            _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumThickness, "global.measurement.millimeter")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.translate("friction-coeficient")), 1),
                            _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.frictionCoefcient, "global.measurement.micro")), 1)
                        ])
                    ], 64))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.translate("lashing")), 1)
                ]),
                _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.translate("lashing-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.lashing?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.lashing?.dinNorm
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("din-compliance")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.translate("carrier-ensures-number-of-lashing")), 1),
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.lashing?.carrierToEnsureSufficientNumberOfLashingsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                ]),
                (_ctx.isCompanyGuideline(_ctx.model?.cargoSecuringMaterial?.lashing?.carrierToEnsureSufficientNumberOfLashingsToSecureCargo))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_52, [
                            _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.translate("min-number-per-pallet")), 1),
                            _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumNumberPerPallet)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_55, [
                            _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.translate("min-number-per-truck")), 1),
                            _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumNumberPerTruck)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_58, [
                            _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.translate("min-length")), 1),
                            _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumLength, "global.measurement.meter")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_61, [
                            _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.translate("min-lashing-capacity")), 1),
                            _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumLashingCapacity, "global.measurement.decanewton")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.translate("min-tension-force")), 1),
                            _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumTensionForceSTF, "global.measurement.decanewton")), 1)
                        ])
                    ], 64))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("div", _hoisted_68, _toDisplayString(_ctx.translate("disposal-of-cargo-securing-material")), 1)
                ]),
                _createElementVNode("div", _hoisted_69, [
                    _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.translate("carrier-ensures-disposal-instructions")), 1),
                    _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.disposalOfCargoSecuringMaterial
                        ?.instructionsToDisposeTheSecuringMaterialAfterUnloading, "DisposalOfSecuringMaterial")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.cargoSecuringMaterial?.disposalOfCargoSecuringMaterial?.additionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ]))
    ]));
}
