import { createModule } from "@/di/module";
import { PackagingModuleId } from "@/app/modules/packaging/packaging.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import EditPackagingComponent from "@/app/sub-modules/packaging/components/edit-packaging.component.vue";
import DisplayPackagingComponent from "@/app/sub-modules/packaging/components/display-packaging.component.vue";
import DisplayPackagingViewComponent from "@/app/sub-modules/packaging/components/display-packaging-view.component.vue";
export const PackagingSubModule = (domainId, options = {}) => {
    const id = "packaging";
    const moduleId = options.moduleId ?? PackagingModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditPackagingComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayPackagingComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayPackagingViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "inventory_2",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.packaging" }],
    });
};
