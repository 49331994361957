var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { DictionariesServiceToken } from "@/app/dictionaries/tokens/dictionaries.token";
let DictionariesService = class DictionariesService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    getDictionary(dictionaryId) {
        return this.httpService.get(`dictionaries/${dictionaryId}`);
    }
    getDictionaries(dictionaryIds) {
        return this.httpService.get(`dictionaries/?ids=${dictionaryIds}`);
    }
    getDictionaryGroup(dictionaryId, group) {
        return this.httpService.get(`dictionaries/${dictionaryId}/${group}`);
    }
};
DictionariesService = __decorate([
    service({ token: DictionariesServiceToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], DictionariesService);
export { DictionariesService };
