import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const GeneralInformationModuleId = "generalInformation";
export const GeneralInformationModule = (entityId, options = {}) => {
    return createModule({
        imports: [
            ModuleModule({
                id: GeneralInformationModuleId,
                domainId: entityId,
                readOnly: options.readOnly,
                originDomain: options.originDomain,
            }),
        ],
    });
};
