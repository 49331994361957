import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditAccessRestrictionsShared = _resolveComponent("EditAccessRestrictionsShared");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.translate("size-access-restriction")), 1),
        _createVNode(_component_EditAccessRestrictionsShared, {
            modelValue: _ctx.accessRestrictionsModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.accessRestrictionsModel) = $event)),
            initial: _ctx.initialAccessRestrictions
        }, null, 8, ["modelValue", "initial"]),
        _createVNode(_component_AppForm, {
            modelValue: _ctx.additionalRestrictionsModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.additionalRestrictionsModel) = $event)),
            initial: _ctx.initialAdditionalRestrictions,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_AttachmentsInput, {
                            name: "additionalRestrictions",
                            title: _ctx.translate('form-fields.additional-restrictions.label')
                        }, null, 8, ["title"]),
                        _createVNode(_component_FormError, {
                            name: `additionalRestrictions[${_ctx.$utils.findErrorIndex(_ctx.errors)}].values`
                        }, null, 8, ["name"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"])
    ], 64));
}
