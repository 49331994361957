import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_CollapsiblePanel = _resolveComponent("CollapsiblePanel");
    return (_openBlock(), _createBlock(_component_CollapsiblePanel, {
        title: _ctx.title,
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click'))),
        descriptionIcon: false,
        "initially-collapsed": ""
    }, {
        default: _withCtx(() => [
            (!_ctx.hasData)
                ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("no-data")), 1)
                    ]),
                    _: 1
                }))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
        ]),
        _: 3
    }, 8, ["title"]));
}
