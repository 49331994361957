import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c113a0d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-6 column-header" };
const _hoisted_3 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_4 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6" };
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6" };
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_17 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6" };
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_24 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-6 column-header" };
const _hoisted_27 = {
    key: 0,
    class: "col-6 column-description allowed-heating-medium"
};
const _hoisted_28 = {
    key: 0,
    class: "column-description"
};
const _hoisted_29 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_30 = { class: "row" };
const _hoisted_31 = { class: "col-6 column-header" };
const _hoisted_32 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_33 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_34 = { class: "row" };
const _hoisted_35 = { class: "col-6 column-header" };
const _hoisted_36 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TemperatureRangesDisplay = _resolveComponent("TemperatureRangesDisplay");
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("max-contact-temperature")), 1),
            (_ctx.model.maxContactTemperature)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.model.maxContactTemperature) + " " + _toDisplayString(_ctx.getValue(_ctx.model.maxContactTemperatureUnit, "TemperatureUnit")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("tank-temperature-loading")), 1),
            _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.tankTemperatureAtTheTimeOfLoading,
                    temperatureUnit: _ctx.model.tankTemperatureAtTheTimeOfLoadingTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("loading-temperature")), 1),
            _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.loadingTemperature,
                    temperatureUnit: _ctx.model.loadingTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("transport-temperature")), 1),
            _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.transportTemperature,
                    temperatureUnit: _ctx.model.transportTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("heating-during-transport")), 1),
            (!!_ctx.model.heatingDuringTransport)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.getValue(_ctx.model.heatingDuringTransport, "HeatingDuringTransport")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
        ]),
        _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("heating-settings-during-transport")), 1),
            _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.heatingSettingsDuringTransport,
                    temperatureUnit: _ctx.model.heatingSettingsDuringTransportTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("heating-type")), 1),
            (_ctx.model.heatingType)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.getValue(_ctx.model.heatingType, "HeatingType")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_24, "-"))
        ]),
        _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.translate("max-heating-medium-temperature")), 1),
            (_ctx.isAnyHeatingMediumEnabled())
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.heatingMediumTemperatureRestrictions, (temperatureRestriction, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (temperatureRestriction?.isEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.getValue(temperatureRestriction?.heatingMedium, "HeatingMedium")) + ": " + _toDisplayString(temperatureRestriction?.maxHeatingMediumTemperature) + " " + _toDisplayString(_ctx.getValue(temperatureRestriction?.maxHeatingMediumTemperatureUnit, "TemperatureUnit")), 1))
                                : _createCommentVNode("", true)
                        ]));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_29, "-"))
        ]),
        _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.translate("cooling-during-transport")), 1),
            (!!_ctx.model.coolingDuringTransport)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.getValue(_ctx.model.coolingDuringTransport, "CoolingDuringTransport")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_33, "-"))
        ]),
        _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.translate("cooling-settings-during-transport")), 1),
            _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_TemperatureRangesDisplay, {
                    model: _ctx.model.coolingSettingsDuringTransport,
                    temperatureUnit: _ctx.model.coolingSettingsDuringTransportTemperatureUnit
                }, null, 8, ["model", "temperatureUnit"])
            ])
        ]),
        _createVNode(_component_LocalizedField, {
            class: "localized-field",
            modelValue: _ctx.model.additionalTemperatureRequirements
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
            ]),
            _: 1
        }, 8, ["modelValue"])
    ]));
}
