import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-38f8e823"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "header"
};
const _hoisted_2 = {
    ref: "content",
    class: "content"
};
const _hoisted_3 = {
    key: 0,
    class: "subtitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_ButtonClean = _resolveComponent("ButtonClean");
    const _component_SubModuleInfo = _resolveComponent("SubModuleInfo");
    const _component_Panel = _resolveComponent("Panel");
    return (_openBlock(), _createBlock(_component_Panel, {
        class: _normalizeClass(["collapsible-panel", { collapsed: _ctx.collapsed }])
    }, {
        default: _withCtx(() => [
            (!_ctx.noHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("h3", {
                        class: _normalizeClass(["title", { 'not-collapsible': !_ctx.collapsible }])
                    }, [
                        (_ctx.collapsible)
                            ? (_openBlock(), _createBlock(_component_ButtonClean, {
                                key: 0,
                                class: "flex-grow-1",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.collapsed = !_ctx.collapsed))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, {
                                        class: "icon",
                                        name: "chevron_right"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                                ]),
                                _: 1
                            }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ], 64))
                    ], 2),
                    _createVNode(_component_SubModuleInfo, {
                        publicInfo: _ctx.publicInfo,
                        descriptionIcon: _ctx.descriptionIcon,
                        headerText: _ctx.subModuleHeaderText,
                        subModuleKey: _ctx.subModuleId,
                        subModuleDescription: _ctx.subModuleDescription
                    }, null, 8, ["publicInfo", "descriptionIcon", "headerText", "subModuleKey", "subModuleDescription"]),
                    _renderSlot(_ctx.$slots, "button", {}, undefined, true)
                ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", {
                class: "content-container",
                style: _normalizeStyle({ maxHeight: _ctx.collapsed ? 0 : `${_ctx.contentHeight}px` })
            }, [
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.subtitle)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
                        : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ], 512)
            ], 4)
        ]),
        _: 3
    }, 8, ["class"]));
}
