import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-087d0c1e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "display-weighing-bridges"
};
const _hoisted_2 = { class: "name" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 address" };
const _hoisted_5 = { class: "label" };
const _hoisted_6 = { class: "details" };
const _hoisted_7 = {
    key: 0,
    class: "address-postal"
};
const _hoisted_8 = { class: "ms-3" };
const _hoisted_9 = { class: "mt-3" };
const _hoisted_10 = { class: "label" };
const _hoisted_11 = { class: "details" };
const _hoisted_12 = { class: "col-6 opening-hours" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    return (!!_ctx.model?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (it) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: it?.name,
                    class: "element"
                }, [
                    _createElementVNode("h4", _hoisted_2, _toDisplayString(it?.name), 1),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("address")) + ":", 1),
                            _createElementVNode("div", _hoisted_6, [
                                (it?.postalAddress)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                        _createVNode(_component_DisplayAddress, {
                                            address: it.postalAddress,
                                            short: "",
                                            formatted: ""
                                        }, null, 8, ["address"])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_DisplayLocation, {
                                        location: it.location
                                    }, null, 8, ["location"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate("bridge-presence")), 1),
                                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getValue(it?.presence, "WeighingBridgePresence")), 1)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_OpeningHoursTable, {
                                "opening-hours": it?.openingHours,
                                title: ""
                            }, null, 8, ["opening-hours"])
                        ])
                    ])
                ]));
            }), 128))
        ]))
        : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("empty")), 1)
            ]),
            _: 1
        }));
}
