import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_AppForm, { initial: _ctx.initial }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { disabled: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.site-name.label")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        placeholder: _ctx.site?.name,
                        disabled: ""
                    }, null, 8, ["placeholder"])
                ]),
                _: 1
            }),
            (_ctx.company?.managedByExternalCompany)
                ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { disabled: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            placeholder: _ctx.company?.managedByName,
                            disabled: ""
                        }, null, 8, ["placeholder"])
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label")}`)), 1)
                        ]),
                        _: 1
                    }),
                    _withDirectives(_createVNode(_component_InputText, {
                        name: "name",
                        placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder')}`),
                        required: ""
                    }, null, 8, ["placeholder"]), [
                        [_directive_default_validation]
                    ]),
                    _createVNode(_component_FormError, { name: "name" })
                ]),
                _: 1
            }),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix("label")}`)), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        name: "referenceNumber",
                        placeholder: _ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix('placeholder')}`),
                        "min-length": "1",
                        "max-length": "30",
                        required: ""
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_FormError, { name: "referenceNumber" })
                ]),
                _: 1
            }),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, null, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.name.building-label")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        name: "buildingName",
                        placeholder: _ctx.translate('form-fields.name.building-placeholder'),
                        "min-length": "3",
                        "max-length": "50"
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_FormError, { name: "buildingName" })
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["initial"]));
}
