import FactSheetContextMenuHeader from "@/app/domains/verification-requests/fact-sheets/components/fact-sheet-context-menu-header.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { FactSheetModuleId, SiteSeparator, UnloadingPointSeparator, } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
import { CompanyInformationSubModule } from "@/app/sub-modules/company-information/company-information.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { SiteAdditionalInformationSubModule } from "@/app/sub-modules/additional-information/site/site-additional-information.module";
import { AlarmsAndEmergencySubModule } from "@/app/sub-modules/alarms-and-emargency/alarms-and-emargency.module";
import { CheckInSubModule } from "@/app/sub-modules/check-in/check-in.module";
import { GatesSubModule } from "@/app/sub-modules/gates/gates.module";
import { GeneralSafetyRulesSubModule } from "@/app/sub-modules/general-safety-rules/general-safety-rules.module";
import { InfrastructuresNearbySubModule } from "@/app/sub-modules/infrastructures-nearby/infrastructures-nearby.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { DriveInTimeRestrictionsSubModule } from "@/app/sub-modules/drive-in-time-restrictions/drive-in-time-restrictions.module";
import { ParkingSpaceBasicSubModule } from "@/app/sub-modules/parking-space-basic/parking-space-basic.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { SafetyTestSubModule } from "@/app/sub-modules/safety-test/safety-test.module";
import { SiteIncidentManagementSubModule } from "@/app/sub-modules/site-incident-management/site-incident-management.module";
import { SiteNavigationSubModule } from "@/app/sub-modules/site-navigation/site-navigation.module";
import { TrafficRelatedRulesSubModule } from "@/app/sub-modules/traffic-related-rules/traffic-related-rules.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { WeighingBridgesSubModule } from "@/app/sub-modules/weighing-bridges/weighing-bridges.module";
import { AvailableEquipmentPackedSubModule } from "@/app/sub-modules/available-equipment-packed/available-equipment-packed.module";
import { AvailableEquipmentSubModule } from "@/app/sub-modules/available-equipment/available-equipment.module";
import { UlpBasicInformationSubModule } from "@/app/sub-modules/basic-information/un-loading-point/ulp-basic-information.module";
import { ContactDataSubModule } from "@/app/sub-modules/contact-data/contact-data.module";
import { ReceivingTankSubModule } from "@/app/sub-modules/receiving-tank/receiving-tank.module";
import { RegistrationSubModule } from "@/app/sub-modules/registration/registration.module";
import { ShipmentPreNotificationSubModule } from "@/app/sub-modules/shipment-pre-notification/shipment-pre-notification.module";
import { SlotBookingSubModule } from "@/app/sub-modules/slot-booking/slot-booking.module";
import { UnLoadingAreaSubModule } from "@/app/sub-modules/un-loading-area/un-loading-area.module";
import { WorkingOnTopSubModule } from "@/app/sub-modules/working-on-top/working-on-top.module";
import { EntitySeparatorSubModule } from "@/app/sub-modules/separators/entity-separator/entity-separator.module";
import { CargoSecuringSubModule } from "@/app/sub-modules/cargo-securing/cargo-securing.module";
import { CompressorSubModule } from "@/app/sub-modules/compressor/compressor.module";
import { ConnectionsSubModule } from "@/app/sub-modules/connections/connections.module";
import { DocumentsSubModule } from "@/app/sub-modules/documents/documents.module";
import { EquipmentPackedSubModule } from "@/app/sub-modules/equipment-packed/equipment-packed.module";
import { FillingSubModule } from "@/app/sub-modules/filling/filling.module";
import { GeneralInstructionsSubModule } from "@/app/sub-modules/general-instructions/general-instructions.module";
import { HoseSubModule } from "@/app/sub-modules/hose/hose.module";
import { PackagingSubModule } from "@/app/sub-modules/packaging/packaging.module";
import { PalletsSubModule } from "@/app/sub-modules/pallets/pallets.module";
import { PumpSubModule } from "@/app/sub-modules/pump/pump.module";
import { SamplingSubModule } from "@/app/sub-modules/sampling/sampling.module";
import { SealingSubModule } from "@/app/sub-modules/sealing/sealing.module";
import { TankSubModule } from "@/app/sub-modules/tank/tank.module";
import { TemperatureRequirementsBulkLoadingSubModule } from "@/app/sub-modules/temperature-requirements-bulk-loading/temperature-requirements-bulk-loading.module";
import { TemperatureRequirementsBulkUnloadingSubModule } from "@/app/sub-modules/temperature-requirements-bulk-unloading/temperature-requirements-bulk-unloading.module";
import { TemperatureRequirementsPackedSubModule } from "@/app/sub-modules/temperature-requirements-packed/temperature-requirements-packed.module";
import { VapourReturnSubModule } from "@/app/sub-modules/vapour-return/vapour-return.module";
import { VehicleSubModule } from "@/app/sub-modules/vehicle/vehicle.module";
import { WeighingSubModule } from "@/app/sub-modules/weighing/weighing.module";
import { FactSheetModule } from "@/app/modules/fact-sheets/fact-sheet.module";
export const FactSheetsModule = (parentDomainId, id, options = {}) => {
    return createModule({
        components: [FactSheetContextMenuHeader],
        imports: [
            DomainModule({
                parent: parentDomainId,
                id: id,
                icon: "sticky_note_2",
                detailsIcon: "summarize",
                domainTranslationPrefix: "fact-sheet",
                forBusinessPartner: true,
                standAloneDomain: true,
                hideMenuItem: true,
                baseUrl: "factsheets",
                connectingDisabled: true,
                sideNavTier: 2,
                displayOnly: options.displayOnly,
                components: {
                    contextMenuHeader: FactSheetContextMenuHeader,
                },
            }),
            ////
            EntitySeparatorSubModule(id, {
                id: SiteSeparator,
                displayTitle: "domains.fact-sheets.separators.site-information",
            }),
            FactSheetModule(id),
            CompanyInformationSubModule(id, { moduleId: FactSheetModuleId }),
            PostalAddressSubModule(id, { moduleId: FactSheetModuleId, id: "Site_address" }),
            SiteNavigationSubModule(id, { moduleId: FactSheetModuleId }),
            AccessRestrictionsSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "Site_accessRestrictions",
            }),
            OpeningHoursSubModule(id, { moduleId: FactSheetModuleId, id: "Site_openingHours" }),
            SiteAdditionalInformationSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "Site_additionalInformation",
            }),
            ProcessesSubModule(id, { moduleId: FactSheetModuleId, id: "Site_processes" }),
            WeighingBridgesSubModule(id, { moduleId: FactSheetModuleId }),
            ParkingSpaceBasicSubModule(id, { moduleId: FactSheetModuleId }),
            InfrastructuresNearbySubModule(id, { moduleId: FactSheetModuleId }),
            CheckInSubModule(id, { moduleId: FactSheetModuleId }),
            GatesSubModule(id, { moduleId: FactSheetModuleId }),
            GeneralSafetyRulesSubModule(id, { moduleId: FactSheetModuleId }),
            TrafficRelatedRulesSubModule(id, { moduleId: FactSheetModuleId }),
            SafetyTestSubModule(id, { moduleId: FactSheetModuleId }),
            AlarmsAndEmergencySubModule(id, { moduleId: FactSheetModuleId }),
            SiteIncidentManagementSubModule(id, { moduleId: FactSheetModuleId }),
            // ////
            EntitySeparatorSubModule(id, {
                id: UnloadingPointSeparator,
                displayTitle: "domains.fact-sheets.separators.ulp-information",
            }),
            UlpBasicInformationSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "UnloadingPoint_basicInformation",
            }),
            PostalAddressSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "UnloadingPoint_address",
            }),
            AccessRestrictionsSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "UnloadingPoint_accessRestrictions",
            }),
            RegistrationSubModule(id, { moduleId: FactSheetModuleId }),
            OpeningHoursSubModule(id, {
                moduleId: FactSheetModuleId,
                id: "UnloadingPoint_openingHours",
            }),
            DriveInTimeRestrictionsSubModule(id, { moduleId: FactSheetModuleId }),
            ContactDataSubModule(id, { moduleId: FactSheetModuleId }),
            ProcessesSubModule(id, { moduleId: FactSheetModuleId, id: "UnloadingPoint_processes" }),
            UnLoadingAreaSubModule(id, { moduleId: FactSheetModuleId }),
            WorkingOnTopSubModule(id, { moduleId: FactSheetModuleId }),
            AvailableEquipmentSubModule(id, { moduleId: FactSheetModuleId }),
            AvailableEquipmentPackedSubModule(id, { moduleId: FactSheetModuleId }),
            ReceivingTankSubModule(id, { moduleId: FactSheetModuleId }),
            SlotBookingSubModule(id, { moduleId: FactSheetModuleId }),
            ShipmentPreNotificationSubModule(id, { moduleId: FactSheetModuleId }),
            ////
            VehicleAndPPESubModule(id, {
                moduleId: FactSheetModuleId,
                id: "AssignedProduct_vehicleAndPersonalProtectiveEquipment",
            }),
            VehicleSubModule(id, { moduleId: FactSheetModuleId }),
            EquipmentPackedSubModule(id, { moduleId: FactSheetModuleId }),
            TankSubModule(id, { moduleId: FactSheetModuleId }),
            ConnectionsSubModule(id, { moduleId: FactSheetModuleId }),
            HoseSubModule(id, { moduleId: FactSheetModuleId }),
            PumpSubModule(id, { moduleId: FactSheetModuleId }),
            CompressorSubModule(id, { moduleId: FactSheetModuleId }),
            VapourReturnSubModule(id, { moduleId: FactSheetModuleId }),
            CargoSecuringSubModule(id, { moduleId: FactSheetModuleId }),
            TemperatureRequirementsBulkLoadingSubModule(id, { moduleId: FactSheetModuleId, showChangeHistory: false }),
            TemperatureRequirementsBulkUnloadingSubModule(id, { moduleId: FactSheetModuleId, showChangeHistory: false }),
            TemperatureRequirementsPackedSubModule(id, { moduleId: FactSheetModuleId, showChangeHistory: false }),
            PackagingSubModule(id, { moduleId: FactSheetModuleId }),
            PalletsSubModule(id, { moduleId: FactSheetModuleId }),
            DocumentsSubModule(id, { moduleId: FactSheetModuleId }),
            GeneralInstructionsSubModule(id, { moduleId: FactSheetModuleId }),
            WeighingSubModule(id, { moduleId: FactSheetModuleId }),
            FillingSubModule(id, { moduleId: FactSheetModuleId }),
            SealingSubModule(id, { moduleId: FactSheetModuleId }),
            SamplingSubModule(id, { moduleId: FactSheetModuleId }),
            ProcessesSubModule(id, { moduleId: FactSheetModuleId, id: "AssignedProduct_processes" }),
            ////
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "domains.fact-sheets",
            },
        ],
    });
};
