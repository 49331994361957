import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-830ee3ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "content-wrapper"
};
const _hoisted_2 = {
    key: 0,
    class: "filter-wrapper"
};
const _hoisted_3 = { class: "timeline" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_SubModuleChangeHistoryEntry = _resolveComponent("SubModuleChangeHistoryEntry");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "sub-module-change-history-modal",
        modalId: _ctx.modalId,
        header: _ctx.headerLabel,
        subheader: _ctx.subHeaderLabel,
        showCloseIcon: true
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    class: "pt-4 pb-2",
                    center: ""
                }))
                : (_ctx.hasHistoryChanges)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_AppButton, {
                                    class: "p-button-secondary",
                                    onClick: _ctx.openTimeRangeModal
                                }, {
                                    default: _withCtx(() => [
                                        (_ctx.hasTimeRange)
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                _createTextVNode(_toDisplayString(_ctx.dateRangeLabel), 1)
                                            ], 64))
                                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.translate("select-date")), 1)
                                            ], 64))
                                    ]),
                                    _: 1
                                }, 8, ["onClick"])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyChanges, (entry, index) => {
                                return (_openBlock(), _createBlock(_component_SubModuleChangeHistoryEntry, {
                                    key: index,
                                    entry: entry,
                                    subModuleId: _ctx.subModuleId,
                                    subModuleDisplayViewComponent: _ctx.subModuleDisplayViewComponent
                                }, null, 8, ["entry", "subModuleId", "subModuleDisplayViewComponent"]));
                            }), 128))
                        ]),
                        _withDirectives(_createVNode(_component_AppButton, {
                            class: "p-button-outlined",
                            loading: _ctx.isLoadingMore,
                            onClick: _ctx.loadMoreMethod
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("more")) + " ", 1),
                                _createVNode(_component_Icon, { name: "keyboard_arrow_down" })
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick"]), [
                            [_vShow, _ctx.hasMoreHistoryChanges]
                        ])
                    ]))
                    : (_openBlock(), _createBlock(_component_EmptyStateMessage, {
                        key: 2,
                        class: "py-3"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("no-history-changes")), 1)
                        ]),
                        _: 1
                    })),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, { onClick: _ctx.close }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("global.buttons.close")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modalId", "header", "subheader"]));
}
