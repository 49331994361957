export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["NotRequired"] = "NotRequired";
    VerificationStatus["NotStarted"] = "NotStarted";
    VerificationStatus["Requested"] = "Requested";
    VerificationStatus["Info"] = "Info";
    VerificationStatus["Confirmed"] = "Confirmed";
    VerificationStatus["ManuallyDone"] = "ManuallyDone";
    VerificationStatus["InProgress"] = "InProgress";
})(VerificationStatus || (VerificationStatus = {}));
