var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { cloneDeep } from "lodash";
import { inject } from "@/di/decorators/inject.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { InjectorService } from "@/app/shared/services/injector.service";
import { ModuleUrlFactoryService } from "@/app/modular-content/services/module-url-factory.service";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import { moduleTokensFactory } from "@/app/modular-content/factories/module-tokens.factory";
import { DomainIdToken, ModuleIdToken, SubModuleServicesToken, } from "@/app/modular-content/token/modular-content.token";
import { InjectionScope } from "@/di/types/scope";
let ModuleService = class ModuleService {
    domainId;
    moduleId;
    subModuleServices;
    injector;
    urlService;
    httpService;
    metadata;
    data = {};
    constructor(domainId, moduleId, subModuleServices, injector, urlService, httpService) {
        this.domainId = domainId;
        this.moduleId = moduleId;
        this.subModuleServices = subModuleServices;
        this.injector = injector;
        this.urlService = urlService;
        this.httpService = httpService;
        const moduleTokens = moduleTokensFactory(this.domainId, this.moduleId);
        this.metadata = injector.resolve(moduleTokens.meta);
    }
    getDomainId() {
        return this.domainId;
    }
    getModuleId() {
        return this.moduleId;
    }
    getMetadata() {
        return this.metadata;
    }
    getData() {
        return this.data;
    }
    async loadData() {
        this.data = await this.httpService.get(this.getUrl());
    }
    async updateData(data) {
        await this.httpService.put(this.getUrl(), data);
        this.data = cloneDeep(data);
    }
    getSubModuleData(dataKey) {
        return this.data[dataKey];
    }
    getDetailsRoute() {
        return `${createRouteId(this.domainId)}-details/${createRouteId(this.moduleId)}`;
    }
    getEditRoute() {
        return `${createRouteId(this.domainId)}-edit/${createRouteId(this.moduleId)}`;
    }
    getSubModuleServices() {
        return this.subModuleServices;
    }
    getUrl() {
        return this.urlService.createUrl();
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_d = typeof Partial !== "undefined" && Partial) === "function" ? _d : Object)
], ModuleService.prototype, "data", void 0);
ModuleService = __decorate([
    service({ scope: InjectionScope.Token }),
    __param(0, inject(DomainIdToken)),
    __param(1, inject(ModuleIdToken)),
    __param(2, inject(SubModuleServicesToken)),
    __metadata("design:paramtypes", [String, String, Array, typeof (_a = typeof InjectorService !== "undefined" && InjectorService) === "function" ? _a : Object, typeof (_b = typeof ModuleUrlFactoryService !== "undefined" && ModuleUrlFactoryService) === "function" ? _b : Object, typeof (_c = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _c : Object])
], ModuleService);
export { ModuleService };
