import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2dde772c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "opening-hours-input" };
const _hoisted_2 = { class: "day-header" };
const _hoisted_3 = { class: "label" };
const _hoisted_4 = { class: "closed" };
const _hoisted_5 = { class: "toggle-label" };
const _hoisted_6 = { class: "open-checkbox" };
const _hoisted_7 = { class: "open-ranges" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_OpeningHourInput = _resolveComponent("OpeningHourInput");
    const _component_Icon = _resolveComponent("Icon");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_DropdownMenuOverlay = _resolveComponent("DropdownMenuOverlay");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekdays, (day) => {
            return (_openBlock(), _createElementBlock("div", {
                key: day,
                class: "day"
            }, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate(`temporal.weekdays.${day}`)), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_AppCheckbox, {
                            type: "toggle",
                            "model-value": _ctx.openByWeekday[day],
                            "onUpdate:modelValue": ($event) => (_ctx.toggleClosed(day))
                        }, null, 8, ["model-value", "onUpdate:modelValue"]),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(!_ctx.openByWeekday[day] ? _ctx.translate("closed") : _ctx.translate("open")), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_AppCheckbox, {
                        "model-value": _ctx.openAllDayByWeekday[day],
                        "onUpdate:modelValue": ($event) => (_ctx.toggleOpenAllDay(day)),
                        label: _ctx.translate('all-day'),
                        disabled: !_ctx.openByWeekday[day]
                    }, null, 8, ["model-value", "onUpdate:modelValue", "label", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    (_ctx.openByWeekday[day] && !_ctx.openAllDayByWeekday[day])
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.model[day].hours, (_, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: day + index,
                                class: "hour-range"
                            }, [
                                _createVNode(_component_OpeningHourInput, {
                                    "model-value": _ctx.model[day].hours[index],
                                    "onUpdate:modelValue": ($event) => (_ctx.setHours(day, index, $event))
                                }, null, 8, ["model-value", "onUpdate:modelValue"]),
                                (index === 0)
                                    ? (_openBlock(), _createBlock(_component_DropdownMenuOverlay, { key: 0 }, {
                                        button: _withCtx(() => [
                                            _createVNode(_component_Icon, {
                                                class: "context",
                                                name: "more_horiz"
                                            })
                                        ]),
                                        items: _withCtx(({ hideOverlay }) => [
                                            _createVNode(_component_MenuItem, {
                                                icon: "add",
                                                label: _ctx.translate('buttons.add'),
                                                onClick: ($event) => (_ctx.addHours(day, { hideOverlay }))
                                            }, null, 8, ["label", "onClick"]),
                                            _createVNode(_component_MenuItem, {
                                                icon: "today",
                                                label: _ctx.translate('apply-for-all-days'),
                                                onClick: ($event) => (_ctx.applyForAllDays(day, { hideOverlay }))
                                            }, null, 8, ["label", "onClick"])
                                        ]),
                                        _: 2
                                    }, 1024))
                                    : _createCommentVNode("", true),
                                (index !== 0)
                                    ? (_openBlock(), _createBlock(_component_Icon, {
                                        key: 1,
                                        class: "trash",
                                        name: "delete",
                                        onClick: ($event) => (_ctx.removeHours(day, index))
                                    }, null, 8, ["onClick"]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                        : _createCommentVNode("", true),
                    (!_ctx.openByWeekday[day] || _ctx.openAllDayByWeekday[day])
                        ? (_openBlock(), _createBlock(_component_OpeningHourInput, {
                            key: 1,
                            disabled: ""
                        }))
                        : _createCommentVNode("", true)
                ])
            ]));
        }), 128))
    ]));
}
