import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-db85a08e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_2 = { class: "text-wrap me-3" };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "d-flex align-items-center gap-3 justify-content-between" };
const _hoisted_6 = { class: "name-id" };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = {
    key: 0,
    class: "id"
};
const _hoisted_9 = { key: 1 };
const _hoisted_10 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_11 = { class: "text-wrap me-3" };
const _hoisted_12 = { key: 1 };
const _hoisted_13 = { key: 1 };
const _hoisted_14 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_15 = { class: "text-wrap me-3" };
const _hoisted_16 = { key: 1 };
const _hoisted_17 = { key: 1 };
const _hoisted_18 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_19 = { class: "text-wrap me-3" };
const _hoisted_20 = { key: 1 };
const _hoisted_21 = { key: 1 };
const _hoisted_22 = { class: "d-flex align-items-center gap-3 justify-content-between" };
const _hoisted_23 = { class: "name-id" };
const _hoisted_24 = { key: 1 };
const _hoisted_25 = {
    key: 0,
    class: "id"
};
const _hoisted_26 = { key: 1 };
const _hoisted_27 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_28 = { class: "text-wrap me-3" };
const _hoisted_29 = { key: 1 };
const _hoisted_30 = { key: 1 };
const _hoisted_31 = { key: 1 };
const _hoisted_32 = { class: "px-2 mt-1" };
const _hoisted_33 = { class: "name-id" };
const _hoisted_34 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_35 = { key: 1 };
const _hoisted_36 = {
    key: 0,
    class: "text-wrap id"
};
const _hoisted_37 = { key: 1 };
const _hoisted_38 = {
    key: 0,
    class: "overflow-hidden px-2 mt-1"
};
const _hoisted_39 = { key: 1 };
const _hoisted_40 = { class: "d-flex flex-column" };
const _hoisted_41 = { key: 0 };
const _hoisted_42 = { key: 1 };
const _hoisted_43 = { class: "d-flex flex-column" };
const _hoisted_44 = { key: 0 };
const _hoisted_45 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_BookmarkButton = _resolveComponent("BookmarkButton");
    const _component_RequestVerificationButton = _resolveComponent("RequestVerificationButton");
    const _component_Column = _resolveComponent("Column");
    const _component_Icon = _resolveComponent("Icon");
    const _component_ExpandableTableCell = _resolveComponent("ExpandableTableCell");
    const _component_TableCellLink = _resolveComponent("TableCellLink");
    const _component_PRLBadge = _resolveComponent("PRLBadge");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_Badge = _resolveComponent("Badge");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isInitialLoading || _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "requirement-profiles-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                filterDisplay: "row",
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                loading: _ctx.isInitialLoading || _ctx.isDataLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                rowHover: false,
                selection: _ctx.selectedRequirementProfiles,
                "onUpdate:selection": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedRequirementProfiles) = $event)),
                first: _ctx.lazyParams.first,
                ref: "reqProfilesList"
            }, {
                "header-buttons": _withCtx(() => [
                    (_ctx.isCompanyList)
                        ? (_openBlock(), _createBlock(_component_BookmarkButton, {
                            key: 0,
                            selectedIds: _ctx.selectedRequirementProfiles.map((selectedProfile) => selectedProfile.requirementProfileId),
                            isLoading: _ctx.isAdding,
                            "onUpdate:addBookmarks": _ctx.addBookmarksMethod,
                            "onUpdate:removeBookmark": _ctx.removeBookmark
                        }, null, 8, ["selectedIds", "isLoading", "onUpdate:addBookmarks", "onUpdate:removeBookmark"]))
                        : _createCommentVNode("", true),
                    (_ctx.hasEnhancedRights)
                        ? (_openBlock(), _createBlock(_component_RequestVerificationButton, {
                            key: 1,
                            selectedRequirementProfiles: _ctx.selectedRequirementProfiles,
                            "onUpdate:verificationStatus": _ctx.updateStatusVerification
                        }, null, 8, ["selectedRequirementProfiles", "onUpdate:verificationStatus"]))
                        : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                    (_ctx.hasEnhancedRights || _ctx.isCompanyList)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            selectionMode: "multiple",
                            class: "not-resizable-column",
                            style: { "min-width": "4rem" }
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "requirementProfileId",
                        header: _ctx.translate('columns.requirement-profile-id'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.requirementProfileId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createElementVNode("span", _hoisted_2, _toDisplayString(data.requirementProfileId), 1),
                                    (data.isBookmarked && !_ctx.isBookmarkedList)
                                        ? (_openBlock(), _createBlock(_component_Icon, {
                                            key: 0,
                                            class: "bookmark-icon",
                                            name: "bookmark"
                                        }))
                                        : _createCommentVNode("", true)
                                ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('companyFrom'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "companyFrom",
                            header: _ctx.getColumnHeader('companyFrom'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.companyFromName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.companyFromId],
                                        class: "name",
                                        onClick: ($event) => (_ctx.linkService.goToCompany(data.companyFromId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.companyFromName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('companyProduct'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "companyProduct",
                            header: _ctx.getColumnHeader('companyProduct'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                        (data.outboundProductName)
                                            ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                                key: 0,
                                                values: [data.outboundProductId],
                                                onClick: ($event) => (_ctx.linkService.goToEntity(data.companyFromId, _ctx.ProductDomainId, data.outboundProductId))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ExpandableTableCell, {
                                                        entityName: data.outboundProductName
                                                    }, null, 8, ["entityName"])
                                                ]),
                                                _: 2
                                            }, 1032, ["values", "onClick"]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-")),
                                        _createElementVNode("div", null, [
                                            (data.outboundProductId)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(data.outboundProductId), 1))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                                        ])
                                    ]),
                                    (data.outboundProductHasPrlRestrictions)
                                        ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                            key: 0,
                                            productType: data.outboundProductType,
                                            productId: data.outboundProductId,
                                            managedByExternalCompany: data.outboundProductManagedByExternalCompany
                                        }, null, 8, ["productType", "productId", "managedByExternalCompany"]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableOutboundProducts,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.outbound-product')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('companyProductReferenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "companyProductReferenceNumber",
                            header: _ctx.translate('columns.company-product-reference-number'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.outboundProductReferenceNumber)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _createElementVNode("span", _hoisted_11, _toDisplayString(data.outboundProductReferenceNumber), 1)
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('companyTo'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "companyTo",
                            header: _ctx.getColumnHeader('companyTo'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.companyToName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.companyToId],
                                        class: "name",
                                        onClick: ($event) => (_ctx.linkService.goToCompany(data.companyToId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.companyToName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('companyToReferenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "companyToReferenceNumber",
                            header: _ctx.translate('columns.company-to-reference-number'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.companyToReferenceNumber)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        _createElementVNode("span", _hoisted_15, _toDisplayString(data.companyToReferenceNumber), 1)
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "siteTo",
                        header: _ctx.translate('columns.site-to'),
                        filterMatchMode: "equals",
                        style: { "min-width": "10rem" },
                        showFilterMenu: false,
                        showClearButton: false
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.siteToName)
                                ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                    key: 0,
                                    values: [data.siteToId],
                                    onClick: ($event) => (_ctx.linkService.goToEntity(data.companyToId, _ctx.SiteDomainId, data.siteToId))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_ExpandableTableCell, {
                                            entityName: data.siteToName
                                        }, null, 8, ["entityName"])
                                    ]),
                                    _: 2
                                }, 1032, ["values", "onClick"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
                        ]),
                        filter: _withCtx(({ filterModel, filterCallback }) => [
                            _createVNode(_component_AppMultiSelect, {
                                modelValue: filterModel.value,
                                "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                onChange: ($event) => (filterCallback()),
                                options: _ctx.availableSitesTo,
                                optionLabel: "value",
                                optionValue: "value",
                                display: "chip",
                                placeholder: _ctx.translate('columns.filters.site-to')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('siteToReferenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "siteToReferenceNumber",
                            header: _ctx.translate('columns.site-to-reference-number'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.siteToReferenceNumber)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                        _createElementVNode("span", _hoisted_19, _toDisplayString(data.siteToReferenceNumber), 1)
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_20, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            field: "unloadingPoint",
                            header: _ctx.getColumnHeader('unloadingPoint'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.unloadingPointId, data.siteToId],
                                        onClick: ($event) => (_ctx.linkService.goToEntity(data.companyToId, _ctx.UnloadingPointDomainId, data.unloadingPointId, _ctx.SiteDomainId, data.siteToId))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_ExpandableTableCell, {
                                                entityName: data.unloadingPointName
                                            }, null, 8, ["entityName"])
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_21, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableUnloadingPoints,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.unloading-point')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "inboundProduct",
                        header: _ctx.translate('columns.inbound-product'),
                        filterMatchMode: "equals",
                        style: { "min-width": "10rem" },
                        showFilterMenu: false,
                        showClearButton: false
                    }, {
                        body: _withCtx(({ data }) => [
                            _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                    (data.inboundProductName)
                                        ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                            key: 0,
                                            values: [data.inboundProductId, data.unloadingPointId, data.siteToId],
                                            onClick: ($event) => (_ctx.linkService.goToUnloadedProduct(data.companyToId, data.siteToId, data.unloadingPointId, data.inboundProductId))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_ExpandableTableCell, {
                                                    entityName: data.inboundProductName
                                                }, null, 8, ["entityName"])
                                            ]),
                                            _: 2
                                        }, 1032, ["values", "onClick"]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_24, "-")),
                                    _createElementVNode("div", null, [
                                        (data.inboundProductId)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(data.inboundProductId), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_26, "-"))
                                    ])
                                ]),
                                (data.inboundProductHasPrlRestrictions)
                                    ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                        key: 0,
                                        productType: data.inboundProductType,
                                        productId: data.inboundProductId,
                                        managedByExternalCompany: data.inboundProductManagedByExternalCompany
                                    }, null, 8, ["productType", "productId", "managedByExternalCompany"]))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        filter: _withCtx(({ filterModel, filterCallback }) => [
                            _createVNode(_component_AppMultiSelect, {
                                modelValue: filterModel.value,
                                "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                onChange: ($event) => (filterCallback()),
                                options: _ctx.availableInboundProducts,
                                optionLabel: "value",
                                optionValue: "value",
                                display: "chip",
                                placeholder: _ctx.translate('columns.filters.inbound-product')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('inboundProductReferenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 8,
                            field: "inboundProductReferenceNumber",
                            header: _ctx.translate('columns.inbound-product-reference-number'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.inboundProductReferenceNumber)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                        _createElementVNode("span", _hoisted_28, _toDisplayString(data.inboundProductReferenceNumber), 1)
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_29, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('requiresVerification'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 9,
                            field: "requiresVerification",
                            header: _ctx.getColumnHeader('requiresVerification'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.isRequiredVerification !== undefined && data.isRequiredVerification !== null)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: data.isRequiredVerification ? 'green' : 'gray'
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.isRequiredVerification ? _ctx.translate("phrases.yes") : _ctx.translate("phrases.no")), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_30, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.booleanOptions,
                                    optionLabel: "name",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.requires-verification')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('statusVerification'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 10,
                            field: "statusVerification",
                            header: _ctx.getColumnHeader('statusVerification'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.statusVerification)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        color: _ctx.verificationStatusStyles[data.statusVerification]
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getStatus(data.statusVerification)), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["color"]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_31, "-"))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.statusVerificationOptions,
                                    optionLabel: "name",
                                    dictionaryId: "RequirementProfileStatusVerification",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.status-verification')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("template", null, [
                        _createVNode(_component_Column, {
                            field: "factSheet",
                            header: _ctx.translate('columns.factSheet'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_32, [
                                    _createVNode(_component_Icon, {
                                        class: _normalizeClass(["image-icon", { 'verified-or-in-review': _ctx.isVerifiedOrInReviewStatus(data.statusVerification) }]),
                                        name: "sticky_note_2",
                                        onClick: ($event) => (_ctx.navigateToFactSheet(data))
                                    }, null, 8, ["class", "onClick"])
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"])
                    ]),
                    (_ctx.isColumnVisible('verifier'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 11,
                            field: "verifier",
                            header: _ctx.getColumnHeader('verifier'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_33, [
                                    _createElementVNode("div", null, [
                                        (data.verifierName)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(data.verifierName), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_35, "-"))
                                    ]),
                                    _createElementVNode("div", null, [
                                        (data.verifierEmail)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(data.verifierEmail), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_37, "-"))
                                    ])
                                ])
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableVerifiers,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.verifier')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("template", null, [
                        (_ctx.isColumnVisible('internalComments'))
                            ? (_openBlock(), _createBlock(_component_Column, {
                                key: 0,
                                field: "additionalInformation",
                                header: _ctx.getColumnHeader('internalComments'),
                                filterMatchMode: "equals",
                                style: { "min-width": "10rem" },
                                showFilterMenu: false,
                                showClearButton: false
                            }, {
                                body: _withCtx(({ data }) => [
                                    (_ctx.$utils.hasValue(data.additionalInformation))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                            _withDirectives(_createVNode(_component_Icon, {
                                                name: "text_snippet_rounded",
                                                class: "image-icon"
                                            }, null, 512), [
                                                [
                                                    _directive_tooltip,
                                                    data.additionalInformation,
                                                    void 0,
                                                    { bottom: true }
                                                ]
                                            ])
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_39, "-"))
                                ]),
                                filter: _withCtx(({ filterModel, filterCallback }) => [
                                    _createVNode(_component_AppMultiSelect, {
                                        modelValue: filterModel.value,
                                        "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                        onChange: ($event) => (filterCallback()),
                                        options: _ctx.booleanOptions,
                                        optionLabel: "name",
                                        optionValue: "value",
                                        display: "chip",
                                        placeholder: _ctx.translate('columns.filters.internal-comments')
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                                ]),
                                _: 1
                            }, 8, ["header"]))
                            : _createCommentVNode("", true)
                    ]),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 12,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_40, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime, "YYYY-MM-DD")), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_42, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('lastUpdate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 13,
                            field: "lastUpdate",
                            header: _ctx.getColumnHeader('lastUpdate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_43, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime, "YYYY-MM-DD")), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_45, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        class: "not-resizable-column",
                        style: { "min-width": "6rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (_ctx.hasEnhancedRights || _ctx.isBookmarkedList || data.isBookmarked)
                                ? (_openBlock(), _createBlock(_component_TableRowContextMenu, {
                                    key: 0,
                                    data: data,
                                    contextTranslate: _ctx.translate,
                                    onDelete: ($event) => (_ctx.deleteItem(data)),
                                    onUpdate: ($event) => (_ctx.editItem($event, data)),
                                    hasEnhancedRights: _ctx.hasEnhancedRights,
                                    customDeletion: ""
                                }, {
                                    items: _withCtx((contextMenuSlotProps) => [
                                        (_ctx.isBookmarkedList || data.isBookmarked)
                                            ? (_openBlock(), _createBlock(_component_MenuItem, {
                                                key: 0,
                                                label: _ctx.translate('components.context-menu.remove-bookmark'),
                                                onClick: () => _ctx.removeBookmark(data, contextMenuSlotProps),
                                                icon: "remove_circle"
                                            }, null, 8, ["label", "onClick"]))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["data", "contextTranslate", "onDelete", "onUpdate", "hasEnhancedRights"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _: 2
            }, 1032, ["value", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "selection", "first"])
        ]),
        empty: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("empty.header")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.translate("empty.message")), 1)
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
