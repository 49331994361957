import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8ac56316"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-12 px-4" };
const _hoisted_3 = { class: "name" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-12 col-xl-6 px-4" };
const _hoisted_6 = { class: "pb-4" };
const _hoisted_7 = { class: "label" };
const _hoisted_8 = { class: "address-details" };
const _hoisted_9 = {
    key: 0,
    class: "address-postal"
};
const _hoisted_10 = { class: "ms-3" };
const _hoisted_11 = {
    key: 0,
    class: "pb-4"
};
const _hoisted_12 = { class: "label globe-icon-wrapper" };
const _hoisted_13 = { class: "col-12 col-xl-6 px-4 border-left" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    return (_ctx.model?.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.model, (gate) => {
            return (_openBlock(), _createElementBlock("div", {
                key: gate.name,
                class: "display-gates"
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h4", _hoisted_3, _toDisplayString(gate.name), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("address")) + ":", 1),
                            _createElementVNode("div", _hoisted_8, [
                                (gate.postalAddress)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createVNode(_component_DisplayAddress, {
                                            address: gate.postalAddress,
                                            short: "",
                                            formatted: ""
                                        }, null, 8, ["address"])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_DisplayLocation, {
                                        location: gate.location
                                    }, null, 8, ["location"])
                                ])
                            ])
                        ]),
                        (gate?.drivingLanes?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createVNode(_component_LocalizedField, {
                                    lanesModelValue: gate?.drivingLanes,
                                    gates: ""
                                }, {
                                    header: _withCtx(() => [
                                        _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.translate("lanes")), 1)
                                    ]),
                                    _: 2
                                }, 1032, ["lanesModelValue"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_OpeningHoursTable, {
                            "opening-hours": gate.openingHours,
                            title: ""
                        }, null, 8, ["opening-hours"])
                    ])
                ])
            ]));
        }), 128))
        : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("empty")), 1)
            ]),
            _: 1
        }));
}
