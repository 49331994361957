import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { filter, Subject } from "rxjs";
import _ from "lodash";
let ModalService = class ModalService {
    visibilitySubject = new Subject();
    resultSubject = new Subject();
    visible = false;
    result;
    data;
    entity;
    header;
    message;
    button;
    closable;
    constructor() {
        this.visibilitySubject.pipe(filter(_.identity)).subscribe(() => {
            this.result = undefined;
        });
    }
    getHeader() {
        return this.header;
    }
    setHeader(header) {
        this.header = header;
    }
    getMessage() {
        return this.message;
    }
    setMessage(message) {
        this.message = message;
    }
    setButton(button) {
        this.button = button;
    }
    getButton() {
        return this.button;
    }
    getClosable() {
        return this.closable;
    }
    setClosable(closable) {
        this.closable = closable;
    }
    setResult(result) {
        this.result = result;
        this.resultSubject.next(result);
    }
    getResult() {
        return this.result;
    }
    setData(data) {
        this.data = data;
    }
    getData() {
        return this.data;
    }
    setEntity(entity) {
        this.entity = entity;
    }
    getEntity() {
        return this.entity;
    }
    setVisible(visible) {
        this.visible = visible;
        this.visibilitySubject.next(visible);
    }
    open() {
        this.setVisible(true);
    }
    close() {
        this.setVisible(false);
    }
    isVisible() {
        return this.visible;
    }
    onVisibleChange() {
        return this.visibilitySubject;
    }
    onResultChange() {
        return this.resultSubject;
    }
};
ModalService = __decorate([
    service({ scope: InjectionScope.Token }),
    __metadata("design:paramtypes", [])
], ModalService);
export { ModalService };
