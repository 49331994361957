var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { VerificationStatus } from "@/app/domains/requirement-profiles/enums/status-verification.enum";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ProcessingItemsService } from "@/app/shared/services/processing-items.service";
import { RouterService } from "@/app/shared/services/router.service";
import { BaseUrlCallback } from "@/app/modular-content/modules/types/domain-module.type";
import { RestClientBaseUrlCallbackToken } from "@/app/http/tokens/rest-client-base-url-callback.token";
import { inject } from "@/di/decorators/inject.decorator";
let SummaryVerificationService = class SummaryVerificationService {
    httpClient;
    routerService;
    processingItemsService;
    baseUrlCallback;
    constructor(httpClient, routerService, processingItemsService, baseUrlCallback) {
        this.httpClient = httpClient;
        this.routerService = routerService;
        this.processingItemsService = processingItemsService;
        this.baseUrlCallback = baseUrlCallback;
    }
    getVerificationId() {
        return this.routerService.getParam("verificationRequestsId") ?? "";
    }
    getEntityId() {
        return this.routerService.getMeta("entityId") ?? "";
    }
    async getSummaryPerEntity() {
        return await this.httpClient.get(`${this.getBaseUrl()}/${this.getEntityId()}`);
    }
    async confirm() {
        await this.httpClient.post("requirementprofiles/changeStatus", {
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.Confirmed,
        });
        this.processingItemsService.add({ id: this.getVerificationId(), statusVerification: VerificationStatus.Confirmed });
    }
    async sendInfo() {
        await this.httpClient.post("requirementprofiles/changeStatus", {
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.Info,
        });
        this.processingItemsService.add({ id: this.getVerificationId(), statusVerification: VerificationStatus.Info });
    }
    getBaseUrl() {
        return this.baseUrlCallback(this.routerService);
    }
    async startVerification() {
        await this.httpClient.post("requirementprofiles/changeStatus", {
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.InProgress,
        });
        this.processingItemsService.add({
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.InProgress,
        });
    }
};
SummaryVerificationService = __decorate([
    service(),
    __param(3, inject(RestClientBaseUrlCallbackToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, typeof (_c = typeof ProcessingItemsService !== "undefined" && ProcessingItemsService) === "function" ? _c : Object, typeof (_d = typeof BaseUrlCallback !== "undefined" && BaseUrlCallback) === "function" ? _d : Object])
], SummaryVerificationService);
export { SummaryVerificationService };
