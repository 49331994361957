import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3c93b36c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-required-equipment-state" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-xl-7" };
const _hoisted_5 = { class: "temp-box" };
const _hoisted_6 = { class: "col-5" };
const _hoisted_7 = { class: "col-5" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-xl-7" };
const _hoisted_10 = { class: "temp-box" };
const _hoisted_11 = { class: "col-5 mb-0" };
const _hoisted_12 = { class: "col-5 mt-0" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-xl-7 temp-container" };
const _hoisted_15 = { class: "temp-box" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, { class: "checkboxes" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppCheckbox, { name: "noSpecialRequirements" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }),
                    (!_ctx.noSpecialRequirements)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("tank-state")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AppMultiSelect, {
                                        name: "tankState",
                                        optionValue: "id",
                                        options: _ctx.dictionaries.tankState,
                                        optionLabel: "name",
                                        dictionaryId: "TankState",
                                        display: "chip",
                                        placeholder: _ctx.translate('tank-state')
                                    }, null, 8, ["options", "placeholder"])
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", _hoisted_6, [
                                            _createVNode(_component_InputLabel, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("min-tank-temperature")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _createElementVNode("div", _hoisted_7, [
                                            _createVNode(_component_InputLabel, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("max-tank-temperature")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("div", _hoisted_11, [
                                            _createVNode(_component_FormField, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_RadioButton, {
                                                        modelValue: _ctx.model.minTankTemperatureUnit,
                                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.minTankTemperatureUnit) = $event)),
                                                        dictionary: _ctx.dictionaries.temperatureUnit,
                                                        dictionaryId: "TemperatureUnit",
                                                        isOptionDeselectable: false
                                                    }, null, 8, ["modelValue", "dictionary"])
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _createElementVNode("div", _hoisted_12, [
                                            _createVNode(_component_FormField, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_RadioButton, {
                                                        modelValue: _ctx.model.maxTankTemperatureUnit,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.maxTankTemperatureUnit) = $event)),
                                                        dictionary: _ctx.dictionaries.temperatureUnit,
                                                        dictionaryId: "TemperatureUnit",
                                                        isOptionDeselectable: false
                                                    }, null, 8, ["modelValue", "dictionary"])
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("div", _hoisted_15, [
                                        _createVNode(_component_FormField, { class: "col-5" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputNumber, {
                                                    name: "minTankTemperature",
                                                    mode: "decimal",
                                                    placeholder: _ctx.translate('min-temperature')
                                                }, null, 8, ["placeholder"])
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_FormField, { class: "col-5" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputNumber, {
                                                    name: "maxTankTemperature",
                                                    mode: "decimal",
                                                    placeholder: _ctx.translate('max-temperature')
                                                }, null, 8, ["placeholder"])
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ]),
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("equipment-for-food-transport")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_RadioButton, {
                                        modelValue: _ctx.model.equipmentMustBeSuitableForFoodTransport,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.equipmentMustBeSuitableForFoodTransport) = $event))
                                    }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["modelValue", "initial"]))
    ]));
}
