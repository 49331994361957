import { createModule } from "@/di/module";
import { AssignedProductMasterBasicInformationSubModule } from "@/app/sub-modules/basic-information/assigned-product-master/assigned-product-master-basic-information.module";
import { CargoSecuringSubModule } from "@/app/sub-modules/cargo-securing/cargo-securing.module";
import { CompressorSubModule } from "@/app/sub-modules/compressor/compressor.module";
import { ConnectionsSubModule } from "@/app/sub-modules/connections/connections.module";
import { DocumentsModule, DocumentsModuleId } from "@/app/modules/documents/documents.module";
import { DocumentsSubModule } from "@/app/sub-modules/documents/documents.module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { EquipmentPackedSubModule } from "@/app/sub-modules/equipment-packed/equipment-packed.module";
import { FillingSubModule } from "@/app/sub-modules/filling/filling.module";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { GeneralInstructionsSubModule } from "@/app/sub-modules/general-instructions/general-instructions.module";
import { HoseSubModule } from "@/app/sub-modules/hose/hose.module";
import { InstructionsModule } from "@/app/modules/instructions/instructions.module";
import { PackagingModule } from "@/app/modules/packaging/packaging.module";
import { PackagingSubModule } from "@/app/sub-modules/packaging/packaging.module";
import { PalletsSubModule } from "@/app/sub-modules/pallets/pallets.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { PumpSubModule } from "@/app/sub-modules/pump/pump.module";
import { SafetyAndSecurityModule, SafetyAndSecurityModuleId, } from "@/app/modules/safety-and-security/safety-and-security.module";
import { SamplingSubModule } from "@/app/sub-modules/sampling/sampling.module";
import { SealingSubModule } from "@/app/sub-modules/sealing/sealing.module";
import { TankSubModule } from "@/app/sub-modules/tank/tank.module";
import { TechnicalRequirementsModule } from "@/app/modules/technical-requirements/technical-requirements.module";
import { TemperatureRequirementsBulkLoadingSubModule } from "@/app/sub-modules/temperature-requirements-bulk-loading/temperature-requirements-bulk-loading.module";
import { TemperatureRequirementsBulkUnloadingSubModule } from "@/app/sub-modules/temperature-requirements-bulk-unloading/temperature-requirements-bulk-unloading.module";
import { TemperatureRequirementsPackedSubModule } from "@/app/sub-modules/temperature-requirements-packed/temperature-requirements-packed.module";
import { VapourReturnSubModule } from "@/app/sub-modules/vapour-return/vapour-return.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { VehicleSubModule } from "@/app/sub-modules/vehicle/vehicle.module";
import { WeighingSubModule } from "@/app/sub-modules/weighing/weighing.module";
import { SelectedLPFilterService } from "@/app/domains/assigned-products/services/selected-lp-filter.service";
import { SelectedULPFilterService } from "@/app/domains/assigned-products/services/selected-ulp-filter.service";
import ContextMenuHeaderComponent from "@/app/domains/assigned-product-masters/components/assigned-product-masters-context-menu-header.component.vue";
import CreateFormComponent from "@/app/domains/assigned-product-masters/components/create-assigned-product-master-form.component.vue";
import TableComponent from "@/app/domains/assigned-product-masters/components/assigned-product-masters-table.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
const parentDomainBaseUrls = {
    [LoadingPointDomainId]: "loadingpoints",
    [UnloadingPointDomainId]: "unloadingpoints",
};
const parentDomainTableFilters = {
    [LoadingPointDomainId]: SelectedLPFilterService,
    [UnloadingPointDomainId]: SelectedULPFilterService,
};
export const AssignedProductMastersModule = (parentDomainId, id) => {
    const parentDomainBaseUrl = parentDomainBaseUrls[parentDomainId];
    const parentDomainTableFilter = parentDomainTableFilters[parentDomainId];
    const componentSuffixes = [parentDomainId, id];
    const contextMenuHeaderComponent = suffixComponentName(ContextMenuHeaderComponent, ...componentSuffixes);
    const tableComponent = suffixComponentName(TableComponent, ...componentSuffixes);
    const createFormComponent = suffixComponentName(CreateFormComponent, ...componentSuffixes);
    return createModule({
        components: [contextMenuHeaderComponent, tableComponent, createFormComponent],
        imports: [
            DomainModule({
                id,
                icon: "note",
                detailsIcon: "note",
                baseUrl: `${parentDomainBaseUrl}/productmasters`,
                lists: [
                    {
                        id: "all",
                        default: true,
                        filter: parentDomainTableFilter,
                    },
                ],
                parent: parentDomainId,
                forBusinessPartner: true,
                connectingDisabled: true,
                domainTranslationPrefix: "assigned-product-masters",
                components: {
                    contextMenuHeader: contextMenuHeaderComponent,
                    table: tableComponent,
                    createForm: createFormComponent,
                },
            }),
            // General Information
            GeneralInformationModule(id),
            AssignedProductMasterBasicInformationSubModule(id),
            // Safety and Security
            SafetyAndSecurityModule(id, {
                navigationIcon: "svg/assigned_products",
            }),
            VehicleAndPPESubModule(id, { moduleId: SafetyAndSecurityModuleId }),
            // Technical Requirements
            TechnicalRequirementsModule(id, {
                navigationIcon: "svg/assigned_products",
            }),
            VehicleSubModule(id),
            EquipmentPackedSubModule(id, {
                showChangeHistory: true,
            }),
            TankSubModule(id, {
                showChangeHistory: true,
            }),
            ConnectionsSubModule(id),
            HoseSubModule(id),
            PumpSubModule(id),
            CompressorSubModule(id),
            VapourReturnSubModule(id),
            CargoSecuringSubModule(id),
            TemperatureRequirementsBulkLoadingSubModule(id),
            TemperatureRequirementsBulkUnloadingSubModule(id),
            TemperatureRequirementsPackedSubModule(id),
            // Packaging
            PackagingModule(id, {
                navigationIcon: "svg/assigned_products",
            }),
            PackagingSubModule(id),
            PalletsSubModule(id),
            // Documents
            DocumentsModule(id, {
                navigationIcon: "svg/assigned_products",
            }),
            DocumentsSubModule(id, { moduleId: DocumentsModuleId }),
            // Instructions
            InstructionsModule(id, {
                navigationIcon: "svg/assigned_products",
            }),
            GeneralInstructionsSubModule(id),
            WeighingSubModule(id),
            FillingSubModule(id),
            SealingSubModule(id),
            SamplingSubModule(id),
            ProcessesSubModule(id),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "domains.assigned-product-masters" }],
    });
};
