import { camelCase } from "change-case";
import { createModule } from "@/di/module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { PreProductsModule } from "@/app/domains/preloading-restrictions/pre-products.module";
import { CopyPreloadingRestrictionsService } from "@/app/domains/preloading-restrictions/services/copy-preloading-restrictions.service";
import { ProductFilterService } from "@/app/domains/products/services/product-filter.service";
import { ProductTypeFilterService } from "@/app/domains/products/services/product-type-filter.service";
import CopyPreloadingRestrictionsModalComponent from "@/app/domains/preloading-restrictions/components/copy-preloading-restrictions-modal.component.vue";
import DisplayPreProductModalComponent from "@/app/domains/preloading-restrictions/components/display-pre-product-modal.component.vue";
import PreloadingRestrictionModalComponent from "@/app/domains/preloading-restrictions/components/preloading-restriction-modal.component.vue";
import PreloadingRestrictionsContextMenuHeaderComponent from "@/app/domains/preloading-restrictions/components/preloading-restrictions-context-menu-header.component.vue";
import PreloadingRestrictionsListComponent from "@/app/domains/preloading-restrictions/components/preloading-restrictions-list.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { CompanyRole } from "@/app/shared/types/access.type";
import { DisplayPreProductModalId } from "@/app/domains/preloading-restrictions/preloading-restrictions.constants";
import { ProductType } from "@/app/domains/products/enums/product-type.enum";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const PreloadingRestrictionsModule = (parentDomainId, id, options = {}) => {
    const componentSuffixes = [parentDomainId, id];
    const contextMenuHeaderComponent = suffixComponentName(PreloadingRestrictionsContextMenuHeaderComponent, ...componentSuffixes);
    const modalComponent = suffixComponentName(PreloadingRestrictionModalComponent, ...componentSuffixes);
    const tableComponent = suffixComponentName(PreloadingRestrictionsListComponent, ...componentSuffixes);
    return createModule({
        components: [
            contextMenuHeaderComponent,
            modalComponent,
            tableComponent,
            DisplayPreProductModalComponent,
            CopyPreloadingRestrictionsModalComponent,
        ],
        imports: [
            DomainModule({
                id: id,
                parent: parentDomainId,
                parentCondition(it) {
                    return it?.productType === ProductType.Bulk;
                },
                public: true,
                readOnly: options.readOnly,
                originDomain: options.originDomain,
                originId: options.originId,
                originIdGoToBtnLabel: options.originIdGoToBtnLabel,
                icon: "assignment",
                detailsIcon: "assignment",
                createRoles: [CompanyRole.ProductManager],
                lists: [
                    {
                        id: "all",
                        default: true,
                        copyAvailable: true,
                    },
                ],
                forBusinessPartner: true,
                domainTranslationPrefix: "preloading-restrictions",
                copyTooltip: "domains.preloading-restrictions.copy-tooltip",
                baseUrl: () => {
                    const paramId = camelCase(parentDomainId + "Id");
                    return `products/:${paramId}/preLoadingRestriction`;
                },
                keepSearchUrlParams: true,
                components: {
                    contextMenuHeader: contextMenuHeaderComponent,
                    createModal: modalComponent,
                    editModal: modalComponent,
                    table: tableComponent,
                    copyModal: CopyPreloadingRestrictionsModalComponent,
                },
            }),
            ModalModule(DisplayPreProductModalId, DisplayPreProductModalComponent),
            createModule({
                imports: [PreProductsModule()],
            }),
        ],
        providers: [
            CopyPreloadingRestrictionsService,
            ProductFilterService,
            ProductTypeFilterService,
            {
                token: TranslationPrefixToken,
                useValue: "domains.preloading-restrictions",
            },
        ],
    });
};
